export const Section = [
  ['heading', { label: 'Heading Text', type: 'input', value: '', defaultValue: 'Heading' }],
  ['subheading', { label: 'Sub-Heading Text', type: 'input', value: '' }],
  [
    'alignment',
    {
      label: 'Align',
      type: 'select',
      value: '',
      defaultValue: 'left',
      options: [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
        { label: 'Right', value: 'right' },
      ],
    },
  ],
  [
    'cols',
    {
      label: 'Columns',
      type: 'select',
      value: '',
      defaultValue: '1',
      options: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
      ],
    },
  ],
];
export const Optional = [
  ["isVisible", {}],
  ["sublabel", {}],
  ["placeholder", {}],
  [
    "options",
    {
      label: "Options",
      type: "option-group",
      value: ["Option 1", "Option 2"],
    },
  ],
  // [
  //   "displayOther",
  //   {
  //     label: "Display Other Option",
  //     type: "checkbox",
  //     value: false,
  //     helpText:
  //       "Allow users to enter text when their selection is not available",
  //   },
  // ],
  // [
  //   "other",
  //   {
  //     label: null,
  //     type: "input",
  //     value: "",
  //     defaultValue: "Other",
  //     visible: { mappedPropKey: "displayOther", mappedPropValue: "value" },
  //   },
  // ],
  [
    "displayAsColumns",
    {
      label: "Columns",
      type: "checkbox",
      value: false,
      helpText:
        "Spread options side by side into a specified number of columns",
    },
  ],
  [
    "numColumns",
    {
      label: null,
      type: "input",
      value: "",
      defaultValue: "2",
      helpText: "COLUMNS",
      visible: { mappedPropKey: "displayAsColumns", mappedPropValue: "value" },
      className: "inline",
    },
  ],
];
export const DateConfig = [
  [
    'dateFormat',
    {
      label: 'Format',
      type: 'single',
      value: '',
      defaultValue: 'MM/DD/YYYY',
      options: ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD'],
    },
  ],
  [
    'separator',
    {
      label: 'Separator',
      type: 'single',
      value: '',
      defaultValue: '/',
      options: ['/', '-', '.'],
      helpText: 'Select which character to use between the date fields',
      mappedPropKey: 'dateFormat',
    },
  ],
  [
    'includeTime',
    {
      label: 'Time Field',
      type: 'checkbox',
      value: false,
      helpText: 'Allow users to specify a time with date',
      dateTimeOnly: true,
    },
  ],
  [
    'sublabel',
    {
      // override common property with custom logic
      label: 'Sublabels',
      type: 'group',
      value: [
        ['Date', { type: 'text', visible: true, value: '', defaultValue: 'Date' }],
        [
          'Hour',
          {
            type: 'text',
            visible: { mappedPropKey: 'includeTime', mappedPropValue: 'value' },
            value: '',
            defaultValue: 'Hour',
          },
        ],
        [
          'Minutes',
          {
            type: 'text',
            visible: { mappedPropKey: 'includeTime', mappedPropValue: 'value' },
            value: '',
            defaultValue: 'Minutes',
          },
        ],
      ],
      dateTimeOnly: true,
    },
  ],
  ['placeholder', {}],
  [
    'pastFuture',
    {
      label: 'Past & Future',
      type: 'multiple',
      value: [
        ['Past', true],
        ['Future', true],
      ],
      helpText: 'Allow users to select dates in the past or future',
      dateTimeOnly: true,
    },
  ],
  [
    'weekdays',
    {
      label: 'Days of the Week',
      type: 'multiple',
      value: [
        ['Mondays', true],
        ['Tuesdays', true],
        ['Wednesdays', true],
        ['Thursdays', true],
        ['Fridays', true],
        ['Saturdays', true],
        ['Sundays', true],
      ],
      helpText:
        'Allowed days of the week. Unchecking a day will disable all of the same days in the calendar.',
      dateTimeOnly: true,
    },
  ],
];
export const Time = [
  [
    'timeRange',
    {
      label: 'Time Range',
      type: 'checkbox',
      value: false,
      helpText: 'Display two time fields to indicate a range',
    },
  ],
  [
    'until',
    {
      label: 'Until',
      type: 'input',
      value: '',
      defaultValue: 'Until',
      helpText: 'Add text between selected forms',
      visible: { mappedPropKey: 'timeRange', mappedPropValue: 'value' },
    },
  ],
  [
    'sublabel',
    {
      // override common property with custom logic
      label: 'Sublabels',
      type: 'group',
      value: [
        [
          'startHour',
          { label: 'Hour', type: 'text', visible: true, value: '', defaultValue: 'Hour' },
        ],
        [
          'startMinutes',
          { label: 'Minutes', type: 'text', visible: true, value: '', defaultValue: 'Minutes' },
        ],
        [
          'endHour',
          {
            label: 'Hour',
            type: 'text',
            visible: { mappedPropKey: 'timeRange', mappedPropValue: 'value' },
            value: '',
            defaultValue: 'Hour',
          },
        ],
        [
          'endMinutes',
          {
            label: 'Minutes',
            type: 'text',
            visible: { mappedPropKey: 'timeRange', mappedPropValue: 'value' },
            value: '',
            defaultValue: 'Minutes',
          },
        ],
      ],
    },
  ],
  ['placeholder', {}],
];
export const Sex = [
  [
    'options',
    {
      label: 'Options',
      type: 'group',
      value: [
        ['male', { label: 'Male', type: 'text', value: '', defaultValue: 'Male' }],
        ['female', { label: 'Female', type: 'text', value: '', defaultValue: 'Female' }],
      ],
    },
  ],
  ['placeholder', {}],
];
export const SocialSecurityNumber = [['placeholder', {}]];
export const Number = [
  [
    'limit',
    {
      label: 'Limit Entry',
      type: 'checkbox',
      value: false,
      helpText: 'Limit the maximum and minimum value allowed in this field',
    },
  ],
  [
    'min',
    {
      label: null,
      type: 'input',
      value: '',
      defaultValue: 0,
      helpText: 'Minimum',
      visible: { mappedPropKey: 'limit', mappedPropValue: 'value' },
      className: 'span15',
    },
  ],
  [
    'max',
    {
      label: null,
      type: 'input',
      value: '',
      defaultValue: 100,
      helpText: 'Maximum',
      visible: { mappedPropKey: 'limit', mappedPropValue: 'value' },
      className: 'span15',
    },
  ],
];
export const ShortText = [
  [
    'limit',
    {
      label: 'Limit Entry',
      type: 'checkbox',
      value: false,
      helpText: 'Limit the maximum number of characters allowed for this field',
    },
  ],
  [
    'max',
    {
      label: null,
      type: 'input',
      value: '',
      helpText: 'CHARS',
      visible: { mappedPropKey: 'limit', mappedPropValue: 'value' },
      className: 'inline',
    },
  ],
];
export const LongText = [
  [
    'limit',
    {
      label: 'Limit Entry',
      type: 'checkbox',
      value: false,
      helpText: 'Limit the maximum number of words allowed for this field',
    },
  ],
  [
    'max',
    {
      label: null,
      type: 'input',
      value: '',
      helpText: 'WORDS',
      visible: { mappedPropKey: 'limit', mappedPropValue: 'value' },
      className: 'inline',
    },
  ],
];
export const Dropdown = [
  ['placeholder', {}],
  [
    'options',
    {
      label: 'Options',
      type: 'option-group',
      value: ['Option 1'],
    },
  ],
];
export const Single = [
  ['placeholder', {}],
  [
    'options',
    {
      label: 'Options',
      type: 'option-group',
      value: ['Option 1'],
    },
  ],
  [
    'displayOther',
    {
      label: 'Display Other Option',
      type: 'checkbox',
      value: false,
      helpText: 'Allow users to enter text when their selection is not available',
    },
  ],
  [
    'other',
    {
      label: null,
      type: 'input',
      value: '',
      defaultValue: 'Other',
      visible: { mappedPropKey: 'displayOther', mappedPropValue: 'value' },
    },
  ],
  [
    'displayAsColumns',
    {
      label: 'Columns',
      type: 'checkbox',
      value: false,
      helpText: 'Spread options side by side into a specified number of columns',
    },
  ],
  [
    'numColumns',
    {
      label: null,
      type: 'input',
      value: '',
      defaultValue: '2',
      helpText: 'COLUMNS',
      visible: { mappedPropKey: 'displayAsColumns', mappedPropValue: 'value' },
      className: 'inline',
    },
  ],
];
export const Multiple = [
  ['placeholder', {}],
  [
    'options',
    {
      label: 'Options',
      type: 'option-group',
      value: ['Option 1'],
    },
  ],
  [
    'displayOther',
    {
      label: 'Display Other Option',
      type: 'checkbox',
      value: false,
      helpText: 'Allow users to enter text when their selection is not available',
    },
  ],
  [
    'other',
    {
      label: null,
      type: 'input',
      value: '',
      defaultValue: 'Other',
      visible: { mappedPropKey: 'displayOther', mappedPropValue: 'value' },
    },
  ],
  [
    'displayAsColumns',
    {
      label: 'Columns',
      type: 'checkbox',
      value: false,
      helpText: 'Spread options side by side into a specified number of columns',
    },
  ],
  [
    'numColumns',
    {
      label: null,
      type: 'input',
      value: '',
      defaultValue: '2',
      helpText: 'COLUMNS',
      visible: { mappedPropKey: 'displayAsColumns', mappedPropValue: 'value' },
      className: 'inline',
    },
  ],
];
export const Spinner = [
  ['placeholder', {}],
  [
    'step',
    {
      label: 'Stepping',
      type: 'input',
      value: '',
      defaultValue: '1',
    },
  ],
  [
    'negativeValues',
    {
      label: 'Negative Values',
      type: 'checkbox',
      value: false,
      helpText: 'Allow negative values in this field',
    },
  ],
];
export const Signature = [
  ['placeholder', {}],
  [
    'sublabel',
    {
      label: 'Sublabel',
      type: 'input',
      value: '',
      defaultValue: 'Use your mouse or finger to draw your signature above',
    },
  ],
];
export const Submit = [
  ['sublabel', {}],
  ['placeholder', {}],
  ['makeRequired', {}],
  ['makeHidden', {}],
  ['hideLabel', {}],
  ['readOnly', {}],
];
export const DescriptionArea = [
  ['label', {}],
  ['sublabel', {}],
  ['placeholder', {}],
  ['makeRequired', {}],
  ['makeHidden', {}],
  ['hideLabel', {}],
  ['readOnly', {}],
  [
    'descriptionContent',
    {
      label: 'Edit Description Area',
      type: 'rich-text',
      value: 'Description Area',
      buttonIcon: 'pencil',
    },
  ],
];
export const Estimate = [
  ['label', {}],
  ['sublabel', {}],
  ['placeholder', {}],
  ['makeRequired', {}],
  ['makeHidden', {}],
  ['hideLabel', {}],
  ['readOnly', {}],
];
export const Upload = [
  ["makeHidden", {}],
  ["readOnly", {}],
];
export const Common = [
  ['label', { label: 'Label', type: 'input', value: '', isDynamic: false }],
  ['isVisible', { label: 'Visible Option', type: 'checkbox', value: false, isDynamic: true }],
  ['sublabel', { label: 'Sublabel', type: 'input', value: '', isDynamic: true }],
  ['placeholder', { label: 'Placeholder', type: 'input', value: '', isDynamic: true }],
  ['makeRequired', { label: 'Make Required', type: 'checkbox', value: false, isDynamic: false }],
  ['makeHidden', { label: 'Make Hidden', type: 'checkbox', value: false, isDynamic: false }],
  ['hideLabel', { label: 'Hide Label', type: 'checkbox', value: false, isDynamic: false }],
  ['readOnly', { label: 'Read-Only', type: 'checkbox', value: false, isDynamic: false }],
];
export const AppointmentBooking = [];

export const Patient = [];
