export default {
  formVersion: [
    'account_id',
    'secure_account_id',
    'form_id',
    'form_version_id',
    'is_restricted',
    'is_redirect',
    'has_contact',
    'has_group',
    'object',
    'redirect_url',
    'thank_you',
  ],
  submission: [
    'id',
    'account_id',
    'secure_account_id',
    'form_id',
    'form_version_id',
    'object',
    'submitted_by',
    'contact_id'
  ],
  object: [
    'id',
    'uuid',
    'form_legwork_attribute_id',
    'form_syncer_attribute_id',
    'legwork_table_name',
    'legwork_column_name',
    'column_name',
    'table_name',
    'value',
    'label',
    'group_name'
  ],
};