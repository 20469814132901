import React, { useMemo, useEffect, useState } from "react";
import Card from "../Global/Card/Card";
import ItemUI from "../Global/Card/Item";
import LinkButton from "components/Global/Button/LinkButton";

import { ProviderResponseType } from "services/ResponseTypes/Provider";
import avatar from "assets/images/doctor.png";
import { useAppSelector } from "hooks/redux";
import { axiosGet } from "services/request";
import * as ResponseTypeguards from "../../../src/services/ResponseTypeguards";

interface Props {
  providers: ProviderResponseType[];
  practiceName: string;
  loading: boolean;
}

const RightDashboard = ({ providers, practiceName, loading }: Props) => {
  const routeFlag = useAppSelector((state) => state.features.routeFlag);
  const [externalLink, setExternalLink] = useState<string>("");
  const [buttonText, setButtonText] = useState<string>("");

  const renderProviderRecent = useMemo(() => {
    return (
      <>
        {providers.length > 0 ? (
          <ul>
            {providers.map((provider) => (
              <ItemUI
                key={provider.name}
                item={{
                  leftIcon: (
                    <span className="avatar d-flex--center">
                      <img src={avatar} alt="Provider avatar" />
                    </span>
                  ),
                  name: `${provider.jobTitle} ${provider.name}`,
                  detail: "",
                }}
              />
            ))}
          </ul>
        ) : (
          <span className="no-result">No results found.</span>
        )}
      </>
    );
  }, [providers]);

  // function to get external link

  const getExternalLinkWithTypeGuard = async () => {
    const createAxiosResponseError = () =>
      new Error("Axios request returned void");
    const createTypeguardError = () =>
      new Error("Typeguard could not validate response");
    const res = await axiosGet(`/tenant/V1/client-portal-external-link`);
    if (!res || !res.data) {
      throw createAxiosResponseError();
    }
    const validResponse = ResponseTypeguards.validateExternalLink(res);
    if (!validResponse) {
      throw createTypeguardError();
    }
    // console.log("validresponse", validResponse);
    return validResponse;
  };

  const getExternalLink = async () => {
    try {
      const res = await getExternalLinkWithTypeGuard();

      if (res) {
        // console.log("res nee", res);
        setExternalLink(res.settings.externalUrl);
        setButtonText(res.settings.buttonText);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getExternalLink();
  });

  return (
    <div className="dashboard-container__right">
      {routeFlag["online-booking"] && (
        <LinkButton
          redirectTo={`/${practiceName}/online-booking`}
          txtButton="Request an appointment"
          className="btn"
          withShadow={true}
        />
      )}

      <LinkButton
        redirectTo={`/${practiceName}/pets`}
        txtButton="View my pets"
      />

      <div
        onClick={() => {
          window.open(
            `//${externalLink}`,
            "_blank"
          );
        }}
      >
        <LinkButton redirectTo="#" txtButton={buttonText} />
      </div>
      <Card
        title="Recent providers"
        children={renderProviderRecent}
        loading={loading}
        classMore="small-scroll"
      />
    </div>
  );
};

export default RightDashboard;
