const styles = {
  dateTimeContainer: {
    display: 'flex',
    width: '100%',
  },
  inputContainer: {
    flex: '0 1 53%',
    marginBottom: '15px',
  },
  calendarContainer: {
    border: '1px solid #D4DBE4',
    backgroundColor: 'white',
    borderRadius: '6px',
    width: 'fit-content'
  },
  calendarStyles: {
    allDay: {
      fontSize: '12px',
      borderRadius: '4px',
      padding: '8px 22px 6px 20px',
      boxSizing: 'border-box',
      textAlign: 'center',
    },
    highlighted: {
      color: 'white',
      backgroundColor: '#2F68FD',
    },
  },
  readOnly: {
    cursor: 'not-allowed',
    backgroundColor: '#d4d4d4',
    pointerEvents: 'none',
  },
  weekDay: {
    fontSize: '12px',
    color: '#6E84A3',
  },
  arrowButton: {
    position: 'absolute',
    top: '18px',
    right: '33px',
  },
  displayColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }
};

export default styles;
