const styles = {
  timeSlotSelectorContainer: {
    display: 'flex',
    border: '1px solid #D4DBE4',
    padding: '10px 10px 0px 10px;',
    minHeight: '160px',
    backgroundColor: 'white',
    borderRadius: '4px',
    fontSize: '10px',
    color: '#12253F',
  },
  inputContainer: {
    flex: '1 1 46%',
    marginBottom: '15px',
  },
  timeSlotContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexFlow: 'wrap',
    minHeight: '53px',
  },
  readOnly: {
    cursor: 'not-allowed',
    backgroundColor: '#d4d4d4',
    pointerEvents: 'none',
  },
  timeSlotAmPmContainer: {
    margin: 'auto',
    display: 'inline-flex',
    flexDirection: 'column',
    width: '100%'
  }
}


export default styles;
