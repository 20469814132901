import moment from 'moment';
import { UserResponseType } from 'services/ResponseTypes/User';
import { AddressResponseType } from 'services/ResponseTypes/Address';
import { PetResponseType } from 'services/ResponseTypes/Pet';
import { PhoneNumberResponseType } from 'services/ResponseTypes/PhoneNumber';

interface PopulateName {
  firstName: string;
  lastName: string;
}

interface PopulateAddress {
  address1: string;
  address2: string;
  city: string;
  stateProvince: string;
  zipPostalCode: string;
}

interface PopulateDateType {
  contactName: PopulateName;
  contactEmailAddress: string;
  contactPhoneNumber: string;
  contactGender: string;
  contactAddress: PopulateAddress;
  coOwnerName: PopulateName;
  coOwnerEmailAddress: string;
  coOwnerPhoneNumber: string;
  coOwnerGender: string;
  coOwnerAddress: PopulateAddress;
  petName: string;
  petSex: string;
  petBirthdate: string;
  petSpecies: string;
  petBreed: string;
}

export const convertLocalTimeToAnotherTimeZone = (
  timeZoneOffset: string,
  newDate?: any
) => {
  const date = newDate ? newDate : new Date();
  const localTime = date.getTime();

  // get local timezone offset and convert to milliseconds
  const localOffset = date.getTimezoneOffset() * 60000;

  // obtain the UTC time in milliseconds
  const utc = localTime + localOffset;

  // const strTzOffset = timeZoneOffset.slice(3); // "UTC+07:00" to "+07:00"
  const tzOffset = moment.duration(timeZoneOffset).asHours();
  const dateTimeBySetting = utc + 3600000 * tzOffset;

  return moment(dateTimeBySetting);
};

export const convertDayOfYearLocalToTimeZone = (
  timezone: string,
  newDate?: any
) => {
  const now = convertLocalTimeToAnotherTimeZone(timezone, newDate);
  const start = moment(newDate).startOf('year');
  //@ts-ignore
  const diff = now - start;
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
};

export const convertPhoneNumber = (value: string) => {
  let input = value.replace(/\D/g, '');
  input = input.substring(0, 10);
  var size = input.length;
  // eslint-disable-next-line eqeqeq
  if (size == 0) {
    // eslint-disable-next-line no-self-assign
    input = input;
  } else if (size < 4) {
    input = '(' + input;
  } else if (size < 7) {
    input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
  } else {
    input =
      '(' +
      input.substring(0, 3) +
      ') ' +
      input.substring(3, 6) +
      '-' +
      input.substring(6, 10);
  }
  return input;
};

export const convertDataToMultipleArray = (items: Array<any>) => {
  let bigArray = [];
  let res = [];
  const sortedItems = items.sort(
    (a, b) => Date.parse(a.dateTime) - Date.parse(b.dateTime)
  );
  for (let i = 0; i < sortedItems.length; i += 1) {
    if (i === 0) res.push(sortedItems[i]);
    else {
      if (
        moment(sortedItems[i - 1].dateTime).isSame(
          moment(sortedItems[i].dateTime),
          'date'
        )
      )
        res.push(sortedItems[i]);
      else {
        bigArray.push(res);
        res = [sortedItems[i]];
      }
    }
  }
  bigArray.push(res);
  return bigArray;
};

export const getSettingByNamespace = (
  userInfo: UserResponseType,
  namespace: string
) => {
  // @ts-ignore
  return userInfo.userSettings?.find((f) => f.namespace == namespace);
};

export const convertStringToCamelCase = (
  strValue: string,
  spaceWord?: boolean
) => {
  const re = /[\/\-\s\&]/;
  const splitStr = strValue.toLowerCase().split(re);
  for (let i = 0; i < splitStr.length; i++) {
    if (i !== 0 && !!splitStr[i].trim()) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
  }
  return splitStr.join(spaceWord ? ' ' : '');
};

export const convertStringToPascalCase = (
  str: string,
  spaceWord?: string
): string => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(spaceWord ?? '');
};

const getAddress = (addresses: AddressResponseType[]): PopulateAddress => {
  const addressPrimary = (
    addresses.find((address) => address.isPrimary) ?? addresses[0]
  ).address;
  return {
    address1: addressPrimary.addressLine1 ?? '',
    address2: addressPrimary.addressLine2 ?? '',
    city: addressPrimary.city ?? '',
    stateProvince: addressPrimary.state ?? '',
    zipPostalCode: addressPrimary.postalCode ?? '',
  };
};

export const convertDataPopulate = (
  userInfo: UserResponseType,
  pet: PetResponseType,
  coOwner: UserResponseType
) => {
  let contactAddress = {
    address1: '',
    address2: '',
    city: '',
    stateProvince: '',
    zipPostalCode: '',
  };
  let coOwnerAddress = {
    address1: '',
    address2: '',
    city: '',
    stateProvince: '',
    zipPostalCode: '',
  };
  if (userInfo.addresses && userInfo.addresses?.length > 0) {
    contactAddress = getAddress(userInfo.addresses);
  }
  if (coOwner.addresses && coOwner.addresses?.length > 0) {
    coOwnerAddress = getAddress(coOwner.addresses);
  }

  return {
    contactId: userInfo.userId || null,
    contactName: {
      firstName: userInfo.name?.givenName ?? '',
      lastName: userInfo.name?.lastName ?? '',
    },
    contactEmailAddress: userInfo.emailAddress ?? '',
    contactPhoneNumber: userInfo.phoneNumbers ?? [],
    contactAddress,
    contactGender: '',
    coOwnerName: {
      firstName: coOwner.name?.givenName ?? '',
      lastName: coOwner.name?.lastName ?? '',
    },
    coOwnerEmailAddress: coOwner.emailAddress ?? '',
    coOwnerPhoneNumber: coOwner.phoneNumbers ?? [],
    coOwnerGender: '',
    coOwnerAddress,
    petId: pet.petId || null,
    petName: pet.name,
    petSex: pet.sexId ?? '',
    petBirthdate: pet.birthDate ?? '',
    petSpeciesBreed: {
      petSpecies: pet.speciesExternalId || '',
      petBreed: pet.breedExternalId || '',
    },
  };
};

export const calculateAge = (dateOfBirth: string, dateNow?: string) => {
  const dateTime = new Date(dateOfBirth);
  const timeNow = dateNow ? new Date(dateNow) : new Date();

  const yearDiff = timeNow.getFullYear() - dateTime.getFullYear();
  const timeStampDiff =
    moment(dateTime).add(yearDiff, 'y').valueOf() - timeNow.getTime();

  if (yearDiff > 0 && timeStampDiff <= 0) {
    return { number: yearDiff, type: 'year' };
  }
  if (yearDiff > 1 && timeStampDiff > 0) {
    return { number: yearDiff - 1, type: 'year' };
  }

  const monthDiff = timeNow.getMonth() - dateTime.getMonth() + 12 * yearDiff;
  const timeStampDiffFollowMonth =
    moment(dateTime).add(monthDiff, 'M').valueOf() - timeNow.getTime();

  if (monthDiff > 0 && timeStampDiffFollowMonth <= 0) {
    return { number: monthDiff, type: 'month' };
  }
  if (monthDiff > 1 && timeStampDiffFollowMonth > 0) {
    return { number: monthDiff - 1, type: 'month' };
  }

  const dayDiff =
    (timeNow.getTime() - dateTime.getTime()) / (1000 * 60 * 60 * 24);

  return { number: Math.floor(dayDiff >= 1 ? dayDiff : 1), type: 'day' };
};

export const printAgeNumber = (dateOfBirth: string, dateNow?: string) => {
  const value = calculateAge(dateOfBirth, dateNow);

  return `${value.number} ${value.type}${value.number === 1 ? '' : 's'} old`;
};

export const getRandomNumber = () => {
  return Math.random();
};

export const getPracticeNameFromURL = (path: string) => {
  const arr = path.split('/');
  return arr[1];
};
