import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { loginWithCode, sendCodeAuth } from 'services/pratices';
import { getUserInfo } from 'scenes/Auth/redux/actions';
import { setSessionJWT } from 'services/SessionJWT';
import { Button } from '@material-ui/core';

interface IProps {
  methodLogin: string;
  setMethodLogin: React.Dispatch<SetStateAction<string>>;
}

const Keys = ['Backspace', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

const ErrorMessage = 'Authentication Code cannot be blank.';

const TIME_DEFAULT = 59;

const AuthCode = ({ methodLogin, setMethodLogin }: IProps) => {
  window.document.title = 'Patient Portal | Auth Code';

  const history = useHistory();
  const dispatch = useAppDispatch();
  const micrositeName = useAppSelector(
    (state) => state.formsPatientPortal.practiceName
  );
  const { username, password } = useAppSelector((state) => state.auth);
  const [code, setCode] = useState<string>('');
  // mount = error when component mount, not show in message error
  const [error, setError] = useState<string>('mount');
  const [errorResponse, setErrorResponse] = useState<string>('');
  const [time, setTime] = useState<number>(TIME_DEFAULT);

  useEffect(() => {
    handleSendCode();
    handleIntervalTime();

    return () => {
      setMethodLogin('');
    };
  }, []);

  const handleSendCode = useCallback(async () => {
    try {
      const res = await sendCodeAuth(
        username,
        password,
        micrositeName,
        methodLogin
      );
    } catch (err) {
      console.log('err', err);
    }
  }, [username, password, micrositeName, methodLogin]);

  const handleVerify = useCallback(async () => {
    try {
      const res = await loginWithCode({
        provider: methodLogin,
        token: code,
        username,
        password,
        micrositeName,
      });

      if (true) {
        setSessionJWT(res);
        dispatch(getUserInfo());
        history.push(`/${micrositeName}/dashboard`);
      }
    } catch (err) {
      // @ts-ignore
      setErrorResponse(err?.response?.data?.message);
    }
  }, [code, username, password, micrositeName, methodLogin]);

  const handleResendCode = () => {
    if (time === 0) {
      handleSendCode();
      setTime(TIME_DEFAULT);
      handleIntervalTime();
    }
  };

  const handleIntervalTime = useCallback(() => {
    let second = TIME_DEFAULT;
    const timeInterval = setInterval(() => {
      second -= 1;
      if (second < 0) {
        clearInterval(timeInterval);
      } else {
        setTime(second);
      }
    }, 1000);
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorResponse('');
    const lengthValue = e.target.value.length;
    if (lengthValue > 6) {
      return;
    }
    if (lengthValue === 0) {
      setError(ErrorMessage);
    } else if (lengthValue < 6) {
      setError('mount');
    } else {
      setError('');
    }
    setCode(e.target.value);
  }, []);

  const handleBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setError(ErrorMessage);
    }
  }, []);

  const handleInput = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!Keys.includes(e.key)) {
        e.preventDefault();
        return;
      }
    },
    []
  );

  return (
    <form>
      <div className="auth__input-container">
        <p className="title">Two-factor authentication</p>
        <p className="description">
          We just sent you a text message with your authentication code. Enter
          the code to verify your identify.
        </p>
        <label htmlFor="">6-digit code</label>
        <input
          type="number"
          autoComplete="off"
          required
          placeholder="6-digit code"
          name="smsCode"
          value={code}
          onKeyPress={handleInput}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        {error && error !== 'mount' && (
          <p className="error-msg">
            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
            <span>{error}</span>
          </p>
        )}
        {errorResponse && (
          <p className="error-msg">
            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
            <span>{errorResponse}</span>
          </p>
        )}
      </div>

      <Button
        variant="contained"
        color="primary"
        className="btn btn-login"
        disabled={!!error || !!errorResponse}
        type="button"
        onClick={handleVerify}
      >
        Verify
      </Button>

      <span
        className={`btn-resend ${time !== 0 && 'disabled'}`}
        onClick={handleResendCode}
      >
        Resend the code
      </span>
      {time > 0 && (
        <span className="time-countdown">{`00:${
          time > 9 ? time : `0${time}`
        }`}</span>
      )}
    </form>
  );
};

export default AuthCode;
