import React from 'react';
import { FormFieldInput } from './form-field-input';
import { FormFieldBase } from './form-field-base';
import { getFieldProperties } from '../helpers';

const FormFieldPatientDropdown = ({
  field,
  isRenderedField,
  onChange,
  value,
  onBlur,
  validationMessage,
  readOnly,
  familyMemberList,
}) => {
  const properties = getFieldProperties(field, ['common', 'specific']);

  const handleChange =  (e) => onChange({
    ...e,
    id: field.uuid,
  }, true)

  return (
    <FormFieldInput
      field={field}
      options={{
        properties,
        isRenderedField,
        onChange: handleChange,
        value,
        onBlur,
        validationMessage,
        readOnly,
      }}
    >
      {isRenderedField
        ? <>
        <option selected={value === ''} value=''>Select a patient</option>
        {
          familyMemberList.map((member, index) => (
            <option key={index} value={member.id} selected={value === member.id}>
              {member.display}
            </option>
          ))
        }
        </>
        : null}
    </FormFieldInput>
  )
}

FormFieldPatientDropdown.defaultProps = {
  isRenderedField: false,
  onChange: () => { },
  value: '',
  onBlur: () => { },
  readOnly: false,
};

export default FormFieldBase(FormFieldPatientDropdown);
