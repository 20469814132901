import React from 'react';

const FormFieldInputTextArea = ({ className, isRenderedField, onChange, maxWords, ...rest }) => {
  const handleChange = e => {
    const { value } = e.target;

    if (maxWords !== undefined && maxWords !== null) {
      const words = value ? value.split(' ') : 0;
      // truncate text to max allowed words if exceeded
      if (words.length > maxWords) e.target.value = words.slice(0, maxWords - 1).join(' ');
    }

    onChange(e);
  };

  const dynamicProps = {};

  if (isRenderedField) {
    dynamicProps.onChange = handleChange;
  } else {
    dynamicProps.readOnly = true;
    dynamicProps.disabled = true;
  }

  return (<>
    <div className="long-text-print">{rest.value}</div>
    <textarea className={className} rows={5} {...dynamicProps} {...rest} />
  </>);
};

FormFieldInputTextArea.defaultProps = {
  className: 'span30',
  isRenderedField: false,
  onChange: () => { },
};

export default FormFieldInputTextArea;
