import * as t from "io-ts";

export const AuthJWTResponse = t.type({
  access_token: t.string,
  expires_in: t.number,
  refresh_token: t.string,
  token_type: t.string,
});

export type AuthJWTResponseType = t.TypeOf<typeof AuthJWTResponse>;
