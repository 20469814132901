import React from 'react';
import { FormFieldBase } from "./form-field-base";
import { FormFieldInput } from "./form-field-input";
import { getFieldProperties, getFieldPropertyValues } from '../helpers';

export const FormFieldUpload = ({
    field,
    isRenderedField,
    onChange,
    value,
    onBlur,
    validationMessage,
    onUpdate,
    files,
    setFiles,
    readOnly,
}) => {
    const properties = getFieldProperties(field, ['common', 'specific']);

    const options = {
        properties,
        isRenderedField,
        onChange,
        value,
        onUpdate,
        onBlur,
        validationMessage,
        readOnly,
    };

    return <FormFieldInput field={field} options={options} files={files} setFiles={setFiles} />;
};

FormFieldUpload.defaultProps = {
    isRenderedField: false,
    onChange: () => { },
    value: '',
    onBlur: () => { },
};

export default FormFieldBase(FormFieldUpload);
