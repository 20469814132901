import styled from "styled-components";

export const Wrapper = styled.header`
  padding: 20px 0;
  margin-bottom: 36px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  nav {
    padding: 0 16px;
  }

  .nav-link__header {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .menu--desktop,
    .account--desktop {
      display: none;
    }

    .menu--desktop {
      margin: auto;
    }

    .account--desktop {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .account {
        position: relative;
        cursor: pointer;

        .dropdown-rectangle {
          position: absolute;
          content: "";
          top: 105%;
          right: 25px;
          z-index: 1300;
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 15px solid #fff;
        }

        .account-options {
          padding: 5px 0;
          position: absolute;
          content: "";
          top: calc(105% + 15px);
          right: 0;
          background-color: #fff;
          border-radius: 12px;
          /* box-shadow: 0 5px 10px 0 rgb(60 128 209 / 26%); */
          box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08),
            0 2px 4px 0 rgba(0, 0, 0, 0.04);
          z-index: 13333;

          li {
            padding: 15px 12px;
            font-size: 13px;
            color: #2c3742;

            a,
            span {
              display: block;
              width: 200px;
              padding-left: 10px;
              text-align: left;
              color: #000;
            }

            svg {
              margin-right: 5px;
            }
          }
        }
      }

      & > a {
        margin-left: 20px;

        .MuiButton-root {
          background-color: #2f68fd;
          color: #fff;
          font-size: 15px;
          border-radius: 6px;
          text-transform: capitalize;
        }
      }
    }

    .menu-bar__icon {
      margin-left: auto;
      color: #000;
      font-size: 1.8rem;
      cursor: pointer;
    }

    a {
      color: #2c3742;
      font-family: "Nunito Sans", sans-serif;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
    }

    ul {
      text-align: center;

      li {
        padding: 0 15px;

        a.active {
          position: relative;
          font-weight: 800;

          &::after {
            position: absolute;
            content: "•";
            top: calc(100% + 5px);
            left: 50%;

            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    nav {
      padding: 0 24px;
    }
  }

  @media screen and (min-width: 1024px) {
    .nav-link__header {
      .menu--desktop,
      .account--desktop {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
      }

      .menu-bar__icon {
        display: none;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    nav {
      padding: 0 calc((100% - 1232px) / 2);
    }
  }
`;
