import React from 'react';
import { FormFieldBase } from './form-field-base';
import { getFieldProperties, getFieldPropertyValues } from '../helpers';
import ReactHtmlParser from 'react-html-parser';
export const FormFieldDescriptionArea = ({ field }) => {
  const properties = getFieldProperties(field, ['common', 'specific']);
  const { descriptionContent } = getFieldPropertyValues(
    properties.specific,
    'descriptionContent'
  );

  return (
    <div className="description-area">
      <div className="rte-content">{ReactHtmlParser(descriptionContent)}</div>
    </div>
  );
};

const controls = {
  edit: true,
  delete: true,
  more: true
  // more: __DEV__,
};
export default FormFieldBase(FormFieldDescriptionArea, controls);
