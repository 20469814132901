import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core"
import {  faFile, faFilePdf, faFileWord, faImage } from '@fortawesome/pro-regular-svg-icons';
import { faUpload, faX } from '@fortawesome/pro-solid-svg-icons';

library.add(faUpload, faImage, faFile, faFilePdf, faFileWord, faX)

const FormFieldInputFile = ({
    type,
    className,
    isRenderedField,
    onChange,
    value,
    displayName,
    files,
    setFiles,
    ...rest
}) => {
    const [selected, setSelected] = useState(false);
    const [error, setError] = useState("");

    const setValue = (newFiles, previousErrors = "") => {
        if (rest.disabled) return;
        let maxFileSize = 10; // filesize in megabytes
        let maxFiles = 10;
        let sizeFilteredFiles = newFiles.filter(f => f.size < maxFileSize * 1000000);
        let newErrors = previousErrors;
        if (newFiles.length != sizeFilteredFiles.length) {
            newErrors += `Only files under ${maxFileSize} megabytes can be uploaded. `;
        }
        let amountFilteredFiles = sizeFilteredFiles.filter((f, i) => i < maxFiles);
        if (sizeFilteredFiles.length != amountFilteredFiles.length) {
            newErrors += `Only ${maxFiles} files can be selected at once. `;
        }
        let returnValue = {
            target: {
                id: rest.id,
                value: "",
                files: amountFilteredFiles
            }
        }
        setError(newErrors);
        setFiles(amountFilteredFiles);
        onChange(returnValue);
    }

    const handleChange = e => {
        let newSelectedFiles = [...e.target.files];
        let duplicateNameFilteredFiles = newSelectedFiles.filter(f1 => !files.some(f2 => f2.name === f1.name));
        let newErrors = "";
        if (newSelectedFiles.length != duplicateNameFilteredFiles.length) {
            newErrors = "All files must have unique names. ";
        }
        let newFiles = [...files, ...newSelectedFiles];
        setValue(newFiles, newErrors);
    }

    const resetField = e => {
        e.preventDefault();
        e.stopPropagation();
        if (rest.disabled) return;
        setValue([]);
    }

    const handleDrag = e => {
        e.preventDefault();
        e.stopPropagation();
        if (rest.disabled) return;
        if (e.type === "dragenter" || e.type === "dragover") {
            setSelected(true);
        } else if (e.type === "dragleave") {
            setSelected(false);
        }
    };

    const handleDrop = e => {
        setError("");
        e.preventDefault();
        e.stopPropagation();
        if (rest.disabled) return;
        setSelected(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            let droppedFiles = [...e.dataTransfer.files];
            let duplicateNameFilteredFiles = droppedFiles.filter(f1 => !files.some(f2 => f2.name === f1.name));
            let newErrors = "";
            if (droppedFiles.length != duplicateNameFilteredFiles.length) {
                newErrors += "All files must have unique names. ";
            }
            let fileTypeFilteredFiles = duplicateNameFilteredFiles.filter(f => ['application/pdf', 'image/png', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(f.type));
            if (duplicateNameFilteredFiles.length != fileTypeFilteredFiles.length) {
                newErrors += "Only allowed file types are PDF's, Word documents, and images. ";
            }
            let newFiles = [...files, ...fileTypeFilteredFiles];
            setValue(newFiles, newErrors);
        }
    }

    const handleRemove = (name, e) => {
        e.preventDefault();
        e.stopPropagation();
        let newFiles = files.filter(f => f.name != name);
        setValue([...newFiles]);
    }

    const handleMouseHover = (e, shouldSelect) => {
        e.preventDefault();
        e.stopPropagation();
        if (rest.disabled) return;
        setSelected(shouldSelect);
    }

    const dynamicProps = {};
    if (isRenderedField) {
        dynamicProps.onChange = handleChange;
    } else {
        dynamicProps.readOnly = true;
        dynamicProps.disabled = true;
    }

    const getIcon = val => {
        let ext = val.split('.').pop();
        if(['png', 'jpg', 'jpeg'].includes(ext)) {
            return <FontAwesomeIcon
                className="form-field-file-doc-icon"
                icon={['far', 'image']}
            />
        } else if(['pdf'].includes(ext)) {
            return <FontAwesomeIcon
                className="form-field-file-doc-icon"
                icon={['far', 'file-pdf']}
            />
        } else if(['doc', 'docx'].includes(ext)) {
            return <FontAwesomeIcon
                className="form-field-file-doc-icon"
                icon={['far', 'file-word']}
            />
        } else {
            return <FontAwesomeIcon
                className="form-field-file-doc-icon"
                icon={['far', 'file']}
            />
        }
    }

    return (
        <label className={selected ? "form-field-file-label form-field-file-label-selected" : "form-field-file-label"} onDragEnter={handleDrag} onDragOver={handleDrag} onDragLeave={handleDrag} onDrop={handleDrop} onMouseEnter={e => handleMouseHover(e, true)} onMouseLeave={e => handleMouseHover(e, false)}>
            {value == "" ?
                <>
                    <span className="form-field-file-label-placeholder">{rest.placeholder}</span>
                    <FontAwesomeIcon
                        className="form-field-file-background"
                        icon={['fas', 'upload']}
                    />
                </> :
                value.split("|").map(val =>
                (
                    <div className="form-field-file-doc" onMouseEnter={e => handleMouseHover(e, false)} onMouseLeave={e => handleMouseHover(e, true)}>
                        <FontAwesomeIcon
                            className="form-field-file-doc-exit"
                            icon={['fas', 'x']}
                            onClick={e => handleRemove(val, e)}
                        />
                        {getIcon(val)}
                        <span className="form-field-file-doc-name-container"><span className="form-field-file-doc-name">{val}</span></span>
                    </div>
                )
                )
            }
            <input
                style={{ "display": "none" }}
                type={type}
                className={className}
                {...rest}
                {...dynamicProps}
                multiple={true}
                value=""
                accept={'application/pdf, image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'}
            />
            <span onClick={e => resetField(e)} className="form-field-file-clear" title="Clear uploaded documents">Clear</span>
            <span className="form-field-file-error" style={error == "" ? {"display": "none"} : {"display": "initial"}}>{error}</span>
        </label>
    );
};

FormFieldInputFile.defaultProps = {
    className: '',
    isRenderedField: false,
    onChange: () => { },
};

export default FormFieldInputFile;
