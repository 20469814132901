import * as t from "io-ts";
import { UserInfoResponse } from "./Tenant";
import { PetCollection } from "./Pet";
import { TaskEntryData } from "./Task";

const UserCollection = t.type({
  count: t.number,
  users: t.array(UserInfoResponse),
});

const AppointmentResponseRequired = t.type({
  externalId: t.string,
  attendees: UserCollection,
  patients: PetCollection,
  dateTime: t.string,
  appointmentId: t.string,
  status: t.string,
  kind: t.string,
});

const AppointmentResponseNullable = t.partial({
  kind: t.string,
  durationMinutes: t.number,
  tasks: t.array(TaskEntryData),
  teleMedKey: t.string,
  externalId: t.string,
  appointmentUpdatedTime: t.string,
  providerName: t.string,
  appointmentTypeName: t.string,
});

export const AppointmentResponse = t.intersection([
  AppointmentResponseRequired,
  AppointmentResponseNullable,
]);
export type AppointmentResponseType = t.TypeOf<typeof AppointmentResponse>;

export const AppointmentCollectionResponse = t.type({
  //  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  appointments: t.array(AppointmentResponse),
});

export const AppointmentCollectionCustom = t.type({
  //  offset: t.number,
  count: t.number,
  totalAvailable: t.number,
  appointments: t.array(t.array(AppointmentResponse)),
});

export type AppointmentCollectionResponseType = t.TypeOf<
  typeof AppointmentCollectionResponse
>;

export type AppointmentCollectionCustomType = t.TypeOf<
  typeof AppointmentCollectionCustom
>;
