import React, { FC } from "react";
import ModalDialog from ".";

interface IProps {
  isOpenModal: boolean;
  title?: string;
  handleCloseModal: (args: boolean) => void;
  handleConfirmModal?: () => void;
}

const ModalDiscard: FC<IProps> = ({
  isOpenModal,
  title = "Discard Changes?",
  handleCloseModal,
  handleConfirmModal,
}) => {
  return (
    <>
      <ModalDialog
        isOpenModal={isOpenModal}
        title={title}
        children="Your data will be lost. Do you wish to continue?"
        classMore="modal--discard-change"
        handleCloseModal={() => handleCloseModal(false)}
        handleConfirmModal={handleConfirmModal}
      />
    </>
  );
};

export default ModalDiscard;
