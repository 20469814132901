import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'hooks/useRouter';
import { Card, CardContent, Container, Grid } from '@material-ui/core';
import { useStyles } from 'scenes/FormsPatientPortal/Dashboard.style';
import { getLogoPractice } from '../FormsPatientPortal/redux/actions';
import FormRenderer from 'components/PatientPortal/form-renderer';
import { APPOINTMENT_BOOKING } from 'constants/app.constant';
import { get } from 'lodash';
import {
  getCoOwnerForPet,
  getDetailAppointmentBooking,
  getPetsForContact,
} from 'services/pratices';
import loading_spinner from 'assets/images/loading.svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { PetResponseType } from 'services/ResponseTypes/Pet';
import { convertDataPopulate } from 'utils/convertData';
import {
  coOnwerInital,
  petInitial,
} from 'scenes/FormsPatientPortal/FormsPatientPortalVM';
import { UserResponseType } from 'services/ResponseTypes/User';
import { getUserInfo } from 'scenes/Auth/redux/actions';
import { getSessionJWT } from 'services/SessionJWT';

const OnlineBooking = () => {
  const classes = useStyles();
  const router = useRouter();
  const dispatchAction = useAppDispatch();
  // @ts-ignore
  const { practiceMicrositeName } = router.match.params;
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const [pets, setPets] = useState<PetResponseType[]>([petInitial]);
  const [selectedPet, setSelectPet] = useState<PetResponseType>(petInitial);
  const [dataSubmissionDetail, setDataSubmissonDetail] = useState<any>();
  const [coOwner, setCoOwner] = useState<UserResponseType>(coOnwerInital);
  const [fullData, setFullData] = useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [daysLoading, setDaysLoading] = React.useState<boolean>(true);
  const [timeLoading, setTimeLoading] = React.useState<boolean>(true);
  const [isError, setIsError] = React.useState<boolean>(false);
  const objectField = {
    fields: [
      { display_name: 'Patient' },
      { display_name: 'Appointment Type' },
      { display_name: 'Provider' },
      { display_name: 'Appointment Date' },
      { display_name: 'Appointment Time' },
    ],
  };

  useEffect(() => {
    const isAuth = getSessionJWT();
    if (isAuth) {
      dispatchAction(getUserInfo());
    }

    if (!getSessionJWT()) {
      let header = document.getElementsByClassName('nav-link__header')[0];
      if (header != undefined) {
        // @ts-ignore
        document.getElementsByClassName('nav-link__header')[0].style.display = "none";
      }
    }

  }, []);

  useEffect(() => {
    if (userInfo.userId) {
      Promise.all([getListPet(), getDetailForm()])
        .then((res) => setIsLoading(false))
        .catch((err) => setIsLoading(false));
    } else {
      getDetailForm()
        .then((res) => setIsLoading(false))
        .catch((err) => setIsLoading(false));
    }
  }, [userInfo.userId]);

  useEffect(() => {
    if (selectedPet.petId && userInfo.userId) {
      getCoOwner();
    }
  }, [selectedPet.petId, userInfo.userId]);

  // useEffect(() => {
  //   if (practiceMicrositeName) {
  //     dispatchAction(getLogoPractice(practiceMicrositeName));
  //   }
  // }, [practiceMicrositeName]);

  const getListPet = useCallback(async () => {
    try {
      const res = await getPetsForContact(userInfo.userId);

      if (res) {
        const activePets = res.pets.filter((pet) => pet.status === 'active');
        setPets([petInitial, ...activePets]);
        const redirectPetId = router.location.state;
        if (redirectPetId) {
          setSelectPet(
            activePets.find(
              (pet: PetResponseType) => pet.petId === redirectPetId
            ) ?? petInitial
          );
          router.history.replace(router.pathname, '');
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  }, [userInfo.userId]);

  const getCoOwner = useCallback(async () => {
    try {
      const res = await getCoOwnerForPet(userInfo.userId, selectedPet.petId);

      if (res) {
        setCoOwner(res);
      }
    } catch (err) {
      console.log('err', err);
    }
  }, [userInfo.userId, selectedPet.petId]);

  const getDetailForm = useCallback(async () => {
    try {
      const response = await getDetailAppointmentBooking(practiceMicrositeName);
      setIsError(false);
      setDataSubmissonDetail(get(response, 'data.versionObject.fields', []));
      setFullData(get(response, 'data', []));
    } catch (error) {
      setIsError(true);
    }
  }, [practiceMicrositeName]);

  const handleSelectPet = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectPet(
      pets.find((pet) => pet.petId === e.target.value) ?? petInitial
    );
    if (!e.target.value) {
      setCoOwner(coOnwerInital);
    }
  };

  const renderSelectPet = useMemo(() => {
    return (
      <div className="form-field-input-container select-pet">
        <div className="form-field-label primary">Pet *</div>
        <select
          name="populatePet"
          value={selectedPet.petId}
          onChange={handleSelectPet}
        >
          {pets.map((pet) => (
            <option value={pet.petId}>{pet.name || 'New Pet'}</option>
          ))}
        </select>
      </div>
    );
  }, [pets, selectedPet]);

  return (
    <>
      {isLoading ? (
        <img src={loading_spinner} alt="Loading" className="loading-spinner" />
      ) : (
        <Container>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} sm={12}>
              <Card className={`${classes.borderRadius15}`} elevation={0}>
                <CardContent className={classes.cardContent}>
                  {!isError ? (
                    <>
                      <div className="class-name-form-unique form-booking-appointment">
                        <FormRenderer
                          isShowInformation={true}
                          formVersion={{
                            object: { fields: dataSubmissionDetail },
                          }}
                          allowSubmit={true}
                          field={objectField}
                          fullData={fullData}
                          // submission={submission}
                          practiceMicrositeName={practiceMicrositeName}
                          saveAsGuest={false}
                          type={APPOINTMENT_BOOKING}
                          populateData={convertDataPopulate(
                            userInfo,
                            selectedPet,
                            coOwner
                          )}
                          renderSelectPet={
                            userInfo.userId ? renderSelectPet : null
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <span className="form--not-publish">
                      This form has not been published yet.
                    </span>
                  )}
                </CardContent>
              </Card>
              <Grid item={true} xs={3} sm={3}></Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default OnlineBooking;
