import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import WithHeader from './WithHeader';
import Loading from 'scenes/Loading';
import Auth from 'scenes/Auth/AuthContainer';
import SubscriptionPatientPortal from 'scenes/Subscriptions/SubscriptionPatientPortal';
import PatientDocument from 'scenes/FormsPatientList/PatientDocument.view';
import FormsPatientPortalVM from 'scenes/FormsPatientPortal/FormsPatientPortalVM';
import OnlineBooking from 'scenes/OnlineBooking/OnlineBooking';
import PageNotFound from 'scenes/PageNotFound';
import PetsContainer from 'scenes/Pets/PetsContainer';
import DashBoardContainer from '../scenes/DashBoard/DashboardContainer';

const randomNumber = Math.random();

const RouteLookup = {
  FormWithSubmission: () => (
    <Route
      exact
      path="/submissionToPrint/:formId/:submissionId"
      key={`formToPrint ${randomNumber}`}
    >
      <FormsPatientPortalVM/>
    </Route>
  ),

  Auth: () => (
    <Route
      exact
      path="/:practiceMicrositeName/(login|forgot-password|auth-code|register|register-success|register-unsuccess|setPassword)/"
      key={`auth ${randomNumber}`}
    >
      <Auth />
    </Route>
  ),

  Subscription: () => (
    <PrivateRoute
      exact
      path="/:practiceMicrositeName/subscription"
      key={`subscription ${randomNumber}`}
      component={() => (
        <WithHeader
          component={SubscriptionPatientPortal}
          titleBar="Subscription"
        />
      )}
    />
  ),

  Dashboard: () => (
    <PrivateRoute
      exact
      path="/:practiceMicrositeName/dashboard"
      key={`dashboard ${randomNumber}`}
      component={() => (
        <WithHeader component={DashBoardContainer} titleBar="Dashboard" />
      )}
    />
  ),

  Pets: () => (
    <PrivateRoute
      path="/:practiceMicrositeName/pets"
      key={`pets ${randomNumber}`}
      component={() => (
        <WithHeader component={PetsContainer} titleBar="Dashboard" />
      )}
    />
  ),

  OnlineBooking: () => (
    <Route
      exact
      path="/:practiceMicrositeName/online-booking"
      key={`onlinebooking ${randomNumber}`}
    >
      <WithHeader component={OnlineBooking} titleBar="Online Booking" />
    </Route>
  ),

  ClientForms: () => (
    <Route
      exact
      path="/:practiceMicrositeName/forms"
      key={`clientforms ${randomNumber}`}
    >
      <WithHeader component={PatientDocument} titleBar="Client Forms" />
    </Route>
  ),

  Form: () => (
    <Route
      exact
      path="/:practiceMicrositeName/forms/:formId"
      key={`form ${randomNumber}`}
    >
      <WithHeader component={FormsPatientPortalVM} titleBar="Client Forms" />
    </Route>
  ),

  FormWithAutoFill: () => (
    <Route
      exact
      path="/:practiceMicrositeName/forms/:formId/:autoFillId"
      key={`form ${randomNumber}`}
    >
      <WithHeader component={FormsPatientPortalVM} titleBar="Client Forms" />
    </Route>
  ),

  Loading: () => (
    <Route
      exact
      path="/:practiceMicrositeName/practice/(dashboard|subscription|login)"
      key={`loading ${randomNumber}`}
    >
      <Loading />
    </Route>
  ),

  RedirectToLogin: () => (
    <Route path="*" key={`all ${randomNumber}`}>
      <WithHeader component={PageNotFound} titleBar="Not Found" />
    </Route>
  ),
};

const AppRouter = () => {
  return (
    <Router>
      <Switch>{Object.values(RouteLookup).map((route) => route())}</Switch>
    </Router>
  );
};

export default AppRouter;
