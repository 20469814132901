import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  padding-bottom: 50px;
`;

// eslint-disable-next-line import/prefer-default-export
export { ButtonContainer };
