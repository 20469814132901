import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOnBlurDropdown } from "hooks/useOnBlurDropdown";
import useOnClickOutSide from "hooks/useOnClickOutSide";
import { toast } from "react-toastify";
// import "./index.scss";

interface IGroup {
  id: string;
  groupName: string;
  emailAddress: string;
  phoneNumbers: string;
  status: string;
}

interface StatusSelectProps {
  currentStatus: string;
  id: string;
  // refreshOnSubmitStatusConfig?: {
  //   handleSubmitAppointmentGet: (
  //     args: GetCurrentTenantAppointmentInRangeArguments
  //   ) => void;
  //   getDailyAppointmentArguments: GetCurrentTenantAppointmentInRangeArguments;
  // };
  customize?: {
    alignDropdownFromRight?: boolean;
  };
  getDataAfterChangeStatus?: () => void;
  screen?: string;
}

const defautlOptions = ["confirmed", "unconfirmed", "completed"];

const listStatus = {
  inProgress: "In Progress",
  completed: "Completed",
  confirmed: "Confirmed",
  unconfirmed: "Unconfirmed",
  complete: "Completed",
  active: "Active",
  inactive: "Inactive",
};

const StatusSelect = (props: StatusSelectProps) => {
  const { screen = "", id } = props;
  const [statusOptions, setStatusOptions] = useState<string[]>([
    ...defautlOptions,
  ]);
  const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);
  const [userSelectedStatusText, setUserSelectedStatusText] =
    React.useState<string>("");
  const clickOutSideRef = useRef();
  const openDropdownRef = React.createRef<HTMLDivElement>();

  // @ts-ignore
  useOnClickOutSide(clickOutSideRef, () => setIsDropdownOpened(false));

  // useEffect(() => {
  //   if (screen === "Group" || screen === "Practice") {
  //     setStatusOptions(["active", "inactive"]);
  //   }
  // }, [screen]);

  // const submitAppointmentStatusUpdate = async (
  //   event: React.SyntheticEvent,
  //   statusText: string
  // ) => {
  //   if (props.currentStatus === statusText) {
  //     setIsDropdownOpened(false);
  //     setUserSelectedStatusText("");
  //     return;
  //   }
  //   try {
  //     event.preventDefault();
  //     setIsDropdownOpened(false);
  //     const res = await configuredRequests.PATCH.updateAppointmentStatus(
  //       id,
  //       statusText === "completed" ? "complete" : statusText
  //     );
  //     if (res) {
  //       setUserSelectedStatusText(statusText);
  //       props.getDataAfterChangeStatus && props.getDataAfterChangeStatus();
  //       toast.success(
  //         <div className="notify-success d-flex">
  //           <FontAwesomeIcon
  //             icon={["fas", "check-circle"]}
  //             className="icon-check-status"
  //           />
  //           <div className="success-message">
  //             <b>Successfully</b>
  //             <p>Update status successfully!</p>
  //           </div>
  //         </div>,
  //         { className: "toast-success" }
  //       );
  //     }
  //   } catch (err) {
  //     toast.error(
  //       <div className="notify-unsuccess d-flex">
  //         <FontAwesomeIcon
  //           icon={["fal", "times-circle"]}
  //           className="icon-check-status"
  //         />
  //         <div className="unsuccess-message">
  //           <b>Error</b>
  //           <p>Update appointment status failed. Please try again.</p>
  //         </div>
  //       </div>,
  //       { className: "toast-unsuccess" }
  //     );
  //   }
  // };

  const convertStringStatus = (status: string) => {
    //@ts-ignore
    return status ? listStatus[status] : status;
  };

  // const toggleDropdown = (status: string) => {
  //   if (["complete", "completed"].includes(status)) {
  //     return;
  //   }
  //   setIsDropdownOpened(!isDropdownOpened);
  // };

  // useOnBlurDropdown(
  //   ["appointment__entry-button", "status_select-option-menu-button"],
  //   () => {
  //     setIsDropdownOpened(false);
  //   }
  // );

  return (
    <div
      className={"status_select-dropdown-container"}
      //@ts-ignore
      // ref={clickOutSideRef}
    >
      <div
        tabIndex={0}
        className={
          "appointment__entry-button " +
          (userSelectedStatusText.length
            ? userSelectedStatusText
            : props.currentStatus)
        }
        // onClick={() => {
        //   const currentStatus =
        //     userSelectedStatusText === ""
        //       ? props.currentStatus
        //       : userSelectedStatusText;
        //   toggleDropdown(currentStatus);
        // }}
        // onKeyPress={(event: React.KeyboardEvent) => {
        //   switch (event.key) {
        //     case " ":
        //     case "Enter": {
        //       openDropdownRef.current?.click();
        //       break;
        //     }
        //   }
        // }}
      >
        <div className="status_select-text">
          {userSelectedStatusText.length
            ? convertStringStatus(userSelectedStatusText)
            : convertStringStatus(props.currentStatus)}
        </div>
      </div>

      {/* <ul
        className={
          "status_select-ul" +
          (isDropdownOpened ? " opened" : "") +
          (props.customize?.alignDropdownFromRight ? " align-from-right" : "")
        }
      >
        {statusOptions.map((statusText: string, statusIndex: number) => {
          const currentStatus =
            userSelectedStatusText === ""
              ? props.currentStatus
              : userSelectedStatusText;
          const statusIsSelected = statusText === currentStatus ? true : false;

          return (
            <li
              key={"status_select-option-" + statusText + "-" + statusIndex}
              className={
                "status_select-option " + (statusIsSelected ? " selected" : "")
              }
              onClick={(event: React.SyntheticEvent) => {
                event.stopPropagation();
                // submitAppointmentStatusUpdate(event, statusText);
              }}
            >
              <button className="status_select-option-menu-button">
                {statusIsSelected ? (
                  <FontAwesomeIcon
                    icon={["far", "check"]}
                    className="icon-check-status"
                  />
                ) : (
                  ""
                )}
                <div className={"status-option--inner-text " + statusText}>
                  {statusText}
                </div>
              </button>
            </li>
          );
        })}
      </ul> */}
    </div>
  );
};

export default StatusSelect;
