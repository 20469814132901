import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { getSettingByNamespace } from 'utils/convertData';
import { saveContactSubscription } from 'services/pratices';
import { InitialSubscriptionType } from 'services/RequestType';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Toast from 'components/Global/Toast';
import { getUserInfo } from "scenes/Auth/redux/actions";

const FormsSubscription = () => {
  const userInfo = useAppSelector(state => state.auth.userInfo);
  const dispatch = useAppDispatch();

  const getcommunicationTypeStatus = (communicationType: string) => {
    if (userInfo == null || userInfo == undefined) {
      return false;
    }

    let setting = getSettingByNamespace(userInfo, 'UserSubscriptionSetting');

    if (communicationType == 'text') {
      return !!setting?.settings?.sms;
    }

    if (communicationType == 'email') {
      return !!setting?.settings?.email;
    }

    if (communicationType == 'postal') {
      return !!setting?.settings?.postal;
    }

    if (communicationType == 'phone') {
      return !!setting?.settings?.phone;
    }

    if (communicationType == 'appointment') {
      return !!setting?.settings?.appointment;
    }

    if (communicationType == 'birthday') {
      return !!setting?.settings?.birthday;
    }

    if (communicationType == 'reminders') {
      return !!setting?.settings?.reminders;
    }

    return false;
  };

  const [stateSubscription, setStateSubscription] = useState<InitialSubscriptionType>({
    phone: true,
    postal: true,
    email: true,
    sms: true,
    appointment: true,
    birthday: true,
    reminders: true
  });

  useEffect(() => {
    setStateSubscription({
      phone: getcommunicationTypeStatus('phone'),
      postal: getcommunicationTypeStatus('postal'),
      email: getcommunicationTypeStatus('email'),
      sms: getcommunicationTypeStatus('text'),
      appointment: getcommunicationTypeStatus('appointment'),
      birthday: getcommunicationTypeStatus('birthday'),
      reminders: getcommunicationTypeStatus('reminders')
    })
  }, [userInfo]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'all') {
      setStateSubscription({
        phone: !e.target.checked,
        postal: !e.target.checked,
        email: !e.target.checked,
        sms: !e.target.checked,
        appointment: !e.target.checked,
        birthday: !e.target.checked,
        reminders: !e.target.checked
      })
    } else {
      setStateSubscription(prevState => ({...prevState, [e.target.name]: e.target.checked}));
    }
  }

  const changeStateSubscription = async () => {
    try {
      const response = await saveContactSubscription({
        namespace: 'UserSubscriptionSetting',
        settings: stateSubscription
      });

      if (response) {
        dispatch(getUserInfo());
        Toast.success("Your data has been saved successfully!");
      }
    } catch (error) {
      Toast.error("Your data has not been saved yet. Please try again.");
    }
  }

  return (
    <>
      {userInfo ? (
        <>
          <div className="subscription__form">
            <h3>Manage Your Subscriptions</h3>
            <h4>Your e-mail address:</h4>
            <input type="text" value={userInfo.maskedEmailAddress} disabled />
            <div className="subscription__warning">
              <b>
                <FontAwesomeIcon icon={faExclamationTriangle} /> Warning:
              </b>{" "}
              Unsubscribing from any form of communication includes appointment-related messaging.
            </div>
            <h4 className="subscription__title-unsubscribe">Unsubscribe</h4>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="all"
                    color="primary"
                    checked={
                      !stateSubscription.email &&
                      !stateSubscription.phone &&
                      !stateSubscription.postal &&
                      !stateSubscription.sms &&
                      !stateSubscription.appointment &&
                      !stateSubscription.birthday &&
                      !stateSubscription.reminders
                    }
                    onChange={handleChange}
                  />
                }
                className="checkbox--small"
                label="Unsubscribe from all"
              />
            </div>
            <h4 className="subscription__title-information">
              How do you like to receive information
            </h4>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="phone"
                    color="primary"
                    checked={stateSubscription.phone}
                    onChange={handleChange}
                  />
                }
                className="checkbox--small"
                label="Phone"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="sms"
                    color="primary"
                    checked={stateSubscription.sms}
                    onChange={handleChange}
                  />
                }
                className="checkbox--small"
                label="Text Message"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="email"
                    color="primary"
                    checked={stateSubscription.email}
                    onChange={handleChange}
                  />
                }
                className="checkbox--small"
                label="E-mail"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="postal"
                    color="primary"
                    checked={stateSubscription.postal}
                    onChange={handleChange}
                  />
                }
                className="checkbox--small"
                label="Postal"
              />
            </div>
            <h4 className="subscription__title-information">
              Send me information on the following
            </h4>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="appointment"
                    color="primary"
                    checked={stateSubscription.appointment}
                    onChange={handleChange}
                  />
                }
                className="checkbox--small"
                label="Appointment"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="birthday"
                    color="primary"
                    checked={stateSubscription.birthday}
                    onChange={handleChange}
                  />
                }
                className="checkbox--small"
                label="Pets Birthday"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="reminders"
                    color="primary"
                    checked={stateSubscription.reminders}
                    onChange={handleChange}
                  />
                }
                className="checkbox--small"
                label="Medical Reminders"
              />
            </div>

            <Button 
              variant="contained" 
              onClick={changeStateSubscription}
            >
              Save
            </Button>
          </div>
        </>
      ) : (
        <span style={{ fontFamily: "Nunito Sans" }}>
          {" "}
          Sorry, the user has no longer existed.
        </span>
      )}
    </>
  );
};

export default FormsSubscription;