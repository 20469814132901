import axios from 'axios';
import { axiosGet, axiosPatch, axiosPost } from './request';
import * as ResponseTypeguards from './ResponseTypeguards';
import {
  AuthCode2FAType,
  ForgotPasswordType,
  RegisterType,
  SaveSubscriptionType,
  SetPasswordType,
  SignUpType,
} from './RequestType';

const apiV = '/v1';

const createAxiosResponseError = () => new Error('Axios request returned void');
const createTypeguardError = () =>
  new Error('Typeguard could not validate response');
const createInvalidRouteParameterError = () =>
  new Error('Route could not be generate because the parameters were invalid');

// GET
export const getFeaturesOnPractice = async (micrositeName: string) => {
  const res = await axiosGet(`/roles/v1/micrositeName/${micrositeName}/screens`);

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  return res.data as unknown as string[];
};

export const getPetsForContact = async (userId: string) => {
  const res = await axiosGet(`/users${apiV}/${userId}/pets`);

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validatePets(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getDetailPet = async (petId: string) => {
  const res = await axiosGet(`/pets${apiV}/getClientPortalDetail/${petId}`);

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validatePet(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getAppointmentsDueForPet = async (
  userId?: string,
  petId?: string,
  option?: { limit?: number; offset?: number }
) => {
  const res = await axiosGet(
    `/tenant${apiV}/appointmentsdue?${userId ? `userId=${userId}` : ''}${
      petId ? `petId=${petId}` : ''
    }${option?.limit ? `&limit=${option?.limit}` : ''}&offset=${
      option?.offset ?? '0'
    }&sortOrder=asc`
  );

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateReminders(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getUpcomingVisitsForPet = async (
  startDate: string,
  endDate: string,
  options?: {
    petId?: string;
    userId?: string;
    limit?: number;
    offset?: number;
    kind?: string;
    withStatus?: string;
    isWithoutEndDate?: boolean;
    withName?: string;
  }
) => {
  const res = await axiosGet(
    `/tenant${apiV}/appointments?dateStart=${startDate}${
      options?.isWithoutEndDate ? '' : `&dateEnd=${endDate}`
    }${options?.userId ? `&userId=${options.userId}` : ''}${
      options?.petId ? `&petId=${options.petId}` : ''
    }${options?.limit ? `&limit=${options.limit}` : ''}&offset=${
      options?.offset ?? '0'
    }&count=10${options?.kind ? `&kind=${options?.kind}` : ''}${
      options?.withStatus ? `&withStatus=${options?.withStatus}` : ''
    }${options?.withName ? `&withName=${options?.withName}` : ''}`
  );

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateAppointments(res);

  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getPastVisitsForPet = async (
  userId: string,
  petId: string,
  options?: { limit?: number; offset?: number }
) => {
  const res = await axiosGet(
    `/tenant${apiV}/pastVisits?userId=${userId}&petId=${petId}${
      options?.limit ? `&limit=${options.limit}` : ''
    }&offset=${
      options?.offset ?? 0
    }&withStatus=unconfirmed,confirmed,complete,&sortOrder=asc`
  );

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateAppointments(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getVaccinationsForPet = async (
  petId: string,
  options?: { limit?: number; offset?: number }
) => {
  const res = await axiosGet(
    `/pets${apiV}/${petId}/vaccinations?${
      options?.limit ? `&limit=${options.limit}` : ''
    }&offset=${options?.offset ?? 0}`
  );

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateVaccinations(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getMedicalHistories = async (
  petId: string,
  options?: { limit?: number; offset?: number }
) => {
  const res = await axiosGet(
    `/pets${apiV}/${petId}/histories?${
      options?.limit ? `&limit=${options.limit}` : ''
    }&offset=${options?.offset ?? 0}`
  );

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateMedicalHistories(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getMedicalCompliances = async (
  petId: string,
  options?: { limit?: number; offset?: number }
) => {
  const res = await axiosGet(
    `/pets${apiV}/${petId}/compliance?${
      options?.limit ? `&limit=${options.limit}` : ''
    }&offset=${options?.offset ?? 0}`
  );

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateMedicalHistories(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getFormsForPet = async (micrositeName: string, petId: string) => {
  const res = await axiosGet(
    `/tenants${apiV}/formBuilder/${micrositeName}/shareFormsForPet?petId=${petId}`
  );

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateDocuments(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getCoOwnerForPet = async (userId: string, petId: string) => {
  const res = await axiosGet(
    `/user${apiV}/getFirstCoOwner?petId=${petId}&currentContactId=${userId}`
  );

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateContact(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getLatestSubmission = async (submissionId: string) => {
  const res = await axiosGet(
    `/tenants${apiV}/formBuilder/submission/${submissionId}`
  );

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateSubmission(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getTimeNow = async () => {
  const res = await axiosGet(`/tenants${apiV}/currentTime`);

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  return res.data;
};

export const getLogoPratice = async (micrositeName: string) => {
  const response = await axiosGet(`/tenants/v1/micrositeName/${micrositeName}`);

  return response;
};

export const getUserInfo = async () => {
  const res = await axiosGet(`/user${apiV}`);

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateContact(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getDailyAppoiments = async (
  startDate: string,
  endDate: string,
  options?: {
    userId?: string;
    limit?: number;
    offset?: number;
    kind?: string;
    withStatus?: string;
    isWithoutEndDate?: boolean;
    withName?: string;
  }
) => {
  const res = await axiosGet(
    `/tenant${apiV}/appointments?dateStart=${startDate}${
      options?.isWithoutEndDate ? '' : `&dateEnd=${endDate}`
    }${options?.userId ? `&userId=${options.userId}` : ''}${
      options?.limit ? `&limit=${options?.limit}` : ''
    }&offset=${options?.offset ?? '0'}&count=10${
      options?.kind ? `&kind=${options?.kind}` : ''
    }${options?.withStatus ? `&withStatus=${options?.withStatus}` : ''}${
      options?.withName ? `&withName=${options?.withName}` : ''
    }`
  );

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateDailyAppointments(res);

  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getSystemSettingUser = async () => {
  const response = await axiosGet(`/tenant${apiV}/settings/SystemSetting`);

  // @ts-ignore
  return response?.data;
};

export const getRecentProviders = async (limit?: number) => {
  const res = await axiosGet(
    `/appointmentSettingProvider${apiV}/ownUserRecentProvider?${
      limit ? `limit=${limit}` : ''
    }`
  );

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateRecentProviders(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getListFormPractice = async (practiceName: string) => {
  const res = await axiosGet(
    `/tenants${apiV}/formBuilder/${practiceName}/shareForms`
  );

  if (!res || !res.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateDocuments(res);
  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const getDetailPatientPortal = async (formId: string, autoFillId: string) => {
  const response = await axiosGet(
    `/tenants/v1/formBuilder/FormForSubmission?formId=${formId}&autoFillId=${autoFillId}`
  );

  return response;
};

export const getDetailPatientPortalWithSubmission = async (formId: string, submissionId: string) => {
  const response = await axiosGet(
    `/tenants/v1/formBuilder/FormWithSubmission?formId=${formId}&submissionId=${submissionId}`
  );

  return response;
};

export const getDetailAppointmentBooking = async (micrositeName: string) => {
  const response = await axiosGet(
    `/appointmentBookingRequests/v1/getLastestPublishedFormVersion?micrositeName=${micrositeName}`
  );

  return response;
};

// POST
export const submitPDF = async (
  pdfFile: any,
  urlApiForEmbed: string | undefined
) => {
  const response = !urlApiForEmbed
    ? await axiosPost('/tenants/v1/formBuilder/uploadFile', pdfFile, {
        axiosOptions: { headers: { 'Content-Type': 'multipart/form-data' } },
      })
    : await axios.post(
        `${urlApiForEmbed}/tenants/v1/formBuilder/uploadFile`,
        pdfFile,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

  return response;
};

export const fetchLogin = async (
  email: string,
  password: string,
  micrositeName: string
) => {
  const response = await axiosPost(
    '/oauth/beginAuth2FA',
    {
      grantType: 'password',
      username: email,
      password,
      micrositeName,
    },
    {
      axiosOptions: {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
      customOptions: {
        useAuth: false,
      },
    }
  );

  if (!response || !response.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateMethodLogin(response);

  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const sendCodeAuth = async (
  username: string,
  password: string,
  micrositeName: string,
  providerType: string
) => {
  const response = await axiosPost(
    `/oauth/send2FACode/${micrositeName}/${providerType}`,
    {
      username,
      password,
    },
    {
      axiosOptions: {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
      customOptions: {
        useAuth: false,
      },
    }
  );

  return response;
};

export const loginWithCode = async (payload: AuthCode2FAType) => {
  const response = await axiosPost('/oauth/endAuth2FA', payload, {
    axiosOptions: {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    },
    customOptions: {
      useAuth: false,
    },
  });

  if (!response || !response.data) {
    throw createAxiosResponseError();
  }

  const validResponse = ResponseTypeguards.validateLoginWithCode(response);

  if (!validResponse) {
    throw createTypeguardError();
  }

  return validResponse;
};

export const registerAccount = async (payload: RegisterType) => {
  const response = await axiosPost(
    `/users${apiV}/findContactForRegister`,
    payload
  );

  // @ts-ignore
  return response?.data;
};

export const signUpUser = async (payload: SignUpType) => {
  const response = await axiosPost(`/users${apiV}/contactPMSSignUp`, payload);

  // @ts-ignore
  return response?.data;
};

export const setPassword = async (userId: string, payload: SetPasswordType) => {
  const res = await axiosPost(`/users${apiV}/${userId}/password`, payload);

  // @ts-ignore
  return res?.data;
};

export const updatePassword = async (
  currentPassword: string,
  password: string
) => {
  const res = await axiosPatch(`/user${apiV}`, {
    currentPassword,
    password,
  });

  // @ts-ignore
  return res?.data;
};

export const saveContactSubscription = async (
  payload: SaveSubscriptionType
) => {
  const res = await axiosPost(`/contacts${apiV}/subscriptions`, payload, {
    customOptions: {
      useAuth: true,
    },
  });
  // @ts-ignore
  return res?.data;
};

export const resetPassword = async (payload: ForgotPasswordType) => {
  const res = await axiosPost(`/users${apiV}/resetContactPassword`, payload, {
    axiosOptions: {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    },
    customOptions: {
      useAuth: false,
    },
  });

  // @ts-ignore
  return res?.data;
};
