export const Labels = {
  AppointmentType: 'Appointment Type',
  Provider: 'Provider',
  AppointmentDate: 'Appointment Date',
  AppointmentTime: 'Appointment Time',
  Morning: 'Morning',
  Afternoon: 'Afternoon',
  Patient: 'Patient'
};

// export const DIRTY = 'DIRTY';
// export const PRISTINE = 'PRISTINE';
export const APPOINTMENT_BOOKING = 'appointment-booking';


