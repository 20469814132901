import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useHistory } from 'react-router-dom';
import { ErrorMessage, FastField, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Modal } from '@material-ui/core';

import Toast from 'components/Global/Toast';
import { ReactComponent as Loading } from 'assets/images/loading.svg';
import LinkButton from 'components/Global/Button/LinkButton';

import { ErrorMessages } from 'constants/validateErrors';
import { passwordPattern } from 'utils/regexs';
import { setPassword } from 'services/pratices';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getLogoPractice } from 'scenes/FormsPatientPortal/redux/actions';

interface SetPasswordType {
  password: string;
  rePassword: string;
}

const validateSchema = Yup.object().shape({
  password: Yup.string()
    .required(`Password ${ErrorMessages.required}`)
    .matches(passwordPattern, ErrorMessages.passwordFormat),
  rePassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], ErrorMessages.rePasswordFormat)
    .required(`Confirm password ${ErrorMessages.required}`),
});

const initialValue = {
  password: '',
  rePassword: '',
};

const SetPassword = () => {
  window.document.title = 'Patient Portal | Set Password';

  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const practiceName = useAppSelector(
    (state) => state.formsPatientPortal.practiceName
  );

  const [loadingCheckExpired, setLoadingCheckExpired] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [errorResponse, setErrorResponse] = useState<string>('');
  const [isExpired, setIsExpired] = useState<boolean>(false);

  useEffect(() => {
    const searchParam = location.search;
    const query = new URLSearchParams(searchParam.split('+').join('%2B'));
    const micrositeName = query.get('micrositeName');
    const expiredTime = query.get('expires');
    // dispatch(getLogoPractice(micrositeName ?? ""));
    if (Number(expiredTime) < Math.floor(Date.now() / 1000)) {
      setIsExpired(true);
    }
    setLoadingCheckExpired(false);
  }, []);

  const handleSetPassword = async (values: SetPasswordType) => {
    setLoading(true);
    const searchParam = location.search;
    const query = new URLSearchParams(searchParam.split('+').join('%2B'));
    const token = query.get('token');
    const userId = query.get('userId');
    if (!userId || !token) {
      setErrorResponse(
        'Set password failed. Please contact administration for support.'
      );
      setLoading(false);
      return;
    }
    try {
      const submitData = {
        token: token,
        password: values.password,
      };
      const res = await setPassword(userId, submitData);
      if (res) {
        setLoading(false);
        Toast.success('Your password is set successfully!');
        history.push(`/${practiceName}/login`);
      }
    } catch (err) {
      setLoading(false);
      // @ts-ignore
      if (err.response.status === 400) {
        // @ts-ignore
        const message = err.response.data.message;
        if (message === 'Invalid token.' || message.substr(0, 4) === 'User') {
          setErrorResponse(
            'Set password failed. Please contact administration for support.'
          );
        } else {
          setErrorResponse(message);
        }
      }
      Toast.error('Set password failed.');
    }
  };

  if (loadingCheckExpired) {
    return <Loading className="loading-spinner loading-center" />;
  }

  return (
    <>
      {isExpired ? (
        <div className="auth__form register__thank-you">
          <p className="title">This link has expired</p>
          <p className="description">
            This link has expired. Please go to Forgot Password on Log In screen
            to auto-generate a new link.
          </p>
          <LinkButton
            redirectTo={`/${practiceName}/login`}
            className="btn"
            txtButton="Back to login"
            withShadow={true}
          />
        </div>
      ) : (
        <Formik
          initialValues={initialValue}
          validationSchema={validateSchema}
          validateOnMount={true}
          validateOnChange={true}
          onSubmit={(values) => handleSetPassword(values)}
        >
          {({ isValid, handleSubmit, handleChange }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <p className="title">Set your password</p>
              <div className="auth__input-container">
                <label htmlFor={'password'}>
                  Password <b className="require">*</b>
                </label>
                <FastField
                  required
                  type="password"
                  autoComplete="off"
                  placeholder="Password"
                  maxLength={50}
                  name="password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setErrorResponse('');
                  }}
                />
                <p className="error-msg">
                  <ErrorMessage
                    name="password"
                    render={(msg) => (
                      <>
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                        <span>{msg}</span>
                      </>
                    )}
                  />
                </p>
              </div>

              <div className="auth__input-container">
                <label htmlFor="password">
                  Confirm password <b className="require">*</b>
                </label>
                <FastField
                  type="password"
                  required
                  maxLength={50}
                  autoComplete="off"
                  placeholder="Confirm password"
                  name="rePassword"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setErrorResponse('');
                  }}
                />
                <p className="error-msg">
                  <ErrorMessage
                    name="rePassword"
                    render={(msg) => (
                      <>
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                        <span>{msg}</span>
                      </>
                    )}
                  />
                  {errorResponse && (
                    <>
                      <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                      <span>{errorResponse}</span>
                    </>
                  )}
                </p>
              </div>

              <Button
                variant="contained"
                color="primary"
                className="btn btn-login"
                disabled={!isValid || !!errorResponse}
                type="submit"
              >
                Set password
              </Button>
            </form>
          )}
        </Formik>
      )}

      <Modal
        open={loading}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Loading className="loading-spinner loading-center" />
      </Modal>
    </>
  );
};

export default SetPassword;
