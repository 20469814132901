import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as Loading } from 'assets/images/loading.svg';
import PetProfile from 'components/Pet/PetProfile';
import Tab from 'components/Global/Tab';
import PetTabContent from 'components/Pet/PetTabContent';

import {
  getAppointmentsDueForPet,
  getDetailPet,
  getFormsForPet,
  getMedicalCompliances,
  getMedicalHistories,
  getPastVisitsForPet,
  getUpcomingVisitsForPet,
  getVaccinationsForPet,
} from 'services/pratices';
import { PetResponseType } from 'services/ResponseTypes/Pet';
import { useAppSelector } from 'hooks/redux';
import { DocumentType } from 'services/ResponseTypes/Document';
import { useRouter } from 'hooks/useRouter';
import moment from 'moment';
import { AppointmentResponseType } from 'services/ResponseTypes/Appointment';
import { VaccinationResponseType } from 'services/ResponseTypes/Vaccination';
import { ReminderResponseType } from 'services/ResponseTypes/Reminder';
import { Compliance, MedicalHistory } from 'services/ResponseTypes/Medical';

interface ParamType {
  petId: string;
}

interface Props {
  timeNow: string;
  getTimeNow: () => void;
}

export type LoadingType = typeof initialLoadingSection;
export type LoadMoreType = keyof typeof initialLoadMore;
export type LoadingMoreType = typeof initialLoadMore;
export type TabType =
  | 'Visits'
  | 'Documents'
  | 'Medical'
  | 'History'
  | 'Compliance'
  | '';

const initialLoadingSection = {
  appointmentsDue: true,
  upcomingVisits: true,
  pastVisits: true,
  petDocuments: false,
  vaccinationRecords: false,
  prescriptionMedications: false,
  history: false,
  compliance: false,
};

const initialLoadMore = {
  appointmentsDue: false,
  upcomingVisits: false,
  pastVisits: false,
  vaccinationRecords: false,
  history: false,
  compliance: false,
};

const defaultStartDate = moment(new Date().toISOString()).format('YYYY-MM-DD');

const PetProfileContainer = ({ timeNow, getTimeNow }: Props) => {
  const params: ParamType = useParams();
  const practice = useAppSelector(
    (state) => state.formsPatientPortal.practiceName
  );
  const routeFlag = useAppSelector((state) => state.features.routeFlag);

  const { push, pathname, location, history } = useRouter();
  const userId = useAppSelector((state) => state.auth.userInfo.userId);
  const petId = useMemo(() => params.petId ?? '', [params.petId]);

  const loadMore = useRef(initialLoadMore);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSection, setLoadingSection] = useState<LoadingType>(
    initialLoadingSection
  );
  const [loadingMore, setLoadingMore] =
    useState<LoadingMoreType>(initialLoadMore);
  const [activeTab, setActiveTab] = useState<TabType>(
    location.state === true ? 'Documents' : 'Visits'
  );
  const tabs: TabType[] = [
    'Visits',
    routeFlag['forms'] ? 'Documents' : '',
    'Medical',
  ];

  const [queryParams, setQueryParams] = useState({
    startDate: defaultStartDate,
    limit: 50,
    offset: {
      appointmentsDue: 0,
      upcomingVisits: 0,
      pastVisits: 0,
      vaccinationRecords: 0,
      history: 0,
      compliance: 0,
    },
    type: null as LoadMoreType | null,
  });

  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [pet, setPet] = useState<PetResponseType>({
    petId: '',
    name: '',
    guardians: [],
  });
  const [appointmentsDue, setAppointmentsDue] = useState<
    ReminderResponseType[]
  >([]);
  const [upcomingVisits, setUpcomingVisits] = useState<
    AppointmentResponseType[]
  >([]);
  const [pastVisits, setPastVisits] = useState<AppointmentResponseType[]>([]);
  const [vaccinations, setVaccinations] = useState<VaccinationResponseType[]>(
    []
  );
  const [medicalHistories, setMedicalHistories] = useState<MedicalHistory[]>(
    []
  );
  const [compliances, setCompliances] = useState<Compliance[]>([]);
  const petSpecies = useMemo(() => pet?.species ?? '', [pet]);

  useEffect(() => {
    getPet();
    getTimeNow();
    if (activeTab === 'Documents') {
      getForms();
      history.replace(pathname, '');
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'Visits' && queryParams.type === null && userId) {
      getAppointmentsDue();
      getUpcomingVisits(queryParams.startDate, {
        limit: 50,
        offset: queryParams.offset.upcomingVisits,
      });
      getPastVisits();
    } else {
      if (queryParams.type === 'appointmentsDue') {
        getAppointmentsDue(true);
      }
      if (queryParams.type === 'upcomingVisits') {
        getUpcomingVisits(
          queryParams.startDate,
          {
            limit: 50,
            offset: queryParams.offset.upcomingVisits,
          },
          true
        );
      }
      if (queryParams.type === 'pastVisits') {
        getPastVisits(true);
      }
    }
    if (activeTab === 'Medical') {
      getVaccinations(queryParams.type === 'vaccinationRecords' ? true : false);
    }
    if (activeTab === 'History') {
      getHistories(queryParams.type === 'history' ? true : false);
    }
    if (activeTab === 'Compliance') {
      getCompliances(queryParams.type === 'compliance' ? true : false);
    }
  }, [queryParams, userId]);

  const getPet = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getDetailPet(petId);

      if (res) {
        setPet(res);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  }, [petId]);

  const getForms = useCallback(async () => {
    try {
      setLoadingSection((prev) => ({ ...prev, petDocuments: true }));
      const response = await getFormsForPet(practice, petId);

      if (response) {
        setDocuments(response.result);
        setLoadingSection((prev) => ({ ...prev, petDocuments: false }));
      }
    } catch (err) {
      setLoadingSection((prev) => ({ ...prev, petDocuments: false }));
      console.log('err', err);
    }
  }, [practice, petId]);

  const onOffLoading = useCallback(
    (isLoadMore: boolean, type: LoadMoreType) => {
      isLoadMore
        ? setLoadingMore((prev) => ({ ...prev, [type]: false }))
        : setLoadingSection((prev) => ({ ...prev, [type]: false }));
    },
    []
  );

  const getAppointmentsDue = useCallback(
    async (isLoadMore?: boolean) => {
      try {
        const res = await getAppointmentsDueForPet('', petId, {
          limit: queryParams.limit,
          offset: queryParams.offset.appointmentsDue,
        });

        if (res.count < queryParams.limit) {
          loadMore.current.appointmentsDue = false;
        } else {
          loadMore.current.appointmentsDue = true;
        }
        if (!queryParams.offset.appointmentsDue) {
          setAppointmentsDue(res.reminders);
        } else if (res.count !== 0) {
          setAppointmentsDue((prev: ReminderResponseType[]) => [
            ...prev,
            ...res.reminders,
          ]);
        }
        onOffLoading(!!isLoadMore, 'appointmentsDue');
      } catch (err) {
        onOffLoading(!!isLoadMore, 'appointmentsDue');
        console.log('err', err);
      }
    },
    [petId, queryParams]
  );

  const getUpcomingVisits = useCallback(
    async (
      startDate: string,
      options?: {
        userID?: string;
        limit?: number;
        offset?: number;
        kind?: string;
        withStatus?: string;
        isWithoutEndDate?: boolean;
      },
      isLoadMore?: boolean
    ) => {
      try {
        const res = await getUpcomingVisitsForPet(startDate, '', {
          withStatus: 'unconfirmed,confirmed,inprogress,complete',
          isWithoutEndDate: true,
          userId,
          petId,
          ...options,
        });
        if (res.count < queryParams.limit) {
          loadMore.current.upcomingVisits = false;
        } else {
          loadMore.current.upcomingVisits = true;
        }
        if (!queryParams.offset.upcomingVisits) {
          setUpcomingVisits(res.appointments);
        } else if (res.count !== 0) {
          setUpcomingVisits((prev: AppointmentResponseType[]) => [
            ...prev,
            ...res.appointments,
          ]);
        }
        onOffLoading(!!isLoadMore, 'upcomingVisits');
      } catch (err) {
        onOffLoading(!!isLoadMore, 'upcomingVisits');
        console.log('err', err);
      }
    },
    [userId, queryParams, petId]
  );

  const getPastVisits = useCallback(
    async (isLoadMore?: boolean) => {
      try {
        const res = await getPastVisitsForPet(userId, petId, {
          limit: queryParams.limit,
          offset: queryParams.offset.pastVisits,
        });

        if (res.count < queryParams.limit) {
          loadMore.current.pastVisits = false;
        } else {
          loadMore.current.pastVisits = true;
        }
        if (!queryParams.offset.pastVisits) {
          setPastVisits(res.appointments);
        } else if (res.count !== 0) {
          setPastVisits((prev: AppointmentResponseType[]) => [
            ...prev,
            ...res.appointments,
          ]);
        }
        onOffLoading(!!isLoadMore, 'pastVisits');
      } catch (err) {
        onOffLoading(!!isLoadMore, 'pastVisits');
        console.log('err', err);
      }
    },
    [userId, petId, queryParams]
  );

  const getVaccinations = useCallback(
    async (isLoadMore?: boolean) => {
      try {
        setLoadingSection((prev) => ({ ...prev, vaccinationRecords: true }));

        const res = await getVaccinationsForPet(petId, {
          limit: queryParams.limit,
          offset: queryParams.offset.vaccinationRecords,
        });

        if (res) {
          if (res.count < queryParams.limit) {
            loadMore.current.vaccinationRecords = false;
          } else {
            loadMore.current.vaccinationRecords = true;
          }
          if (!queryParams.offset.vaccinationRecords) {
            setVaccinations(res.vaccinations);
          } else if (res.count !== 0) {
            setVaccinations((prev: VaccinationResponseType[]) => [
              ...prev,
              ...res.vaccinations,
            ]);
          }
          onOffLoading(!!isLoadMore, 'vaccinationRecords');
        }
      } catch (err) {
        onOffLoading(!!isLoadMore, 'vaccinationRecords');
        console.log('err', err);
      }
    },
    [petId, queryParams]
  );

  const getHistories = useCallback(
    async (isLoadMore?: boolean) => {
      try {
        const res = await getMedicalHistories(petId, {
          limit: queryParams.limit,
          offset: queryParams.offset.history,
        });

        if (res) {
          if (res.count < queryParams.limit) {
            loadMore.current.history = false;
          } else {
            loadMore.current.history = true;
          }
          if (!queryParams.offset.history) {
            setMedicalHistories(res.histories);
          } else if (res.count !== 0) {
            setMedicalHistories((prev: MedicalHistory[]) => [
              ...prev,
              ...res.histories,
            ]);
          }
          onOffLoading(!!isLoadMore, 'history');
        }
      } catch (err) {
        onOffLoading(!!isLoadMore, 'history');
        console.log('err', err);
      }
    },
    [petId, queryParams]
  );

  const getCompliances = useCallback(
    async (isLoadMore?: boolean) => {
      try {
        const res = await getMedicalCompliances(petId, {
          limit: queryParams.limit,
          offset: queryParams.offset.compliance,
        });

        if (res) {
          if (res.count < queryParams.limit) {
            loadMore.current.compliance = false;
          } else {
            loadMore.current.compliance = true;
          }
          if (!queryParams.offset.compliance) {
            setCompliances(res.histories);
          } else if (res.count !== 0) {
            setCompliances((prev: MedicalHistory[]) => [
              ...prev,
              ...res.histories,
            ]);
          }
          onOffLoading(!!isLoadMore, 'compliance');
        }
      } catch (err) {
        onOffLoading(!!isLoadMore, 'compliance');
        console.log('err', err);
      }
    },
    [petId, queryParams]
  );

  const handleLoadMore = (type: LoadMoreType) => {
    // console.log('more', loadMore);
    // console.log('not', loadingSection);
    // console.log('type', type);
    // return;

    if (loadMore.current[type]) {
      loadMore.current[type] = false;
      setLoadingMore((prev) => ({
        ...prev,
        [type]: true,
      }));
      setQueryParams((prev) => ({
        ...prev,
        offset: { ...prev.offset, [type]: prev.offset[type] + 1 },
        type,
      }));
    }
  };

  const clearData = useCallback(() => {
    setAppointmentsDue([]);
    setUpcomingVisits([]);
    setPastVisits([]);
  }, []);

  const handleChangeTab = (newTab: TabType) => {
    setLoadingSection(initialLoadingSection);
    setActiveTab(newTab);
    switch (newTab) {
      case 'Documents':
        setDocuments([]);
        getForms();
        break;
      case 'Medical':
        setVaccinations([]);
        setQueryParams((prev) => ({
          ...prev,
          offset: { ...prev.offset, vaccinationRecords: 0 },
          type: null,
        }));
        break;
      case 'History':
        setLoadingSection((prev) => ({ ...prev, history: true }));
        setMedicalHistories([]);
        setQueryParams((prev) => ({
          ...prev,
          offset: { ...prev.offset, history: 0 },
          type: null,
        }));
        break;
      case 'Compliance':
        setLoadingSection((prev) => ({ ...prev, compliance: true }));
        setCompliances([]);
        setQueryParams((prev) => ({
          ...prev,
          offset: { ...prev.offset, compliance: 0 },
          type: null,
        }));
        break;
      default:
        clearData();
        setQueryParams((prev) => ({
          ...prev,
          startDate: defaultStartDate,
          offset: {
            appointmentsDue: 0,
            upcomingVisits: 0,
            pastVisits: 0,
            vaccinationRecords: 0,
            history: 0,
            compliance: 0,
          },
          type: null,
        }));
    }
  };

  const handleRedirectToDocument = (form: DocumentType) => {
    window.sessionStorage.setItem('pet', JSON.stringify(pet));
    form.documentStatus === 'submitted' &&
      window.sessionStorage.setItem(
        'latestSubmissionId',
        form.latestSubmissionId ?? ''
      );
    push(`${pathname}/forms/${form.id}`);
  };

  return (
    <>
      {loading ? (
        <Loading className="loading-spinner" />
      ) : (
        <div className="pet-profile__container">
          <PetProfile pet={pet} timeNow={timeNow} practiceName={practice} />
          <div className="pet-profile__header">
            <Tab tabs={tabs} activeTab={activeTab} onChange={handleChangeTab} />
            <button id="print-history__button" onClick={() => window.print()}>Print</button>
          </div>
          <PetTabContent
            practiceName={practice}
            petId={petId}
            petSpecies={petSpecies}
            activeTab={activeTab}
            documents={documents}
            upcomingVisits={upcomingVisits}
            appointmentsDue={appointmentsDue}
            pastVisits={pastVisits}
            vaccinations={vaccinations}
            medicalHistories={medicalHistories}
            compliances={compliances}
            handleRedirectToForm={handleRedirectToDocument}
            loading={loadingSection}
            handleLoadMoreData={handleLoadMore}
            loadingMore={loadingMore}
            onChangeTab={handleChangeTab}
          />
        </div>
      )}
    </>
  );
};

export default PetProfileContainer;
