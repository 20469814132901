import React from 'react';
import classNames from 'classnames';
import { Time } from './properties-config';
import { FormFieldBase } from './index';
import { getFieldProperties, getFieldPropertyValues } from '../helpers';
import { FormFieldInputTime } from './form-field-inputs';
import ValidationMessage from './validation-message';

export const FormFieldTime = ({
  field,
  className,
  commonProps,
  isRenderedField,
  onChange,
  value,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  const localProps = new Map(Time);
  const getTimeElements = timeValue => {
    const elements = {};

    if (timeValue) {
      const timeParts = timeValue.split(' ');
      const hoursMinutes = timeParts[0].split(':');

      elements.hour = hoursMinutes[0];
      elements.minute = hoursMinutes[1];
      elements.ampm = timeParts[1];
    }

    return elements;
  };

  const handleBlur = () => {
    onBlur({ target: { id: field.uuid, value } });
  };

  const getValue = (timeRange, index) => {
    if (timeRange && Array.isArray(value)) return value[index];
    return value;
  };

  const handleTimeRangeChange = (e, index) => {
    const newValue = [];
    const otherIndex = 1 - index;

    newValue[otherIndex] = getValue(/* timeRange */ true, otherIndex);
    newValue[index] = e.target.value;
    e.target.value = newValue;

    onChange(e);
  };

  const propertyNames = ['timeRange', 'until', 'sublabel'];
  const defaultValues = propertyNames.map(propName => ({
    [`${propName}`]: localProps.get(propName).defaultValue,
  }));
  const properties = getFieldProperties(field, 'specific');
  const propValues = getFieldPropertyValues(properties.specific, propertyNames, defaultValues);
  const sublabels = getFieldPropertyValues(new Map(propValues.sublabel), [
    'startHour',
    'startMinutes',
    'endHour',
    'endMinutes',
  ]);
  const classes = classNames({
    [className]: true,
    time: true,
    'control-group error': validationMessage,
  });
  const id = field.uuid;
  const { timeRange } = propValues;

  const timeProps = {};
  if (isRenderedField) {
    timeProps.onBlur = handleBlur;
    timeProps.disabled = readOnly;
  }

  return (
    <div className={classes}>
      <div className='form-field-label primary'> {`
            ${commonProps.label}
          `}
          {commonProps.makeRequired ?  <b style={{color: "#102341"}}>*</b> : ''}</div>
      <div className='form-field-input time'>
        <FormFieldInputTime
          id={id}
          sublabels={{
            Hour: sublabels.startHour,
            Minutes: sublabels.startMinutes,
          }}
          isRenderedField={isRenderedField}
          onChange={timeRange ? e => handleTimeRangeChange(e, 0) : onChange}
          {...timeProps}
          {...getTimeElements(getValue(timeRange, 0))}
        />
        {propValues.timeRange && (
          <FormFieldInputTime
            id={id}
            prefix={propValues.until}
            sublabels={{
              Hour: sublabels.endHour,
              Minutes: sublabels.endMinutes,
            }}
            isRenderedField={isRenderedField}
            timeRange
            onChange={timeRange ? e => handleTimeRangeChange(e, 1) : onChange}
            {...timeProps}
            {...getTimeElements(getValue(timeRange, 1))}
          />
        )}
      </div>
      <ValidationMessage message={validationMessage} />
    </div>
  );
};

export default FormFieldBase(FormFieldTime);

FormFieldTime.defaultProps = {
  commonProps: {},
  isRenderedField: false,
  onChange: () => { },
  value: '',
  onBlur: () => { },
  readOnly: false,
};
