import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Wrapper } from './Wrapper';

interface ILoading {
  show: boolean,
}
const LoadingIndicator = (props: ILoading) => {
  const { show } = props;
  return <Wrapper style={{ display: show ? 'block' : 'none' }}>
    <div className="image-loading">
      <CircularProgress />
    </div>
  </Wrapper>
};

export default LoadingIndicator;
