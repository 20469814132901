import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      backgroundColor: (props: any) => {
        if (props.color === 'primary') {
          return '#2F68FD';
        }

        return 'white';
      },
      width: '25%',
      display: 'flex',
      borderRadius: '8px'
    },
    btnCancel: {
      '&:hover': {
        backgroundColor: '#d5d5d5'
      }
    },
    btnSubmit: {
      '&:hover': {
        backgroundColor: '#023EE3'
      }
    },
    title: {
      color: (props: any) => {
        if (props.color === 'primary') {
          return '#fff';
        }

        return '#6E84A3';
      },
      'font-family': 'Nunito Sans',
      'font-size': '15px',
      'font-weight': '500',
      'letter-spacing': '0',
      'line-height': '18px',
      'text-align': 'center',
      'text-transform': 'capitalize',
    }
  })
);