import { makeStyles, Table, TableCell, TableHead } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles({
  ml15: {
    marginLeft: 15
  },
  mb15: {
    marginBottom: 15
  },
  mb20: {
    marginBottom: 20
  },
  mb30: {
    marginBottom: 30
  },
  mt15: {
    marginTop: 15
  },
  tableHeader: {
    color: '#6E84A3',
    fontFamily: 'Nunito Sans',
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '18px'
  },
  userBalanceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 15
  },
  message: {
    color: '#12253F',
    fontFamily: 'Nunito Sans',
    fontSize: 17,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'center',
    width: 155
  },
  messageError: {
    color: '#12253F',
    fontFamily: 'Nunito Sans',
    fontSize: 17,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'center'
  },
  borderTop: {
    borderTop: '2px solid #e3ebf6'
  },
  borderRadius15: {
    borderRadius: '15px !important'
  },
  minHeight150: {
    minHeight: 150
  },
  cardTitle: {
    color: '#12253F',
    fontFamily: 'Nunito Sans',
    fontSize: 17,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '20px'
  },
  marginSide15: {
    margin: '0 15px'
  },
  text: {
    fontFamily: 'Nunito Sans'
  },
  tableCell: {
    borderBottom: 0,
    fontSize: 15
  },
  cardContent: {
    padding: '16px 0px !important'
  }
});

export const TableCellWrapper = styled(TableCell)`
  padding: 15px;
  width: 25%;
`;

export const TableWrapper = styled(Table)`
  border-top: 1px solid #e3ebf6;
  min-width: 160px;
  width: 100%;
`;

export const SpanStyleBold = styled.span`
  color: #12253f;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
`;

export const TableHeadWrapper = styled(TableHead)`
  .MuiTableCell-root {
    border-bottom: none;
  }
`;

export const TableCellDocument = styled(TableCell)`
  padding: 6px 0px;
  width: 25%;
`;
