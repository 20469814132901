import React from 'react';
import CustomButton from '../../../components/CustomButton/CustomButton.component';
import { ButtonContainer } from './styled-components';

export interface DialogCancelAndConfirmProps {
  cancelHandler(event: React.MouseEvent<HTMLButtonElement>): void;
  confirmHandler(event: React.MouseEvent<HTMLButtonElement>): void;
  confirmTitle?: string;
  disabledConfirm?: boolean;
}

const DialogCancelAndConfirm = (
  props: DialogCancelAndConfirmProps
): React.ReactElement => {
  const {
    cancelHandler,
    confirmHandler,
    confirmTitle = 'OK',
    disabledConfirm = false,
  } = props;

  return (
    <ButtonContainer>
      <CustomButton
        title={'Cancel'}
        variant="contained"
        onClick={cancelHandler}
        className="btn-cancel"
        style={{
          marginRight: '15px',
          height: '37px',
          width: '99px',
        }}
        isCancel={true}
      />
      <CustomButton
        title={confirmTitle}
        variant="contained"
        onClick={confirmHandler}
        disabled={disabledConfirm}
        autoFocus={true}
        color={'primary'}
        className="btn-primary"
        style={{
          marginRight: '15px',
          height: '37px',
          width: '99px',
        }}
      />
    </ButtonContainer>
  );
};

export default DialogCancelAndConfirm;
