import * as t from "io-ts";
import { PetEntryData } from "./Pet";
import { PhoneNumberEntryData } from "./PhoneNumber";

const RolePermissionType = t.partial({
  roleId: t.string,
  roleName: t.string,
});

const UserInfoRequired = t.type({
  id: t.string,
  enabled: t.boolean,
  roles: t.array(RolePermissionType),
});

const UserInfoNullable = t.partial({
  emailAddress: t.string,
  name: t.string,
  pets: t.array(PetEntryData),
  phoneNumbers: t.array(PhoneNumberEntryData),
  externalStatus: t.string,
  externalId: t.string,
  pulseUserStatus: t.string,
  isMessageRead: t.boolean,
  latestMessageDate: t.string,
});

export const UserInfoResponse = t.intersection([
  UserInfoRequired,
  UserInfoNullable,
]);

export type FullUserInfo = t.TypeOf<typeof UserInfoResponse>;

export const TenantAllUserData = t.type({
  count: t.number,
  totalAvailable: t.number,
  offset: t.number,
  users: t.array(UserInfoResponse),
});

export type TenantUserDataResponse = t.TypeOf<typeof TenantAllUserData>;

export const TenantFilteredContactData = t.type({
  count: t.number,
  totalAvailable: t.number,
  users: t.array(UserInfoResponse),
});

export type TenantFilteredDataResponse = t.TypeOf<
  typeof TenantFilteredContactData
>;

const RequiredTenantInfo = t.type({
  tenantId: t.string,
  createdBy: t.string,
  createdAt: t.string,
  tenantGroupId: t.string,
});

const NullableTenantInfo = t.partial({
  tenantName: t.string,
  micrositeName: t.string,
});

export const FullTenantInfoResponse = t.intersection([
  RequiredTenantInfo,
  NullableTenantInfo,
]);

export type TenantInfoResponse = t.TypeOf<typeof FullTenantInfoResponse>;
