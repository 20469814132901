import { MedicalServiceStatus } from '../../constants/enums';
import * as t from 'io-ts';

const RequiredMedical = t.type({
  medicalServiceId: t.string,
  medicalServiceName: t.string,
  isVaccine: t.boolean,
  isShowInClientPortal: t.boolean,
  isCompliance: t.boolean,
});

const NullableMedical = t.partial({
  tenantId: t.string,
  externalId: t.string,
  speciesNames: t.array(t.string),
  speciesIds: t.array(t.string),
  order: t.number,
});

const MedicalEntry = t.intersection([RequiredMedical, NullableMedical]);

const NullableMedicalHistory = t.partial({
  administeredDate: t.string,
});

const RequiredMedicalHistory = t.type({
  medicalService: MedicalEntry,
});

const MedicalHistoryEntry = t.intersection([
  NullableMedicalHistory,
  RequiredMedicalHistory,
]);

const NullableMedicalCompliance = t.partial({
  administeredDate: t.string,
  state: t.keyof(MedicalServiceStatus),
});

const MedicalComplianceEntry = t.intersection([
  NullableMedicalCompliance,
  RequiredMedicalHistory,
]);

export type MedicalHistory = t.TypeOf<typeof MedicalHistoryEntry>;

export type Compliance = t.TypeOf<typeof MedicalComplianceEntry>;

export const ComplianceCollectionEntry = t.type({
  count: t.number,
  totalAvailable: t.number,
  histories: t.array(MedicalComplianceEntry),
});

export const MedicalHistoryCollectionEntry = t.type({
  count: t.number,
  totalAvailable: t.number,
  histories: t.array(MedicalHistoryEntry),
});

export type ComplianceCollection = t.TypeOf<typeof ComplianceCollectionEntry>;

export type MedicalHistoryCollection = t.TypeOf<
  typeof MedicalHistoryCollectionEntry
>;
