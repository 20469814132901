import React from "react";
import classNames from "classnames";

const FormFieldInputCheckbox = ({
  className,
  label,
  isOther,
  displayAsColumns,
  numColumns,
  group,
  isRenderedField,
  onChange,
  value,
  ...rest
}) => {
  const handleChange = (e) => {
    let value = label;

    // set value to sibling <input type="text"/> DOM element
    // if isOther === true and the target element being changed
    // is <input type="checkbox"/>
    if (isOther && e.target.type === "checkbox")
      value = e.target.nextSibling.value;

    onChange(e, group, value, /* otherEl */ e.target.previousSibling);
  };

  const classes = classNames({
    "checkbox-container": true,
    [className]: true,
  });

  const style = {
    display: displayAsColumns ? "inline-block" : "block",
    width: displayAsColumns ? `${(1 / parseInt(numColumns)) * 100}%` : "auto",
  };

  const dynamicProps = {};

  if (isRenderedField) {
    dynamicProps.onChange = handleChange;
    dynamicProps.disabled = rest.disabled;
  } else {
    dynamicProps.readOnly = true;
    dynamicProps.disabled = true;
  }

  return (
    <div className={classes} style={style}>
      <label>
        <input type="checkbox" {...dynamicProps} {...rest} />
        {isOther ? (
          <input
            type="text"
            placeholder={label}
            value={value || ""}
            {...dynamicProps}
          />
        ) : (
          label
        )}
      </label>
    </div>
  );
};

FormFieldInputCheckbox.defaultProps = {
  className: "",
  isRenderedField: false,
  onChange: () => {},
  value: "",
};

export default FormFieldInputCheckbox;
