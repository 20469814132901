import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from 'components/Global/Card/Card';
import ItemUI from 'components/Global/Card/Item';

import { PetResponseType } from 'services/ResponseTypes/Pet';
import { useHistory } from 'react-router-dom';
import { UserResponseType } from 'services/ResponseTypes/User';
import { printAgeNumber } from 'utils/convertData';

interface Props {
  user: UserResponseType;
  pets: PetResponseType[];
  loading: boolean;
  practiceName: string;
  timeNow: string;
  getPets: () => void;
  getTimeNow: () => void;
}

const ListPet = ({
  user,
  pets,
  loading,
  practiceName,
  timeNow,
  getTimeNow,
  getPets,
}: Props) => {
  const history = useHistory();

  useEffect(() => {
    if (user.userId) {
      getPets();
      getTimeNow();
    }
  }, [user]);

  const renderMyPets = (
    <>
      {pets.length > 0 ? (
        <ul className="list-pets">
          {pets.map((pet) => (
            <ItemUI
              key={pet.petId}
              handleClickItem={() => {
                history.push(`/${practiceName}/pets/${pet.petId}`);
              }}
              item={{
                name: pet.name,
                detail: (
                  <ul className="detail__info-list">
                    <li>{pet.breed || 'N/A'}</li>
                    <li>{pet.sex || 'N/A'}</li>
                    <li>
                      {pet.birthDate
                        ? printAgeNumber(pet.birthDate, timeNow)
                        : 'N/A'}
                    </li>
                  </ul>
                ),
                leftIcon: (
                  <span className="avatar d-flex--center">
                    {pet.imageUrl && (
                      <img src={pet.imageUrl} alt="Pet Avatar" />
                    )}
                  </span>
                ),
                rightAction: (
                  <>
                    {/*pet.isPastDue ? (
                      <span className="status status--danger">Past due</span>
                    ) : (
                      <span className="status status--success">Up-to-date</span>
                    )*/}
                    {/* {pet.documentRequiredAttention &&
                      pet.documentRequiredAttention > 0 && (
                        <span className="status status--danger">
                          {`${pet.documentRequiredAttention} document${
                            pet.documentRequiredAttention > 1 ? "s" : ""
                          } require
                          attention`}
                        </span>
                      )} */}
                    <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                  </>
                ),
              }}
            />
          ))}
        </ul>
      ) : (
        <span className="no-result no-result--warning">No Registered Pets</span>
      )}
    </>
  );

  return (
    <>
      <Card title="My Pets" children={renderMyPets} loading={loading} />
    </>
  );
};

export default ListPet;
