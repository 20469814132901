import React from "react";
import classNames from "classnames";
import {
  getComponent,
  getFieldProperties,
  getFieldPropertyValues,
} from "../helpers";
import { FormFieldBase } from "./form-field-base";

const SectionColumn = ({
  field,
  className,
  commonProps,
  value,
  readOnly,
  ...rest
}) => {
  // eslint-disable-line no-unused-vars
  const getValue = (index) => value && value[index];
  const propertyNames = [
    "label",
    "makeRequired",
    "makeHidden",
    "hideLabel",
    "readOnly",
    "sublabel",
  ];
  const defaultValues = {
    label: field.display_name,
    makeRequired: false,
    makeHidden: false,
    hideLabel: false,
    readOnly: false,
    sublabel: "",
  };

  return (
    <div
      className={`col-container ${
        !field.fields || field.fields.length === 0 ? "hidden" : ""
      }`}
    >
      {field.fields.map((f, index) => {
        const properties = getFieldProperties(f, "common");
        const commonPropValues = getFieldPropertyValues(
          properties.common,
          propertyNames,
          defaultValues
        );
        const classes = classNames({
          "form-field-input-container": true,
          "hide-field": commonPropValues.makeHidden,
          "hide-label": commonPropValues.hideLabel,
          "read-only":
            readOnly ||
            commonPropValues.readOnly ||
            commonPropValues.makeHidden,
        });
        const props = {
          key: f.uuid,
          field: f,
          isNested: true,
          parent: field,
          className: classes,
          commonProps: commonPropValues,
          value: getValue(index),
          ...rest,
          readOnly:
            readOnly ||
            commonPropValues.readOnly ||
            commonPropValues.makeHidden,
        };

        const component = getComponent(f, props);

        return (
          <div key={f.uuid} className="col">
            {component}
          </div>
        );
      })}
    </div>
  );
};

export class FormFieldSection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { field } = this.props;
    let properties;
    let propMap;
    let cols = 1;
    let heading = "";
    let subheading = "";
    let alignment = "";

    try {
      properties = getFieldProperties(field, "specific");
      propMap = properties.specific;

      cols = propMap.get("cols").value;
      heading = propMap.get("heading").value;
      subheading = propMap.get("subheading").value;
      alignment = propMap.get("alignment").value;

      if (!cols || cols === "") cols = propMap.get("cols").defaultValue;
      if (!heading) heading = "";
      if (!subheading) subheading = "";
      if (!alignment || alignment === "")
        alignment = propMap.get("alignment").defaultValue;
    } catch (ex) {
      if (!cols || cols === "") cols = 1;
      if (!heading) heading = "";
      if (!subheading) subheading = "";
      if (!alignment || alignment === "") alignment = "left";
    }

    return (
      <div className={`form-field-section col-${cols}`}>
        <div className={`form-field-label heading align-${alignment}`}>
          {heading}
        </div>
        {subheading !== "" && (
          <div className={`form-field-label subheading align-${alignment}`}>
            {subheading}
          </div>
        )}
        <SectionColumn {...this.props} />
      </div>
    );
  }
}

export default FormFieldBase(FormFieldSection);
