const styles = {
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignContent: 'center',
    justifyContent: 'center',
    minHeight: '18em',
    margin: '0 25px',
  },
  icon: {
    fontSize: '2.5em',
    flex: '1 1 100%',
    color: 'green',
    textAlign: 'center',
    fontFamily: 'Nunito Sans'
  },
  h1: {
    flex: '1 1 100%',
    textAlign: 'center',
    fontSize: '2.5em',
    fontFamily: 'Nunito Sans'
  },
  message: {
    flex: '1 1 100%',
    margin: 0,
    fontSize: '15px',
    textAlign: 'center',
    lineHeight: 1.5,
    fontFamily: 'Nunito Sans',
    marginTop: "10px",
    color: '#12253f'
  },
  messageSmall: {
    fontSize: '1em',
    fontFamily: 'Nunito Sans'
  },
  refererBlock: {
    textAlign: 'center',
  },
  refererBtn: {
    marginTop: '15px',
  },
  textThankYou: {
    flex: '1 1 100%',
    textAlign: 'center',
    fontSize: '2.5em',
    fontFamily: 'Nunito Sans',
    fontSize: '17px' ,
    fontWeight: 'bold',
    color: '#12253f'
  }
};

export default styles;