import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Login from 'components/Auth/Login';
import ForgotPassword from 'components/Auth/ForgotPassword';
import AuthCode from 'components/Auth/AuthCode';
import SetPassword from 'components/Auth/SetPassword';
import PracticeLogo from 'components/Global/Logo';
import Register from 'components/Register/Register';
import ThankYou from 'components/Auth/ThankYou';

import { getSessionJWT } from 'services/SessionJWT';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getPracticeNameFromURL } from 'utils/convertData';
import { useRouter } from 'hooks/useRouter';
import { getLogoPractice } from 'scenes/FormsPatientPortal/redux/actions';

const AuthContainer = () => {
  const isAuthenticated = getSessionJWT();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const practice = useAppSelector((state) => state.formsPatientPortal);

  const [practiceName, setPracticeName] = useState('');
  const [methodLogin, setMethodLogin] = useState('');
  const practiceMicrositeName = getPracticeNameFromURL(router.pathname);

  useEffect(() => {
    if (practice.practiceName) {
      setPracticeName(practice.practiceName);
    } else {
      setPracticeName(practiceMicrositeName);
    }
    if (!practice.practiceName) {
      dispatch(getLogoPractice(practiceMicrositeName));
    }
  }, []);

  return !isAuthenticated ? (
    <div className="auth-container">
      <div className="auth-container__form">
        <PracticeLogo logo={practice.logoPractice} />
        
        <Switch>
          <Route
            path={`/:practiceMicrositeName/login`}
            render={(props) => (
              <Login
                methodLogin={methodLogin}
                setMethodLogin={setMethodLogin}
              />
            )}
          />
          <Route
            path={`/:practiceMicrositeName/register`}
            component={Register}
          />
          <Route
            path={`/:practiceMicrositeName/forgot-password`}
            component={ForgotPassword}
          />
          <Route
            path={`/:practiceMicrositeName/(register-success|register-unsuccess)`}
            component={ThankYou}
          />
          <Route
            path={`/:practiceMicrositeName/setPassword`}
            component={SetPassword}
          />
          <Route
            path={`/:practiceMicrositeName/auth-code`}
            render={(props) =>
              !methodLogin ? (
                <Redirect to={`/${practiceMicrositeName}/login`} />
              ) : (
                <AuthCode
                  methodLogin={methodLogin}
                  setMethodLogin={setMethodLogin}
                />
              )
            }
          />
        </Switch>
      </div>
    </div>
  ) : (
    <Redirect to={`/${practice.practiceName}/dashboard`} />
  );
};

export default AuthContainer;
