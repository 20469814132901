import {
  GET_LOGO_PRACTICE,
  GET_LOGO_PRACTICE_SUCCESS,
  GET_LOGO_PRACTICE_FAILED,
} from 'constants/actions';

export const initialState = {
  loading: false,
  logoPractice: '',
  practiceName: '',
  practiceEmailAddress: '',
  practicePhoneNumber: '',
  error: '',
};

interface IPatientPortalState {
  loading: boolean;
  logoPractice: string;
  error: string;
  practiceName: string;
}

export const FormsPatientPortalReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return {
        ...initialState,
      };
    }
    case GET_LOGO_PRACTICE: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_LOGO_PRACTICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        logoPractice: action?.logoPractice,
        practiceName: action?.practiceName,
        practiceEmailAddress: action?.practiceEmailAddress,
        practicePhoneNumber: action?.practicePhoneNumber,
      };
    }
    case GET_LOGO_PRACTICE_FAILED: {
      return {
        ...state,
        loading: false,
        logoPractice: '',
        practiceName: '',
        practiceEmailAddress: '',
        practicePhoneNumber: '',
      };
    }
    default:
      return state;
  }
};
