import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";

import RightDashboard from "components/Dashboard/RightDashboard";
import LeftDashboard from "components/Dashboard/LeftDashboard";

import {
  getAppointmentsDueForPet,
  getRecentProviders,
  getUpcomingVisitsForPet,
} from "services/pratices";
import { useAppSelector } from "hooks/redux";
import { AppointmentResponseType } from "services/ResponseTypes/Appointment";
import { ProviderResponseType } from "services/ResponseTypes/Provider";
import { ReminderResponseType } from "services/ResponseTypes/Reminder";

export type LoadingType = typeof initialLoadingSection;
export type LoadingMoreType = typeof initialLoadMore;
export type LoadMoreType = keyof typeof initialLoadMore;

const initialLoadingSection = {
  upcomingVisits: true,
  appointmentsDue: true,
  recentProviders: true,
};

const initialLoadMore = {
  upcomingVisits: false,
  appointmentsDue: false,
};

const defaultStartDate = moment(new Date().toISOString()).format("YYYY-MM-DD");

const DashBoardContainer = () => {
  const practice = useAppSelector(
    (state) => state.formsPatientPortal.practiceName
  );
  const userId = useAppSelector((state) => state.auth.userInfo.userId);

  const [upcomingVisits, setUpcomingVisits] = useState<
    AppointmentResponseType[]
  >([]);
  const [appointmentsDue, setAppointmentsDue] = useState<
    ReminderResponseType[]
  >([]);
  const [recentProviders, setRecentProviders] = useState<
    ProviderResponseType[]
  >([]);

  const [loadingSection, setLoadingSection] = useState<LoadingType>(
    initialLoadingSection
  );
  const loadMore = useRef(initialLoadMore);
  const [loadingMore, setLoadingMore] =
    useState<LoadingMoreType>(initialLoadMore);
  const [queryParams, setQueryParams] = useState({
    startDate: defaultStartDate,
    limit: 50,
    offset: {
      appointmentsDue: 0,
      upcomingVisits: 0,
    },
    type: null as LoadMoreType | null,
  });

  useEffect(() => {
    if (userId) {
      getUpcomingVisits();
      getAppointmentsDue();
      getProviders();
    }
  }, [userId]);

  useEffect(() => {
    if (queryParams.type === "upcomingVisits") {
      getUpcomingVisits(true);
    }
    if (queryParams.type === "appointmentsDue") {
      getAppointmentsDue(true);
    }
  }, [queryParams]);

  const onOffLoading = useCallback(
    (isLoadMore: boolean, type: LoadMoreType) => {
      isLoadMore
        ? setLoadingMore((prev) => ({ ...prev, [type]: false }))
        : setLoadingSection((prev) => ({ ...prev, [type]: false }));
    },
    []
  );

  const handleLoadMore = (type: LoadMoreType) => {
    if (loadMore.current[type]) {
      loadMore.current[type] = false;
      setLoadingMore((prev) => ({
        ...prev,
        [type]: true,
      }));
      setQueryParams((prev) => ({
        ...prev,
        offset: { ...prev.offset, [type]: prev.offset[type] + 1 },
        type,
      }));
    }
  };

  const getUpcomingVisits = useCallback(
    async (isLoadMore?: boolean) => {
      try {
        const res = await getUpcomingVisitsForPet(queryParams.startDate, "", {
          withStatus: "unconfirmed,confirmed,inprogress,complete",
          isWithoutEndDate: true,
          userId,
          limit: queryParams.limit,
          offset: queryParams.offset.upcomingVisits,
        });

        if (res.count < queryParams.limit) {
          loadMore.current.upcomingVisits = false;
        } else {
          loadMore.current.upcomingVisits = true;
        }

        if (!queryParams.offset.upcomingVisits) {
          setUpcomingVisits(res.appointments);
        } else if (res.count !== 0) {
          setUpcomingVisits((prev: AppointmentResponseType[]) => [
            ...prev,
            ...res.appointments,
          ]);
        }

        onOffLoading(!!isLoadMore, "upcomingVisits");
      } catch (err) {
        onOffLoading(!!isLoadMore, "upcomingVisits");
        console.log("err", err);
      }
    },
    [userId, queryParams]
  );

  const getAppointmentsDue = useCallback(
    async (isLoadMore?: boolean) => {
      try {
        const res = await getAppointmentsDueForPet(userId, "", {
          limit: queryParams.limit,
          offset: queryParams.offset.appointmentsDue,
        });

        if (res.count < queryParams.limit) {
          loadMore.current.appointmentsDue = false;
        } else {
          loadMore.current.appointmentsDue = true;
        }

        if (!queryParams.offset.appointmentsDue) {
          setAppointmentsDue(res.reminders);
        } else if (res.count !== 0) {
          setAppointmentsDue((prev: ReminderResponseType[]) => [
            ...prev,
            ...res.reminders,
          ]);
        }
        onOffLoading(!!isLoadMore, "appointmentsDue");
      } catch (err) {
        onOffLoading(!!isLoadMore, "appointmentsDue");
        console.log("err", err);
      }
    },
    [userId, queryParams]
  );

  const getProviders = useCallback(async () => {
    try {
      const res = await getRecentProviders();

      setRecentProviders(res);
      setLoadingSection((prev) => ({ ...prev, recentProviders: false }));
    } catch (err) {
      setLoadingSection((prev) => ({ ...prev, recentProviders: false }));
      console.log("err", err);
    }
  }, []);

  return (
    <div className="dashboard-container">
      <RightDashboard
        providers={recentProviders}
        practiceName={practice}
        loading={loadingSection.recentProviders}
      />
      <LeftDashboard
        practiceName={practice}
        upcomingVisits={upcomingVisits}
        appointmentsDue={appointmentsDue}
        loading={{
          upcomingVisits: loadingSection.upcomingVisits,
          appointmentsDue: loadingSection.appointmentsDue,
        }}
        loadingMore={{
          upcomingVisits: loadingMore.upcomingVisits,
          appointmentsDue: loadingMore.appointmentsDue,
        }}
        handleLoadMore={handleLoadMore}
      />
    </div>
  );
};

export default DashBoardContainer;
