import { UserResponseType } from "services/ResponseTypes/User";
import {
  LOGIN,
  LOGOUT,
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
} from "constants/actions";

export const getUserInfo = () => {
  return {
    type: GET_USER_INFO,
  };
};

export const getUserSuccess = (userInfo: UserResponseType) => {
  return {
    type: GET_USER_INFO_SUCCESS,
    payload: userInfo,
  };
};

export const login = (payload: { username: string; password: string }) => {
  return {
    type: LOGIN,
    payload,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
