import { v4 as uuidv4 } from 'uuid';

import React from 'react';

import * as FormProperties from './properties-config';
import * as FormTypes from './helpers/index';
import * as Validators from './helpers/validator';
import { getCookie } from './common.util';

export const getFieldPropertyValues = (
  propertyMap,
  propertyNames,
  defaultValues = {}
) => {
  let property;
  const values = {};
  if (typeof propertyNames === 'string') propertyNames = [propertyNames];
  propertyNames.forEach((propertyName) => {
    try {
      property = propertyMap.get(propertyName);
      values[propertyName] = property.value;
    } catch (ex) {
      if (!property || !values[propertyName])
        values[propertyName] = defaultValues[propertyName];
    }
  });
  return values;
};

const fieldTypes = {
  'Section Break': {
    properties: FormProperties.Section,
    component: FormTypes.FormFieldSection,
  },
  'Date Picker': {
    properties: FormProperties.Date,
    component: FormTypes.FormFieldDate,
    validators: Validators.DatePicker,
  },
  Time: { properties: FormProperties.Time, component: FormTypes.FormFieldTime },
  Number: {
    properties: FormProperties.Number,
    component: FormTypes.FormFieldNumber,
    validators: Validators.Number,
  },
  'Short Text Entry': {
    properties: FormProperties.ShortText,
    component: FormTypes.FormFieldShortText,
    validators: Validators.ShortTextEntry,
  },
  'Phone Number': { validators: Validators.PhoneNumber },
  'Long Text Entry': {
    properties: FormProperties.LongText,
    component: FormTypes.FormFieldLongText,
    validators: Validators.LongTextEntry,
  },
  Note: {
    properties: FormProperties.LongText,
    component: FormTypes.FormFieldLongText,
    validators: Validators.LongTextEntry,
  },
  Dropdown: {
    properties: FormProperties.Dropdown,
    component: FormTypes.FormFieldDropdown,
    validators: Validators.Dropdown,
  },
  'Pet Sex': {
    properties: FormProperties.Sex,
    component: FormTypes.FormFieldGender,
  },
  'Single Choice': {
    properties: FormProperties.Single,
    component: FormTypes.FormFieldSingle,
  },
  'Multiple Choice': {
    properties: FormProperties.Multiple,
    component: FormTypes.FormFieldMultiple,
  },
  Spinner: {
    properties: FormProperties.Spinner,
    component: FormTypes.FormFieldSpinner,
    validators: Validators.Spinner,
  },
  Signature: {
    properties: FormProperties.Signature,
    component: FormTypes.FormFieldSignature,
    preventOverride: true,
  },
  Submit: {
    properties: FormProperties.Submit,
    component: FormTypes.FormFieldSubmit,
  },
  SSN: {
    properties: FormProperties.SocialSecurityNumber,
    component: FormTypes.FormFieldSSN,
    validators: Validators.SSN,
    preventOverride: true,
  },
  'Page Break': { properties: null, component: FormTypes.FormFieldPage },
  'Print Page Break': {
    properties: null,
    component: () => <p style={{ pageBreakBefore: 'always' }} />,
  },
  'Contact Address': {
    properties: null,
    component: FormTypes.FormFieldAddress,
  },
  'Co-Owner Address': {
    properties: null,
    component: FormTypes.FormFieldAddress,
  },
  SubFields: { properties: null, component: FormTypes.FormFieldSubFields },
  default: { properties: null, component: FormTypes.FormFieldDefault },
  'Email Address': { validators: Validators.EmailAddress },
  Birthdate: { validators: Validators.Birthdate },
  'Description Area': {
    properties: FormProperties.DescriptionArea,
    component: FormTypes.FormFieldDescriptionArea,
  },
  'Appointment Booking': {
    properties: FormProperties.AppointmentBooking,
    component: FormTypes.FormFieldAppointmentBooking,
  },
  Patient: {
    properties: FormProperties.Dropdown,
    component: FormTypes.FormFieldPatientDropdown,
    validators: Validators.Dropdown,
  },
  'Pet Species & Breed': {
    properties: FormProperties.SpecieAndBreed,
    component: FormTypes.FormFieldSpecieAndBreed,
  },
  'Estimate': {
    properties: FormProperties.Estimate,
    component: FormTypes.FormFieldEstimate,
  },
  'Document Upload': {
    properties: FormProperties.Upload,
    component: FormTypes.FormFieldUpload,
  },
  Optional: {
    properties: FormProperties.Optional,
    component: FormTypes.FormFieldOptional,
  },
};

/**
 *  Shared methods for all form-field-types
 */
export const getSpecificProps = (field) => {
  let Properties;
  if (['Pet Sex', 'SSN', 'Pet Species & Breed'].includes(field.display_name))
    Properties = fieldTypes[field.display_name].properties;
  else if (field.display_name !== 'Birthdate' && field.is_basic)
    Properties = [];
  else if (field.type_name === 'Date Picker')
    Properties = fieldTypes[field.type_name].properties.filter(([key, prop]) =>
      field.is_basic ? !prop.dateTimeOnly : true
    );
  else if (fieldTypes.hasOwnProperty(field.type_name))
    Properties = fieldTypes[field.type_name].properties;
  else Properties = fieldTypes.default.properties;
  return Properties;
};

export const getComponent = (field, props) => {
  let Component;
  // Appointment Booking
  if (
    [
      'Co-Owner Address',
      'Contact Address',
      'Pet Sex',
      'SSN',
      'Appointment Booking',
      'Patient',
      'Pet Species & Breed',
    ].includes(field.display_name)
  ) {
    Component = fieldTypes[field.display_name].component;
  } else if (
    field.fields &&
    field.type_name !== 'Section Break' &&
    field.type_name !== 'Optional'
  ) {
    Component = fieldTypes.SubFields.component;
  } else if (fieldTypes.hasOwnProperty(field.type_name)) {
    Component = fieldTypes[field.type_name].component;
  } else Component = fieldTypes.default.component;

  return <Component {...props} validationMessage={field.validationMessage} />;
};

export const getMapFromArray = (arr) =>
  arr instanceof Map ? arr : new Map(arr);

/**
 * getFieldProperties returns a Map object for each @types value passed
 * in that is found in the @field properties object.
 *
 * @param {object} field            The field we're requesting the properties for
 * @param {(string|string[])} types A string or array of strings of the type(s) of properties we want returned
 *                                  E.g.: ['common', 'specific']
 * @return {object}                 {<key-for-each-@types>: [<array>]}
 */
export const getFieldProperties = (field, types) => {
  const properties = field.properties || {};
  const result = {};
  if (typeof types === 'string') types = [types];
  types.forEach((type) => {
    if (properties.hasOwnProperty(type))
      result[type] = getMapFromArray(properties[type]);
  });
  return result;
};

export const hasSubfields = (field) => {
  return !!(field.fields && field.fields.length > 0);
};

export const { Messages } = Validators;

export const getFieldValidators = (field) =>
  getFieldByType(field).validators || [];

const getFieldByType = (field) => {
  const fieldType = fieldTypes[field.display_name || field.type_name];
  return fieldType || {};
};

export const getFieldValue = (field) => {
  if (Array.isArray(field.value) && !isArrayWithObjects(field.value)) {
    return field.value.join('');
  }
  return field.value;
};

const isArrayWithObjects = (arr) =>
  arr.find((item) => typeof item === 'object');

// browser safe HTTP request (GET/POST)
export const httpRequest = function (url, callback, options = {}) {
  const xhr = new XMLHttpRequest();
  let body;

  if (!options.method) options.method = 'GET';

  xhr.open(options.method, url, true);
  xhr.onreadystatechange = handler;
  xhr.responseType = 'json';
  const token = getCookie('token');

  xhr.setRequestHeader('Authorization', `Bearer ${token}`);

  if (options.method === 'POST' && options.body) {
    xhr.setRequestHeader('Content-Type', 'application/json');
    body = JSON.stringify(options.body);
  }

  xhr.send(body);

  function handler() {
    if (this.readyState === this.DONE) {
      if (this.status === 200) callback(null, this.response);
      else
        callback(
          new Error(`${url} request failed with status: [${this.status}]`)
        );
    }
  }
};

export const convertStringTo = (str, replacement) => {
  let result = '';
  for (let i = 0; i < str.length; i++) {
    result += replacement;
  }
  return result;
};

export const replaceAt = (str, index, character) =>
  `${str.substr(0, index)}${character}${str.substr(index + character.length)}`;

export const setNewFieldDefaults = (newField) => {
  setCommonPropertyDefaults(newField);
  setSpecificPropertyDefaults(newField);
  setSubfieldPropertyDefaults(newField);
};

const setCommonPropertyDefaults = (field) => {
  let propMap;

  // default 'label' property to this field's display_name (only if not a "Section Break" or "Page Break" type field)
  if (['Section Break', 'Page Break'].includes(field.type_name)) return;

  propMap = getMapFromArray(field.properties.common);
  let { value, ...rest } = propMap.get('label');

  value = field.display_name;

  propMap.set('label', { value, ...rest });
  field.properties.common = [...propMap];
};

const setSpecificPropertyDefaults = (field) => {
  let propMap;

  // iterate field-specific properties and set values for any with default values defined
  if (!field.properties.specific) return;

  propMap = getMapFromArray(field.properties.specific);
  propMap.forEach((val, key) => {
    let { value, defaultValue, ...rest } = propMap.get(key);

    if (val.hasOwnProperty('defaultValue') && val.hasOwnProperty('value')) {
      value = defaultValue;
      propMap.set(key, { value, defaultValue, ...rest });
    } else if (val.hasOwnProperty('value') && Array.isArray(val.value)) {
      // some properties might have an array of values, which themselves
      // have "sub-properties" (of type 'object') which might have
      // default values that should be set
      const newValue = [...value.filter((item) => typeof item[1] === 'object')];

      newValue.forEach(([itemKey, itemValue], index) => {
        if (
          itemValue.hasOwnProperty('defaultValue') &&
          itemValue.hasOwnProperty('value')
        ) {
          const newItemValue = { ...itemValue, value: itemValue.defaultValue };

          newValue[index] = [itemKey, newItemValue];
          propMap.set(key, { value: newValue, defaultValue, ...rest });
        }
      });
    }
  });

  field.properties.specific = [...propMap];
};

const setSubfieldPropertyDefaults = (field) => {
  // set default 'sublabel' property values if this field has subfields
  if (!hasSubfields(field)) return;

  field.fields.forEach((subfield, index) => {
    const subfieldPropMap = getMapFromArray(subfield.properties);
    let { value, ...rest } = subfieldPropMap.get('sublabel');
    value = subfield.display_name;
    subfieldPropMap.set('sublabel', { value, ...rest });
    field.fields[index].properties = [...subfieldPropMap];
  });
};

export const mapFieldProps = (field) => {
  // if this is NOT a section or page break, add default (common) properties to this field
  if (!['Section Break', 'Page Break'].includes(field.type_name))
    field.properties = { common: [...FormProperties.Common] };

  // map subfield properties, if has subfields
  mapSubfieldProps(field);

  // map field specific properties base on field type
  const specificProps = getSpecificProps(field);
  if (specificProps)
    field.properties = {
      ...field.properties,
      specific: [...specificProps],
    };
};

const mapSubfieldProps = (field) => {
  if (!hasSubfields(field)) return;

  field.fields.forEach((subfield) => {
    // add uuid to subfield
    subfield.uuid = uuidv4();

    // need to map "dynamic" properties to each sub-field
    const dynamicProps = getMapFromArray(
      FormProperties.Common.filter((prop) => prop[1].isDynamic)
    );

    if (dynamicProps.has('isVisible')) {
      // set default visible property value for this sub-field
      let { value, ...rest } = dynamicProps.get('isVisible');

      value = subfield.is_visible;
      dynamicProps.set('isVisible', { value, ...rest });
    }

    // add properties to sub-field
    subfield.properties = [...dynamicProps];
  });
};

export const groupFields = (fields = []) => {
  // - reduce "grouped" fields down to their category name
  // - "grouped" fields should have children array of sub fields
  const groups = {};

  return fields
    .map((field) => {
      if (field.group_name === 'Ungrouped') return field;

      if (!groups[field.group_name]) {
        groups[field.group_name] = {
          id: field.id,
          form_field_group_id: field.form_field_group_id,
          display_name: field.group_name,
          sort_order: field.sort_order,
          fields: [field],
        };
        return groups[field.group_name];
      }

      groups[field.group_name].fields.push(field);
    })
    .filter((field) => field) // filter out undefined fields from .map()
    .sort((a, b) => a.sort_order - b.sort_order);
};

export const createSubmitButton = () => {
  const submit = {
    uuid: uuidv4(),
    type_name: 'Submit',
    display_name: 'Submit',
  };
  mapFieldProps(submit);
  setNewFieldDefaults(submit);
  return submit;
};

export const fieldIsType = (field, type, comparisonAttribute = 'type_name') => {
  return field[comparisonAttribute] === type;
};

export const preventOverride = (field) =>
  !!getFieldByType(field).preventOverride;

export const setFieldPropertyValue = (
  field,
  propertyName,
  value,
  type = 'common'
) => {
  if (!field.properties || !field.properties[type]) return;

  const properties = getMapFromArray(getFieldProperties(field, type)[type]);
  const { value: currentValue, ...rest } = properties.get(propertyName);

  properties.set(propertyName, { value, ...rest });
  field.properties[type] = [...properties];
};
