import * as t from "io-ts";

const ProviderMethod = t.type({
  providerType: t.string,
  providerDescription: t.string,
});

export const MethodLoginEntry = t.type({
  code: t.number,
  message: t.string,
  providers: t.array(ProviderMethod),
});

export type MethodLoginResponseType = t.TypeOf<typeof MethodLoginEntry>;
