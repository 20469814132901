import * as t from "io-ts";

import { PhoneNumberEntryData } from "./PhoneNumber";
import { AddressEntryData } from "./Address";
import { TaskEntryData } from "./Task";
import { UserKindEnum } from "../../constants/enums";

const LocationCoordinates = t.type({
  lat: t.number,
  lon: t.number,
  tag: t.string,
});

const Role = t.partial({
  roleId: t.string,
  roleName: t.string,
});

const AdditionalDetails = t.partial({
  likes: t.string,
  "random-detail": t.string,
  "random-object": LocationCoordinates,
});

const UserSettingDetail = t.partial({
  email: t.boolean,
  phone: t.boolean,
  postal: t.boolean,
  sms: t.boolean,
  appointment: t.boolean,
  birthday: t.boolean,
  reminders: t.boolean,
});

const UserSettingObject = t.type({
  namespace: t.string,
  settings: UserSettingDetail,
});

const UserDetails = t.partial({
  birthDate: t.string,
  preferredLanguage: t.string,
});

const UserResponseRequired = t.type({
  userId: t.string,
  enabled: t.boolean,
  tenant: t.string,
  userKind: t.keyof(UserKindEnum),
});

const UserResponseNullable = t.partial({
  externalId: t.string,
  externalStatus: t.string,
  pulseUserStatus: t.string,
  name: t.type({
    givenName: t.string,
    lastName: t.string,
  }),
  roles: t.array(Role),
  emailAddress: t.string,
  maskedEmailAddress: t.string,
  details: UserDetails,
  addresses: t.array(AddressEntryData),
  phoneNumbers: t.array(PhoneNumberEntryData),
  policies: t.array(t.string),
  createdBy: t.string,
  createdAt: t.string,
  additionalDetails: AdditionalDetails,
  tasks: t.array(TaskEntryData),
  birthDate: t.string,
  userSettings: t.array(UserSettingObject),
});

export const UserEntryData = t.intersection([
  UserResponseRequired,
  UserResponseNullable,
]);

export type UserResponseType = t.TypeOf<typeof UserEntryData>;
