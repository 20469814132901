import React, { useMemo } from 'react';
import { Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from 'react-router-dom';

import { PetResponseType } from 'services/ResponseTypes/Pet';

import { printAgeNumber } from 'utils/convertData';
import LinkButton from 'components/Global/Button/LinkButton';
import { useAppSelector } from 'hooks/redux';

type IProps = {
  pet: PetResponseType;
  timeNow: string;
  practiceName: string;
};

const PetProfile = ({ pet, timeNow, practiceName }: IProps) => {
  const routeFlag = useAppSelector((state) => state.features.routeFlag);

  const breadcrumbs = useMemo(
    () => [
      <Link to={`/${practiceName}/pets`} key={'my pets'}>
        My Pets
      </Link>,
      <span key={'petId'} className="text--bolder">
        {pet?.name}
      </span>,
    ],
    [pet?.name, practiceName]
  );

  return (
    <>
      <div className="pet-profile__background">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </div>
      <div className="pet-profile__info">
        <div className="pet-profile__image">
          {pet.imageUrl && <img src={pet.imageUrl} alt="Pet" />}
        </div>
        <div className="pet-profile__content">
          <div>
            <span className="pet-profile__name">{pet.name}</span>
            <span className="pet-profile__detail">
              <ul className="detail__info-list">
                <li>{pet?.breed || 'N/A'}</li>
                <li>{pet?.sex || 'N/A'}</li>
                <li>
                  {pet.birthDate
                    ? printAgeNumber(pet.birthDate, timeNow)
                    : 'N/A'}
                </li>
              </ul>
            </span>
          </div>
          {routeFlag['online-booking'] && (
            <span>
              <LinkButton
                redirectTo={{
                  pathname: `/${practiceName}/online-booking`,
                  state: pet.petId,
                }}
                txtButton="Request an appointment"
              />
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default PetProfile;
