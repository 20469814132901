import classNames from 'classnames';
import React from 'react';
import ValidationMessage from '../../../../../validation-message';
import { Labels } from '../../../../constants';
import TimeSlot from './components/time-slot';
import styles from './styles';
import moment from 'moment';

const TimeSelector = ({
  id,
  timeSlots,
  selectedTime,
  onTimeClick,
  validationMessage,
  readOnly,
  duration,
  loading,
}) => {
  const validationClass = classNames({
    'control-group error': validationMessage,
  });

  const readOnlyStyle = readOnly && styles.readOnly;

  return (
    <div id={id} className='form-field-input-container' style={styles.inputContainer}>
      <div className='form-field-label primary'>{`${Labels.AppointmentTime}`} <b style={{color: "#102341"}}>*</b></div>
      <div
        style={{ ...styles.timeSlotSelectorContainer, ...readOnlyStyle }}
        className={validationClass}
      >
        <div style={styles.timeSlotAmPmContainer}>
          {loading ? 
            <img src="https://portal.thevethero.com/static/media/loading-spinner.14e717da.svg" alt="Loading" className="loading-spinner" />
          : <div style={styles.timeSlotContainer}>
              {timeSlots[0].map(slot => (
                <TimeSlot
                  key={slot.time}
                  time={slot.time}
                  selected={slot.time === selectedTime}
                  onClick={onTimeClick}
                  disabled={!slot.available}
                />
              ))}

              {timeSlots[1].map(slot => (
                <TimeSlot
                  key={slot.time}
                  time={slot.time}
                  selected={slot.time === selectedTime}
                  onClick={onTimeClick}
                  disabled={!slot.available}
                />
              ))}
            </div>}
        </div>
      </div>
      <ValidationMessage message={validationMessage} />
    </div>
  );
};

TimeSelector.defaultProps = {
  timeSlots: [[], []],
  selectedTime: null,
  readOnly: false,
};

export default TimeSelector;
