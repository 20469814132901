import React, { useEffect, useState } from 'react';
import ListPet from 'components/Pet/ListPet';
import FormsPatientPortalVM from 'scenes/FormsPatientPortal/FormsPatientPortalVM';
import PetProfileContainer from './PetProfileContainer';

import { useAppSelector } from 'hooks/redux';
import { Route, Switch } from 'react-router-dom';
import { getPetsForContact, getTimeNow } from 'services/pratices';
import { PetResponseType } from 'services/ResponseTypes/Pet';
import { getSessionJWT } from 'services/SessionJWT';

const PetsContainer = () => {
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const practiceName = useAppSelector(
    (state) => state.formsPatientPortal.practiceName
  );

  const [pets, setPets] = useState<PetResponseType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [timeNow, setTimeNow] = useState<string>('');

  // useEffect(() => {
  //   const body = document.querySelector("body");
  //   if (body) {
  //     body.style.background = "#fff";
  //   }

  //   return () => {
  //     if (body) {
  //       body.style.background = "#f6f8fc";
  //     }
  //   };
  // }, []);

  const getListPet = async () => {
    setIsLoading(true);
    try {
      const res = await getPetsForContact(userInfo.userId);

      if (res) {
        setPets(res.pets.filter((pet) => pet.status === 'active'));
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log('err', err);
    }
  };

  const getDateTimeNow = async () => {
    try {
      const res = await getTimeNow();

      if (res) {
        // @ts-ignore
        setTimeNow(res.toString());
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <div className="pets-container">
      <Switch>
        <Route
          exact
          path={`/:practiceMicrositeName/pets`}
          render={(props) => (
            <ListPet
              pets={pets}
              loading={isLoading}
              user={userInfo}
              getPets={getListPet}
              timeNow={timeNow}
              getTimeNow={getDateTimeNow}
              practiceName={practiceName}
            />
          )}
        />
        <Route
          exact
          path={`/:practiceMicrositeName/pets/:petId`}
          render={(props) => (
            <PetProfileContainer
              timeNow={timeNow}
              getTimeNow={getDateTimeNow}
            />
          )}
        />
        <Route
          exact
          path={`/:practiceMicrositeName/pets/:petId/forms/:formId`}
          component={FormsPatientPortalVM}
        />
        <Route
          exact
          path={`/:practiceMicrositeName/pets/:petId/forms/:formId/:autoFillId`}
          component={FormsPatientPortalVM}
        />
      </Switch>
    </div>
  );
};

export default PetsContainer;
