import React, { FC } from 'react';
import { Button, Modal } from '@material-ui/core';
import styled from 'styled-components';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WrappedModalContent = styled.div`
  position: absolute;
  top: ${(props) => get(props, 'top', '50%')};
  left: ${(props) => get(props, 'left', '50%')};

  transform: translate(
    ${(props) => get(props, 'top', '-50%')},
    ${(props) => get(props, 'left', '-50%')}
  );

  box-sizing: border-box;
  /* min-width: 350px; */
  width: 400px;
  padding: 0;

  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 11px 15px 8px rgba(0, 0, 0, 0.12);

  .modal-header,
  .modal-body,
  .modal-footer {
    width: 100%;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    margin: 0;

    p {
      margin: 0;

      color: #2c3742;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
    }

    svg {
      height: 27px;
      width: 12px;
      flex-shrink: 0;
      color: #6e84a3;

      &:hover {
        color: #2c3742;
      }
    }
  }

  .modal-body {
    padding: 20px;
    border-top: 1px solid #dee0ed;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;

    .modal-body__label {
      display: block;
      margin-bottom: 10px;

      color: #6e84a3;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;

      &:first-child {
        margin-top: 0;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    .modal-body__text {
      color: #000;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
    }

    .search-filter__global {
      .filter-form {
        width: 100%;
        background-color: #fff;
        border: 1px solid #dee0ed;
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid #dee0ed;

    .btn {
      min-width: 65px;
      height: 36px;
      padding: 0 10px;
      border: none;
      border-radius: 4px;

      font-size: 13px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;

      outline: none;
      cursor: pointer;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
          0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
      }

      &:disabled {
        cursor: default;
        box-shadow: none;
      }

      &-left {
        color: #12253f;
        background-color: #eceef5;

        &:hover {
          /* background-color: #a7a9b2; */
        }
      }

      &-right {
        margin-left: auto;
        color: #fff;
        background-color: #0074ff;

        &:hover {
          /* background-color: #303f9f; */
        }
      }
    }
  }
`;

interface IProps {
    isOpenModal: boolean;
    handleCloseModal: () => void;
    handleConfirmModal?: () => void;
}

const ModalDiscard: FC<IProps> = ({
    isOpenModal,
    handleCloseModal,
    handleConfirmModal,
}) => {
    return (
        <Modal
            open={isOpenModal}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
        >
            <WrappedModalContent className={`modal-content`}>
                <div className='modal-header'>
                    <p>Receive Submission Copy?</p>
                    <FontAwesomeIcon
                        className='close-icon'
                        icon={['far', 'times']}
                        onClick={handleCloseModal}
                    />
                </div>
                <div className='modal-body'>
                    <p>
                        Would you like to receive a copy of your form by email?
                    </p>
                </div>
                <div className='modal-footer'>
                    <Button variant='contained' onClick={handleCloseModal}>
                        No
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => handleConfirmModal && handleConfirmModal()}
                    >
                        Yes
                    </Button>
                </div>
            </WrappedModalContent>
        </Modal>
    );
};

export default ModalDiscard;
