import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #100f0f;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0.8;
  z-index: 999999;
  .image-loading {
    position: relative;
    top: calc(50% - 40px);
    left: calc(50% - 20px);
  }
`;