import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.scss";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface OptionDropdownType {
  label: string;
  value: string;
}

interface FilterProps {
  handleSetNewFilterValue: (newSelectedItem: OptionDropdownType) => void;
  optionsForFilter: OptionDropdownType[];
  disabled?: boolean;
  classCustom?: string;
  valueSelected?: OptionDropdownType;
  setOpenDropdown?: (arg: boolean) => void;
  placeholder?: string;
}

const Filter = (props: FilterProps): JSX.Element => {
  const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);
  const [valueFilterSelected, setValueFilter] =
    React.useState<OptionDropdownType>(props.optionsForFilter[0]);

  React.useEffect(() => {
    if (props.valueSelected) {
      setValueFilter(props.valueSelected);
    }
  }, [props.valueSelected]);

  React.useEffect(() => {
    if (!!props.setOpenDropdown) {
      props.setOpenDropdown(isDropdownOpened);
    }
  }, [isDropdownOpened]);

  const handleSetNewFilterValue = (newSelectedItem: OptionDropdownType) => {
    setValueFilter(newSelectedItem);
    props.handleSetNewFilterValue(newSelectedItem);
    setIsDropdownOpened(false);
  };

  const handleClick = () => {
    setIsDropdownOpened(!isDropdownOpened);
  };

  const handleBlur = () => {
    setIsDropdownOpened(false);
  };

  return (
    <div className="search-filter__global">
      <div
        className={"filter-form " + (props.classCustom ?? "")}
        tabIndex={0}
        onClick={handleClick}
        onBlur={handleBlur}
        onKeyDown={(event: React.KeyboardEvent) => {
          switch (event.key) {
            case " ":
            case "Enter": {
              handleClick();
              break;
            }
          }
        }}
      >
        {props.disabled ? (
          "Disable"
        ) : (
          <span
            className={`${
              props.placeholder && !valueFilterSelected?.value && "placeholder"
            }`}
          >
            {" "}
            {props.placeholder && !valueFilterSelected?.value
              ? props.placeholder
              : valueFilterSelected?.label}{" "}
          </span>
        )}
        <nav className={"filter-nav" + (isDropdownOpened ? " open" : "")}>
          <ul
            className={
              "select-filter__list" + (isDropdownOpened ? " open" : "")
            }
          >
            {props.optionsForFilter.map((item, index) => {
              return (
                <li
                  key={"item-filter-" + item.label + "-" + index}
                  className="select-filter__item"
                  onClick={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleSetNewFilterValue(item);
                  }}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>
        </nav>
        <FontAwesomeIcon
          className="icon-filter-down"
          icon={faChevronDown}
          style={{ padding: 2 }}
        />
      </div>
    </div>
  );
};

export default Filter;
