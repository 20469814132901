import { VaccinationStatusEnum } from "./../../constants/enums";
import * as t from "io-ts";

const VaccinationRequired = t.type({
  vaccinationId: t.string,
  title: t.string,
  scheduleDate: t.string,
  state: t.keyof(VaccinationStatusEnum),
});

const VaccinationNullable = t.partial({
  injectedDate: t.string,
  externalId: t.string,
  isCompleted: t.boolean,
  inThePast: t.boolean,
});

const VaccinationEntry = t.intersection([
  VaccinationRequired,
  VaccinationNullable,
]);

export type VaccinationResponseType = t.TypeOf<typeof VaccinationEntry>;

export const VaccinationCollectionResponse = t.type({
  count: t.number,
  totalAvailable: t.number,
  vaccinations: t.array(VaccinationEntry),
});

export type VaccinationCollectionResponseType = t.TypeOf<
  typeof VaccinationCollectionResponse
>;
