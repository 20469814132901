export enum UserKindEnum {
  'User',
  'Customer',
  'Admin',
  'Undefined',
  'Visitor',
}

export enum VaccinationStatusEnum {
  'UpToDate',
  'PastDue',
  'ActionNeeded',
}

export enum MedicalServiceStatus {
  'UpToDate',
  'OverDue',
  'NoHistory',
  'Unknown',
}
