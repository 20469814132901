import { PetEntryData } from "./Pet";
import { VaccinationStatusEnum } from "./../../constants/enums";
import * as t from "io-ts";

const ReminderRequired = t.type({
  reminderId: t.string,
  title: t.string,
  scheduleDate: t.string,
  state: t.keyof(VaccinationStatusEnum),
});

const ReminderNullable = t.partial({
  injectedDate: t.string,
  externalId: t.string,
  isCompleted: t.boolean,
  inThePast: t.boolean,
  pet: PetEntryData,
});

const ReminderEntry = t.intersection([ReminderRequired, ReminderNullable]);

export type ReminderResponseType = t.TypeOf<typeof ReminderEntry>;

export const ReminderCollectionResponse = t.type({
  count: t.number,
  totalAvailable: t.number,
  reminders: t.array(ReminderEntry),
});

export type ReminderCollectionResponseType = t.TypeOf<
  typeof ReminderCollectionResponse
>;
