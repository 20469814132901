import React, { useCallback, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { Button, Modal } from '@material-ui/core';

import { Wrapper } from './Wrapper';
import Account from './Account/Account';
import { ReactComponent as Loading } from 'assets/images/loading.svg';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { logout } from 'scenes/Auth/redux/actions';
import { getSessionJWT } from 'services/SessionJWT';
import { ErrorMessages } from 'constants/validateErrors';
import { passwordPattern } from 'utils/regexs';
import { updatePassword } from 'services/pratices';
import { ErrorMessage, FastField, Formik } from 'formik';
import Toast from 'components/Global/Toast';

interface SetPasswordType {
  currentPassword: string;
  password: string;
  rePassword: string;
}

interface IProps {
  titleBar: string;
}

const validateSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required(`Current password ${ErrorMessages.required}`)
    .matches(passwordPattern, ErrorMessages.passwordFormat),
  password: Yup.string()
    .required(`New password ${ErrorMessages.required}`)
    .matches(passwordPattern, ErrorMessages.passwordFormat),
  rePassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], ErrorMessages.rePasswordFormat)
    .required(`Confirm password ${ErrorMessages.required}`),
});

const initialValue = {
  currentPassword: '',
  password: '',
  rePassword: '',
};

const navItems = [
  {
    route: 'dashboard',
    title: 'Dashboard',
    isAuthenticated: true,
    isExact: true,
  },
  {
    route: 'online-booking',
    title: 'Online Booking',
    isAuthenticated: false,
    isExact: true,
  },
  {
    route: 'forms',
    title: 'Digital Forms',
    isAuthenticated: false,
    isExact: true,
  },
  {
    route: 'subscription',
    title: 'Subscription',
    isAuthenticated: true,
    isExact: true,
  },
  {
    route: 'pets',
    title: 'My Pets',
    isAuthenticated: true,
    isExact: false,
  },
];

const Header = ({ titleBar }: IProps) => {
  window.document.title = `Patient Portal | ${titleBar}`;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const formsPatientPortal = useAppSelector(
    (state) => state.formsPatientPortal
  );
  const routeFlag = useAppSelector((state) => state.features.routeFlag);
  const userInfo = useAppSelector((state) => state.auth.userInfo);

  const paths = Object.keys(routeFlag);
  const isAuthenticated = !!getSessionJWT();

  const [openNavbarMobile, setOpenNavbarMobile] = useState<boolean>(false);
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);
  const [errorResponse, setErrorResponse] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const isNoPermissionAccess = useCallback(
    (path) => paths.includes(path) && !routeFlag[path],
    [paths, routeFlag]
  );

  const handleCloseNavbar = () => {
    setOpenNavbarMobile(false);
  };

  const handleOpenModalReset = () => {
    setOpenNavbarMobile(false);
    setOpenModalChangePassword(true);
  };

  const getInitialsName = useCallback(() => {
    const givenName = userInfo?.name?.givenName ?? '';
    const lastName = userInfo?.name?.lastName ?? '';
    if (!lastName.length) {
      if (!givenName.length) {
        return '';
      }
      return givenName[0].toUpperCase();
    }
    return givenName[0].toUpperCase() + lastName[0].toUpperCase();
  }, [userInfo.name]);

  const getFullName = useCallback(() => {
    if (userInfo.name) {
      const username = userInfo.name?.givenName + ' ' + userInfo.name?.lastName;
      return username.trim();
    }
    return '';
  }, [userInfo.name]);

  const handleLogout = () => {
    history.push(`/${formsPatientPortal.practiceName}/login`);
    window.sessionStorage.clear();
    dispatch(logout());
  };

  const handleSetPassword = async (values: SetPasswordType) => {
    setLoading(true);
    try {
      const res = await updatePassword(values.currentPassword, values.password);

      // if (res) {
      setLoading(false);
      setOpenModalChangePassword(false);
      Toast.success('Your password has been changed.');
      // }
    } catch (err) {
      setLoading(false);
      // @ts-ignore
      if (err.response?.data?.message === 'Incorrect password.') {
        setErrorResponse(
          'Your current password does not matches with the password you provided. Please try again.'
        );
      } else {
        Toast.error('Your password change failed.');
      }
      console.log('err', err);
    }
  };

  return (
    <Wrapper>
      <nav className="d-flex">
        <div className="logo-practice">
          {formsPatientPortal?.logoPractice && (
            <img src={formsPatientPortal?.logoPractice} alt="logo-practice" />
          )}
        </div>
        <div className={`nav-link__header d-flex`}>
          <ul className="menu--desktop">
            {navItems.map((item) => (
              <React.Fragment key={item.route}>
                {(item.isAuthenticated && !isAuthenticated) ||
                isNoPermissionAccess(item.route) ? null : (
                  <li>
                    <NavLink
                      exact={item.isExact}
                      to={`/${formsPatientPortal.practiceName}/${item.route}`}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
          <Account
            practiceName={formsPatientPortal.practiceName}
            isAuth={isAuthenticated}
            fullName={getFullName()}
            iconName={getInitialsName()}
            handleLogout={handleLogout}
            handleChangePassword={() => setOpenModalChangePassword(true)}
          />
          <FontAwesomeIcon
            icon={['fas', 'bars']}
            className="menu-bar__icon"
            onClick={() => setOpenNavbarMobile(true)}
          />
          <Modal
            open={openNavbarMobile}
            onClose={handleCloseNavbar}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal__menu-bar"
          >
            <ul>
              {isAuthenticated && (
                <>
                  <li className="account">
                    <span className="account-info">
                      <span className="account-icon">{getInitialsName()}</span>
                      <span className="account-name">{getFullName()}</span>
                    </span>
                  </li>
                </>
              )}

              {navItems.map((item) => (
                <React.Fragment key={item.route}>
                  {(item.isAuthenticated && !isAuthenticated) ||
                  isNoPermissionAccess(item.route) ? null : (
                    <li>
                      <NavLink
                        exact={item.isExact}
                        to={`/${formsPatientPortal.practiceName}/${item.route}`}
                        onClick={handleCloseNavbar}
                      >
                        {item.title}
                      </NavLink>
                    </li>
                  )}
                </React.Fragment>
              ))}

              {isAuthenticated ? (
                <>
                  <li>
                    <span onClick={handleOpenModalReset}>Change password</span>
                  </li>
                  <li>
                    <span onClick={handleLogout}>Logout</span>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link
                      to={`/${formsPatientPortal.practiceName}/login`}
                      onClick={handleCloseNavbar}
                    >
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/${formsPatientPortal.practiceName}/register`}
                      onClick={handleCloseNavbar}
                    >
                      Register
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </Modal>
        </div>
      </nav>

      <Modal
        open={openModalChangePassword}
        onClose={() => setOpenModalChangePassword(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal__change-password">
          <Formik
            initialValues={initialValue}
            validationSchema={validateSchema}
            validateOnMount={true}
            validateOnChange={true}
            onSubmit={(values) => handleSetPassword(values)}
          >
            {({ isValid, handleSubmit, handleChange }) => (
              <form
                onSubmit={handleSubmit}
                autoComplete="off"
                className="auth-container__form"
              >
                <p className="title">Reset password</p>
                <div className="auth__input-container">
                  <label htmlFor={'currentPassword'}>
                    Current password <b className="require">*</b>
                  </label>
                  <FastField
                    required
                    type="password"
                    autoComplete="off"
                    maxLength={50}
                    name="currentPassword"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setErrorResponse('');
                    }}
                  />
                  <p className="error-msg">
                    <ErrorMessage
                      name="currentPassword"
                      render={(msg) => (
                        <>
                          <FontAwesomeIcon
                            icon={['fas', 'exclamation-circle']}
                          />
                          <span>{msg}</span>
                        </>
                      )}
                    />
                    {errorResponse && (
                      <>
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                        <span>{errorResponse}</span>
                      </>
                    )}
                  </p>
                </div>

                <div className="auth__input-container">
                  <label htmlFor="password">
                    New password <b className="require">*</b>
                  </label>
                  <FastField
                    type="password"
                    required
                    maxLength={50}
                    autoComplete="off"
                    name="password"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setErrorResponse('');
                    }}
                  />
                  <p className="error-msg">
                    <ErrorMessage
                      name="password"
                      render={(msg) => (
                        <>
                          <FontAwesomeIcon
                            icon={['fas', 'exclamation-circle']}
                          />
                          <span>{msg}</span>
                        </>
                      )}
                    />
                  </p>
                </div>
                <div className="auth__input-container">
                  <label htmlFor="rePassword">
                    Confirm new password <b className="require">*</b>
                  </label>
                  <FastField
                    type="password"
                    required
                    maxLength={50}
                    autoComplete="off"
                    name="rePassword"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setErrorResponse('');
                    }}
                  />
                  <p className="error-msg">
                    <ErrorMessage
                      name="rePassword"
                      render={(msg) => (
                        <>
                          <FontAwesomeIcon
                            icon={['fas', 'exclamation-circle']}
                          />
                          <span>{msg}</span>
                        </>
                      )}
                    />
                  </p>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  className="btn btn-change-password"
                  disabled={!isValid || !!errorResponse}
                  type="submit"
                >
                  Save changes
                </Button>
                <button
                  className="btn-close"
                  onClick={() => setOpenModalChangePassword(false)}
                >
                  <FontAwesomeIcon icon={['fal', 'times']} />
                </button>
              </form>
            )}
          </Formik>
        </div>
      </Modal>

      <Modal
        open={loading}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Loading className="loading-spinner loading-center" />
      </Modal>
    </Wrapper>
  );
};

export default Header;
