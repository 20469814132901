import React from 'react';
import { useAppSelector } from 'hooks/redux';

import { Link, useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';

const ThankYou = () => {
  window.document.title = 'Patient Portal | Register';

  const location = useLocation();
  const practiceName = useAppSelector(
    (state) => state.formsPatientPortal.practiceName
  );

  return (
    <div className="auth__form-register--success">
      <p className="title">
        {location.state
          ? 'Registration successful'
          : 'Oops! Something went wrong.'}
      </p>

      <p className="description">
        {location.state
          ? "Please check your email for login instructions. If you don't receive an email within the next 15 minutes, please check your spam folder."
          : "This could have happened because you aren't yet a customer of ours or we don't have you in our system under the information you provided. Please try again or reach out to our office for assistance."}
      </p>

      <Link to={`/${practiceName}/login`}>
        <Button variant="contained" color="primary" className="btn">
          Back to login
        </Button>
      </Link>
    </div>
  );
};

export default ThankYou;
