import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useStyles } from './CustomButton.style';

interface IProps extends ButtonProps {
  title: string;
  isCancel?: boolean;
}

const CustomButton: React.FC<IProps> = (props) => {
  const classes = useStyles(props);
  const { title, isCancel = false, className = '', ...rest } = props;

  return (
    <Button
      {...rest}
      className={`${classes.button} ${
        isCancel ? classes.btnCancel : classes.btnSubmit
      } ${className}`}
      color={'default'}
    >
      <p className={classes.title}>{title}</p>
    </Button>
  );
};

CustomButton.defaultProps = {
  isCancel: false,
};

export default CustomButton;
