import {
  GET_LOGO_PRACTICE,
  GET_LOGO_PRACTICE_SUCCESS,
  GET_LOGO_PRACTICE_FAILED
} from 'constants/actions';

export const getLogoPractice = (practiceName: string) => {
  return {
    type: GET_LOGO_PRACTICE,
    practiceName
  }
}

export const getLogoPracticeSuccess = ({ logoPractice, practiceName, practiceEmailAddress, practicePhoneNumber }: { logoPractice: string, practiceName: string, practiceEmailAddress: string, practicePhoneNumber: string }) => {
  return {
    type: GET_LOGO_PRACTICE_SUCCESS,
    logoPractice,
    practiceName,
    practiceEmailAddress,
    practicePhoneNumber
  }
}

export const getLogoPracticeFailed = () => {
  return {
    type: GET_LOGO_PRACTICE_FAILED,
  }
}