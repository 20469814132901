import { combineReducers } from 'redux';
import { FormsPatientPortalReducer } from 'scenes/FormsPatientPortal/redux/reducer';
import { authReducer } from 'scenes/Auth/redux/reducers';
import { featuresReducer } from './feature/reducer';

const rootReducer = combineReducers({
  formsPatientPortal: FormsPatientPortalReducer,
  auth: authReducer,
  features: featuresReducer,
});

export default rootReducer;
