import React from 'react';
import { DialogActions, DialogContent } from '@material-ui/core';
import DialogCancelAndConfirm from '../footers/DialogCancelAndConfirm/DiaglogCancelAndConfirm';
import {
  SizedDialog,
  ContentContainer,
  StyledDialogTitle,
  StyledDialogContentText
} from './styled-components';

export interface FooterProps {
  cancelHandler(event: React.MouseEvent<HTMLButtonElement>): void;
  confirmHandler(event: React.MouseEvent<HTMLButtonElement>): void;
  confirmTitle?: string;
  disabledConfirm?: boolean;
}

export interface ConfirmationDialogProps {
  confirmHandler(event: React.MouseEvent<HTMLButtonElement>): void;
  textTitle: string;
  textDescription: string;
  confirmButtonTitle?: string;
  open: boolean;
  cancelHandler(event: React.MouseEvent<HTMLButtonElement>): void;
  DialogFooter?: React.FunctionComponent<FooterProps>;
  disabledConfirm?: boolean;
}

const ConfirmationDialog = (
  props: ConfirmationDialogProps
): React.ReactElement => {
  const {
    confirmHandler,
    cancelHandler,
    confirmButtonTitle,
    textTitle,
    textDescription,
    open,
    DialogFooter = DialogCancelAndConfirm,
    disabledConfirm = false
  } = props;

  return (
    <SizedDialog open={open}>
      <ContentContainer>
        <StyledDialogTitle id="alert-dialog-title">
          {textTitle}
        </StyledDialogTitle>
        <DialogContent>
          <StyledDialogContentText id="alert-dialog-description">
            {textDescription}
          </StyledDialogContentText>
        </DialogContent>
      </ContentContainer>
      <DialogActions>
        <DialogFooter
          confirmTitle={confirmButtonTitle}
          confirmHandler={confirmHandler}
          cancelHandler={cancelHandler}
          disabledConfirm={disabledConfirm}
        />
      </DialogActions>
    </SizedDialog>
  );
};

export default ConfirmationDialog;
