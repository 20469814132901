/* eslint-disable consistent-return */
import React from "react";
import classNames from "classnames";
import { getMapFromArray, getFieldPropertyValues, fieldIsType } from ".";
import ValidationMessage from "./validation-message";
import { FormFieldBase } from "./form-field-base";
import { FormFieldInput } from "./form-field-input";
import r from "./enums";

const { STATE, COUNTRY } = r;

export const FormFieldAddress = ({
  field,
  className,
  commonProps,
  isRenderedField,
  onChange,
  value,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  const getFieldSelectOptions = (field) => {
    if (!isRenderedField) return;

    if (fieldIsType(field, "State / Province", "display_name")) {
      const listState = [
        "Select a State / Province",
        ...(field.options ?? STATE).sort(),
      ];
      return listState.map((val, idx) => (
        <option
          hidden={val === "Select a State / Province"}
          key={idx}
          value={val === "Select a State / Province" ? "" : val}
        >
          {val}
        </option>
      ));
    }
    if (fieldIsType(field, "Country", "display_name"))
      return COUNTRY.map((val) => <option key={val}>{val}</option>);
  };

  const { fields } = field;
  const fieldMap = new Map();

  // transform fields array to:
  // {0: [fields[0]], // address 1
  //  1: [fields[1]], // address 2
  //  2: [fields[2], fields[3], fields[4]], // city, state, zip
  //  3: [fields[5]]} // country
  fields
    .sort((a, b) => a.sort_order - b.sort_order) // First: make sure sub-fields are properly sorted
    .map((sf, index) => {
      const subfield = { ...sf };
      if (isRenderedField && value[index] !== undefined)
        subfield.value = value[index];
      if (index >= 2 && index <= 4) {
        if (!fieldMap.get(2)) fieldMap.set(2, [subfield]);
        else fieldMap.get(2).push(subfield);
      } else {
        fieldMap.set(index, [subfield]);
      }
    });

  const classes = classNames({
    [className]: true,
    address: true,
    "control-group error": validationMessage,
  });

  return (
    <div key={field.uuid || commonProps.label} className={classes}>
      <div className="form-field-label header">
        {`
            ${commonProps.label}
          `}
        {commonProps.makeRequired ? <b style={{ color: "#102341" }}>*</b> : ""}
      </div>
      <div className="flex">
        {Array.from(fieldMap).map((fields, key) => {
          if (fields[1][0].display_name === "Country") {
            return;
          }
          return (
            <div key={key} className="flex">
              {fields[1].map((el) => {
                const properties = getMapFromArray(el.properties);
                const propertyNames = ["sublabel", "isVisible"];
                const defaultValues = { sublabel: "", isVisible: true };
                const propertyValues = getFieldPropertyValues(
                  properties,
                  propertyNames,
                  defaultValues
                );

                if (propertyValues.isVisible) {
                  return (
                    <FormFieldInput
                      key={el.id}
                      field={el}
                      children={getFieldSelectOptions(el)}
                      options={{
                        properties,
                        sublabel: propertyValues.sublabel,
                        subfield: true,
                        isRenderedField,
                        onChange,
                        value: el.value || "",
                        onBlur,
                        readOnly,
                      }}
                    />
                  );
                }
                return null;
              })}
            </div>
          );
        })}
      </div>
      <ValidationMessage message={validationMessage} />
    </div>
  );
};

export default FormFieldBase(FormFieldAddress);

FormFieldAddress.defaultProps = {
  className: "",
  commonProps: {},
  isRenderedField: false,
  onChange: () => {},
  value: [],
  onBlur: () => {},
  readOnly: false,
};
