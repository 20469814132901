import React from "react";

import "./Item.scss";

export interface Item {
  name?: string;
  leftIcon?: React.ReactNode;
  detail: string | React.ReactNode;
  rightAction?: string | React.ReactNode;
  elementMore?: React.ReactNode;
}

type Props = {
  item: Item;
  handleClickItem?: () => void;
  handleClickSection?: () => void;
  classMore?: string;
};

const ItemUI = ({
  item,
  classMore = "",
  handleClickItem,
  handleClickSection,
}: Props) => {
  return (
    <li className={`item ${classMore}`}>
      <span
        className={`${handleClickItem ? "link" : ""}`}
        onClick={handleClickItem}
      >
        <span className="left-content">
          <span
            className={`d-flex--center ${handleClickSection && "link"}`}
            onClick={handleClickSection}
          >
            {item.leftIcon && (
              <span className="left-icon">{item.leftIcon}</span>
            )}
            <span className="content-detail">
              {item.name && (
                <span className="name text--bolder">{item.name}</span>
              )}
              <span className="content-more">{item.detail}</span>
            </span>
          </span>
          {item.elementMore && <>{item.elementMore}</>}
        </span>

        {item.rightAction && (
          <span className="right-action">{item.rightAction}</span>
        )}
      </span>
    </li>
  );
};

export default ItemUI;
