import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Card from 'components/Global/Card/Card';
import ItemUI from 'components/Global/Card/Item';
import StatusSelect from 'components/Global/Status';
import JoinAppointmentIcon from 'components/Global/TeleMed/JoinAppointmentIcon';

import { DocumentType } from 'services/ResponseTypes/Document';
import {
  LoadingMoreType,
  LoadingType,
  LoadMoreType,
  TabType,
} from 'scenes/Pets/PetProfileContainer';
import { AppointmentResponseType } from 'services/ResponseTypes/Appointment';
import { convertStringToPascalCase } from 'utils/convertData';
import { VaccinationResponseType } from 'services/ResponseTypes/Vaccination';
import { ReminderResponseType } from 'services/ResponseTypes/Reminder';
import LinkButton from 'components/Global/Button/LinkButton';
import { Compliance, MedicalHistory } from 'services/ResponseTypes/Medical';
import { useAppSelector } from 'hooks/redux';

type IProps = {
  petId: string;
  petSpecies: string;
  practiceName: string;
  activeTab: string;
  documents: DocumentType[];
  appointmentsDue: ReminderResponseType[];
  upcomingVisits: AppointmentResponseType[];
  pastVisits: AppointmentResponseType[];
  vaccinations: VaccinationResponseType[];
  medicalHistories: MedicalHistory[];
  compliances: Compliance[];
  loadingMore: LoadingMoreType;
  loading: LoadingType;
  handleRedirectToForm: (form: DocumentType) => void;
  handleLoadMoreData: (type: LoadMoreType) => void;
  onChangeTab: (tab: TabType) => void;
};

const PetTabContent = (props: IProps) => {
  const {
    petId,
    petSpecies,
    practiceName,
    activeTab,
    documents,
    appointmentsDue,
    upcomingVisits,
    pastVisits,
    vaccinations,
    medicalHistories,
    compliances,
    loading,
    loadingMore,
    handleRedirectToForm,
    handleLoadMoreData,
    onChangeTab,
  } = props;
  const routeFlag = useAppSelector((state) => state.features.routeFlag);

  const renderDetailReminder = useCallback(
    (reminder: ReminderResponseType | VaccinationResponseType) => {
      const timeShow = moment(
        reminder.injectedDate || reminder.scheduleDate
      ).format('MM/DD/YY');
      if (reminder.state === 'PastDue') {
        return `Not Received ${moment(reminder.scheduleDate).format(
          'MM/DD/YY'
        )}`;
      }
      if (reminder.isCompleted) {
        return `Last received on ${timeShow}`;
      }
      if (reminder.state === 'ActionNeeded') {
        return `Next Due Date ${timeShow}`;
      }
      return `Next Due Date Scheduled ${moment(reminder.scheduleDate).format(
        'MM/DD/YY'
      )}`;
    },
    []
  );

  const renderAppoinmentsDue = useMemo(() => {
    return (
      <>
        {appointmentsDue.length > 0 ? (
          <ul className="list-reminder">
            {appointmentsDue.map((apt) => (
              <ItemUI
                classMore={apt.state === 'PastDue' ? 'reminder--past-due' : ''}
                key={apt.reminderId}
                item={{
                  name: apt.title,
                  detail: <>{renderDetailReminder(apt)}</>,
                  elementMore:
                    apt.state === 'UpToDate' ? (
                      <span className="status--complete vaccination-status">
                        <FontAwesomeIcon icon={['fas', 'check-circle']} />
                        <span>Up-to-date</span>
                      </span>
                    ) : apt.state === 'PastDue' ? (
                      <span className="status status--danger">Past Due</span>
                    ) : (
                      <span className="status status--warning">
                        {moment().add(1, "year") < moment(apt.scheduleDate) ? 'Pending' : 'Action Needed'}
                      </span>
                    ),
                  rightAction: routeFlag['online-booking'] &&
                    apt.state === 'PastDue' && (
                      <LinkButton
                        redirectTo={{
                          pathname: `/${practiceName}/online-booking`,
                          state: petId,
                        }}
                        txtButton="Schedule now"
                      />
                    ),
                }}
              />
            ))}
          </ul>
        ) : (
          <span className="no-result">No results found.</span>
        )}
      </>
    );
  }, [appointmentsDue, practiceName, petId]);

  const renderUpcomingVisits = useMemo(() => {
    return (
      <>
        {upcomingVisits.length > 0 ? (
          <ul className="list-upcoming-visit">
            {upcomingVisits.map((apt) => (
              <ItemUI
                key={apt.appointmentId}
                item={{
                  leftIcon: (
                    <>
                      {apt.kind === 'telemed' && apt.teleMedKey ? (
                        <JoinAppointmentIcon
                          key={'join-appointment-icon-' + apt.appointmentId}
                          customClasses={{
                            button: 'btn-telemed__join-appointment',
                          }}
                        />
                      ) : null}
                    </>
                  ),
                  name: '',
                  detail: (
                    <>
                      <ul className="detail__info-list">
                        <li>
                          {`${moment(apt.dateTime, 'YYYY-MM-DD hh:mm').format(
                            'dddd MMM Do, YYYY'
                          )} at ${moment(apt.dateTime).format('hh:mm A')}`}
                        </li>
                        <li>{apt.appointmentTypeName ?? 'N/A'}</li>
                        <li>{apt.providerName ?? 'N/A'}</li>
                      </ul>
                    </>
                  ),
                  rightAction: (
                    <StatusSelect
                      id={apt.appointmentId}
                      currentStatus={apt.status}
                    />
                  ),
                }}
              />
            ))}
          </ul>
        ) : (
          <span className="no-result">No results found.</span>
        )}
      </>
    );
  }, [upcomingVisits]);

  const renderPastVisits = useMemo(() => {
    return (
      <>
        {pastVisits.length > 0 ? (
          <ul className="list-past-visits">
            {pastVisits.map((apt) => (
              <ItemUI
                key={apt.appointmentId}
                item={{
                  leftIcon: (
                    <>
                      {apt.kind === 'telemed' && apt.teleMedKey ? (
                        <JoinAppointmentIcon
                          key={'join-appointment-icon-' + apt.appointmentId}
                          customClasses={{
                            button: 'btn-telemed__join-appointment',
                          }}
                        />
                      ) : null}
                    </>
                  ),
                  name: '',
                  detail: (
                    <ul className="detail__info-list">
                      <li>
                        {`${moment(apt.dateTime, 'YYYY-MM-DD hh:mm').format(
                          'dddd MMM Do, YYYY'
                        )} at ${moment(apt.dateTime).format('hh:mm A')}`}
                      </li>
                      <li>{apt.appointmentTypeName ?? 'N/A'}</li>
                      <li>{apt.providerName ?? 'N/A'}</li>
                    </ul>
                  ),
                  rightAction: (
                    <StatusSelect
                      id={apt.appointmentId}
                      currentStatus={apt.status}
                    />
                  ),
                }}
              />
            ))}
          </ul>
        ) : (
          <span className="no-result">No results found.</span>
        )}
      </>
    );
  }, [pastVisits]);

  const renderPetDocuments = useMemo(() => {
    return (
      <>
        {documents.length > 0 ? (
          <ul className="list-documents">
            {documents.map((document) => (
              <ItemUI
                key={document.id}
                item={{
                  name: '',
                  detail: <>{document.form_name}</>,
                  elementMore: (
                    <span className={`form-status ${document.documentStatus}`}>
                      {convertStringToPascalCase(
                        document.documentStatus ?? 'New'
                      )}
                    </span>
                  ),
                  rightAction: (
                    <>
                      {document.documentStatus === 'submitted' ? (
                        <button
                          className="btn btn--light"
                          onClick={() => handleRedirectToForm(document)}
                        >
                          Edit
                        </button>
                      ) : (
                        <button
                          className="btn btn--light"
                          onClick={() => handleRedirectToForm(document)}
                        >
                          Start
                        </button>
                      )}
                    </>
                  ),
                }}
              />
            ))}
          </ul>
        ) : (
          <span className="no-result">No results found.</span>
        )}
      </>
    );
  }, [documents]);

  const renderHistory = useMemo(() => {
    return (
      <>
        {medicalHistories.length > 0 ? (
          <ul className="list-reminder">
            {medicalHistories.map((service) => (
              <ItemUI
                key={service.medicalService.medicalServiceId}
                item={{
                  name: service.medicalService.medicalServiceName,
                  detail: service.administeredDate
                    ? `${moment(
                        service.administeredDate,
                        'YYYY-MM-DD hh:mm'
                      ).format('dddd MMM Do, YYYY')} at ${moment(
                        service.administeredDate
                      ).format('hh:mm A')}`
                    : '',
                }}
              />
            ))}
          </ul>
        ) : (
          <span className="no-result">No results found.</span>
        )}
      </>
    );
  }, [medicalHistories, practiceName, petId]);

  const renderCompliance = useMemo(() => {
    return (
      <>
        {compliances.filter(service => (service.medicalService.speciesIds && petSpecies != '') ? service.medicalService.speciesIds.includes(petSpecies) : true).length > 0 ? (
          <ul className="list-reminder">
            {compliances.filter(service => (service.medicalService.speciesIds && petSpecies != '') ? service.medicalService.speciesIds.includes(petSpecies) : true).map((service) => (
              <ItemUI
                key={service.medicalService.medicalServiceId}
                item={{
                  name: service.medicalService.medicalServiceName,
                  detail: service.administeredDate
                    ? `Expires ${moment(service.administeredDate).format(
                        'MM/DD/YYYY'
                      )}`
                    : '',
                  elementMore:
                    service.state === 'UpToDate' ? (
                      <span className="status--complete vaccination-status">
                        <FontAwesomeIcon icon={['fas', 'check-circle']} />
                        <span>Up-to-date</span>
                      </span>
                    ) : service.state === 'OverDue' ? (
                      <span className="status status--danger">Over Due</span>
                    ) : (
                      <span className="status status--warning">No History</span>
                    ),
                }}
              />
            ))}
          </ul>
        ) : (
          <span className="no-result">No results found.</span>
        )}
      </>
    );
  }, [compliances, practiceName, petId]);

  const renderTabPanel = (): React.ReactElement => {
    switch (activeTab) {
      case 'Documents':
        return (
          <Card
            key={activeTab + 'documents'}
            title="Pet Documents"
            children={renderPetDocuments}
            loading={loading.petDocuments}
          />
        );
      case 'History':
        return (
          <Card
            key={activeTab + 'history'}
            title={
              <span className="medical-tab">
                <span
                  className={`sub-tab ${
                    activeTab === 'History' ? 'active' : ''
                  }`}
                >
                  History
                </span>
                <span
                  className={`sub-tab`}
                  onClick={() => onChangeTab('Compliance')}
                >
                  Compliance
                </span>
              </span>
            }
            children={renderHistory}
            loading={loading.history}
            loadingMore={loadingMore.history}
            handleLoadMore={() => handleLoadMoreData('history')}
            isScrollContent={true}
          />
        );
      case 'Compliance':
        return (
          <Card
            key={activeTab + 'compliance'}
            title={
              <span className="medical-tab">
                <span
                  className="sub-tab"
                  onClick={() => onChangeTab('History')}
                >
                  History
                </span>
                <span className="sub-tab active">Compliance</span>
              </span>
            }
            children={renderCompliance}
            loading={loading.compliance}
            loadingMore={loadingMore.compliance}
            handleLoadMore={() => handleLoadMoreData('compliance')}
            isScrollContent={true}
          />
        );
      case 'Medications':
      default:
        return (
          <React.Fragment key={activeTab + 'visits'}>
            <Card
              title="Services Due"
              children={renderAppoinmentsDue}
              loading={loading.appointmentsDue}
              loadingMore={loadingMore.appointmentsDue}
              handleLoadMore={() => handleLoadMoreData('appointmentsDue')}
              classMore="small-scroll"
            />

            <Card
              title="Upcoming Visits"
              children={renderUpcomingVisits}
              loading={loading.upcomingVisits}
              loadingMore={loadingMore.upcomingVisits}
              handleLoadMore={() => handleLoadMoreData('upcomingVisits')}
              classMore="small-scroll"
            />

            <Card
              title="Past Visits"
              children={renderPastVisits}
              loading={loading.pastVisits}
              loadingMore={loadingMore.pastVisits}
              handleLoadMore={() => handleLoadMoreData('pastVisits')}
              classMore="small-scroll"
            />
          </React.Fragment>
        );
    }
  };

  return <>{renderTabPanel()}</>;
};

export default PetTabContent;
