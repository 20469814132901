import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { ErrorMessage, FastField, Formik } from 'formik';
import * as Yup from 'yup';

import LinkButton from 'components/Global/Button/LinkButton';

import { ErrorMessages } from 'constants/validateErrors';
import { resetPassword } from 'services/pratices';
import { useAppSelector } from 'hooks/redux';
import { Button } from '@material-ui/core';

interface ValueType {
  emailAddress: string;
}

const initialValue = {
  emailAddress: '',
};

const validateSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .required(`Email Address ${ErrorMessages.required}`)
    .email(ErrorMessages.emailFormat)
    .max(50, ErrorMessages.incorrectFormat),
});

const ForgotPassword = () => {
  window.document.title = 'Patient Portal | Forgot Password';

  const micrositeName = useAppSelector(
    (state) => state.formsPatientPortal.practiceName
  );

  const [email, setEmail] = useState('');
  const [errorResponse, setErrorResponse] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const handleRequest = async (values: ValueType) => {
    try {
      setDisabledSubmit(true);
      const res = await resetPassword({
        emailAddress: values.emailAddress,
        micrositeName,
      });

      if (res) {
        setEmail(values.emailAddress);
        setDisabledSubmit(false);
      }
    } catch (err) {
      console.log('err', err);
      setDisabledSubmit(false);
      // @ts-ignore
      setErrorResponse('Email does not exist in the system.');
    }
  };

  return (
    <>
      {!email ? (
        <Formik
          initialValues={initialValue}
          validationSchema={validateSchema}
          validateOnMount={true}
          validateOnChange={true}
          onSubmit={(values) => handleRequest(values)}
        >
          {({
            isValid,
            setFieldValue,
            handleSubmit,
            handleBlur,
            handleChange,
          }) => (
            <form onSubmit={handleSubmit}>
              <p className="title">Reset your password</p>
              <p className="description">
                Please enter the email address you'd like your password reset
                information sent to.
              </p>

              <div className="auth__input-container">
                <label htmlFor="emailAddress">
                  Email <b className="require">*</b>
                </label>
                <FastField
                  required
                  placeholder="Email"
                  name="emailAddress"
                  maxLength={50}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setErrorResponse('');
                  }}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleBlur(e);
                    setFieldValue('emailAddress', e.target.value.trim());
                  }}
                />
                <p className="error-msg">
                  <ErrorMessage
                    name="emailAddress"
                    render={(msg) => (
                      <>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        <span>{msg}</span>
                      </>
                    )}
                  />
                  {!!errorResponse && (
                    <>
                      <FontAwesomeIcon icon={faExclamationCircle} />
                      <span>{errorResponse}</span>
                    </>
                  )}
                </p>
              </div>

              <Button
                variant="contained"
                color="primary"
                className="btn btn-login"
                disabled={!isValid || !!errorResponse || disabledSubmit}
                type="submit"
              >
                Reset password
              </Button>

              <span className="back-login">
                <Link to={`/${micrositeName}/login`}>Back to login</Link>
              </span>
            </form>
          )}
        </Formik>
      ) : (
        <div className="auth__form-register--success">
          <span className="email-success__icon">
            <FontAwesomeIcon icon={['fas', 'check-circle']} />
            <FontAwesomeIcon icon={['far', 'envelope']} />
          </span>
          <p className="title">Help is on the way</p>
          <p className="description">
            <p>
              We're going through our records to find an account for&nbsp;
              <span className="text--bolder">{email}</span>
            </p>
            <br />
            If we find a match, you will get an email with further instructions.
            If you don't hear from us in the next 15 minutes, please double
            check that you entered the correct email address and check your spam
            folder.
          </p>
          <LinkButton
            redirectTo={`/${micrositeName}/login`}
            txtButton="Back to login"
            className="btn"
            withShadow={true}
          />
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
