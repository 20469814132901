import React from 'react';
import classNames from 'classnames';
import { getMapFromArray, getFieldPropertyValues } from '.';
import { FormFieldBase } from './form-field-base';
import { FormFieldInput } from './form-field-input';
import ValidationMessage from './validation-message';

export const FormFieldSubFields = ({
  field,
  className,
  commonProps,
  isRenderedField,
  onChange,
  value,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  const classes = classNames({
    [className]: true,
    subfields: true,
    'control-group error': validationMessage,
  });

  return (
    <div className={classes}>
      <div className='form-field-label header'>{`
            ${commonProps.label}
          `}
          {commonProps.makeRequired ?  <b style={{color: "#102341"}}>*</b> : ''}</div>
      <div className='flex'>
        {field.fields
          .sort((a, b) => a.sort_order - b.sort_order)
          .map((subfield, index) => {
            const properties = getMapFromArray(subfield.properties);
            const propertyNames = ['sublabel', 'placeholder', 'isVisible'];
            const defaultValues = { sublabel: '', placeholder: '', isVisible: true };
            const propertyValues = getFieldPropertyValues(properties, propertyNames, defaultValues);

            if (propertyValues.isVisible) {
              return (
                <FormFieldInput
                  key={subfield.id}
                  field={subfield}
                  options={{
                    properties,
                    sublabel: propertyValues.sublabel,
                    subfield: true,
                    isRenderedField,
                    onChange,
                    value: value[index] || '',
                    onBlur,
                    disabled: readOnly,
                  }}
                />
              );
            }
            return null;
          })}
      </div>
      <ValidationMessage message={validationMessage} />
    </div>
  );
};

export default FormFieldBase(FormFieldSubFields);

FormFieldSubFields.defaultProps = {
  commonProps: {},
  isRenderedField: false,
  onChange: () => { },
  value: [],
  onBlur: () => { },
  readOnly: false,
};
