import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import "regenerator-runtime/runtime";
import rootReducer from "./reducer";
import rootSaga from "./saga";
// import {composeWithDevTools} from 'redux-devtools-extension'

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSaga);
