const styles = {
  container: {
    width: '100%',
    textAlign: 'center',
  },
  icon: {
    fontSize: '24px',
    paddingBottom: '20px',
  },
  title: {
    fontSize: '20px',
    lineHeigh: '24px',
    fontWeight: '600',
  },
  description: {
    margin: '0 auto',
    maxWidth: '55%',
  },
};

export default styles;
