import * as t from "io-ts";

const SubmissionRequired = t.type({
  id: t.string,
  submissionsDetail: t.string,
  JsonStructure: t.string,
  isApproved: t.boolean,
});

const SubmissionNullable = t.partial({
  email: t.string,
  isRead: t.boolean,
  link: t.string,
  name: t.string,
  submissionDate: t.string,
});

export const SubmissionEntry = t.intersection([
  SubmissionRequired,
  SubmissionNullable,
]);

export type SubmissionResponseType = t.TypeOf<typeof SubmissionEntry>;
