import React, { useEffect, useMemo } from 'react';
import Header from 'components/Header';
import { ReactComponent as LoadingSpinner } from 'assets/images/loading.svg';

import { getUserInfo } from 'scenes/Auth/redux/actions';
import { getSessionJWT } from 'services/SessionJWT';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Container } from '@material-ui/core';
import { useRouter } from 'hooks/useRouter';
import { useParams } from 'react-router-dom';
import { getLogoPractice } from 'scenes/FormsPatientPortal/redux/actions';

interface IProps {
  component: React.FunctionComponent<Record<string, unknown>>;
  titleBar: string;
}

const PageWithHeader = (props: IProps): JSX.Element => {
  const router = useRouter();
  const params = useParams();
  const path = useMemo(() => router.pathname.split('/')[2], [router.pathname]);
  const micrositeName = useMemo(() => router.pathname.split('/')[1], [router.pathname]);
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.auth.userInfo.userId);
  const practiceName = useAppSelector(
    (state) => state.formsPatientPortal.practiceName
  );
  const { loading, routeFlag } = useAppSelector((state) => state.features);
  const paths = Object.keys(routeFlag);
  const Component = props.component;

  const isNoPermissionAccess = useMemo(
    () => paths.includes(path) && !routeFlag[path],
    [routeFlag, path]
  );

  useEffect(() => {
    if (!practiceName) {
      // @ts-ignore
      dispatch(getLogoPractice(params.practiceMicrositeName));
    }
    if (!!getSessionJWT() && !userId) {
      dispatch(getUserInfo());
    }
  }, []);

  useEffect(() => {
    //console.log('Step 1: start dispatching')
    if (practiceName) {
      //console.log('Step 2: micrositename: ',practiceName)
      dispatch({
        type: 'FETCH_FEATURES',
        micrositeName: practiceName,
      });
    }
  }, [practiceName]);

  if (loading) {
    return <LoadingSpinner className="loading-spinner loading-center" />;
  }

  return (
    <>
      <Header titleBar={props.titleBar} />
      <Container>
        <div className="patient-portal-content" id="patient-portal-app">
          {isNoPermissionAccess ? (
            <p>You don't have permission to access this site.</p>
          ) : (
            <Component />
          )}
        </div>
      </Container>
    </>
  );
};

export default PageWithHeader;
