import React, { useEffect } from 'react';
import { FormFieldBase } from './form-field-base';
import { getFieldProperties, getFieldPropertyValues } from '../helpers';

export const FormFieldEstimate = ({ field }) => {
  const properties = getFieldProperties(field, ['common', 'specific']);
  const { estimateContent } = getFieldPropertyValues(properties.specific, 'estimateContent');
  
  let { isDisplayTax } = getFieldPropertyValues(properties.specific, 'isDisplayTax');
  if(isDisplayTax === undefined){
    isDisplayTax = true;
  }
  const totalLowTax = isDisplayTax ? estimateContent.estimateItems.map(ei => ei.lowTax).reduce((ei1, ei2) => ei1 + ei2) : 0;
  const totalHighTax = isDisplayTax ? estimateContent.estimateItems.map(ei => ei.highTax).reduce((ei1, ei2) => ei1 + ei2) : 0;
  const totalLowPrice = Number(estimateContent.estimateItems.map(ei => ei.lowPrice).reduce((ei1, ei2) => ei1 + ei2).toFixed(2)) + Number(totalLowTax);
  const totalHighPrice = Number(estimateContent.estimateItems.map(ei => ei.highPrice).reduce((ei1, ei2) => ei1 + ei2).toFixed(2)) + Number(totalHighTax);

  
  return (
    <div className='estimate'>
      {(estimateContent != undefined && Object.keys(estimateContent).length != 0) ?
        <div className='estimate-content'>
          <div className='estimate-top-container'>
            <div className='estimate-top-container-left'>
              <span style={{ "fontWeight": "bold" }}>Customer</span>
              <span>{estimateContent.contactFirstName} {estimateContent.contactLastName}</span>
              <span>{estimateContent.contactEmail}</span>
            </div>
            <div className='estimate-top-container-right'>
              <span>{estimateContent.estimateDate}</span>
              <span>{estimateContent.estimateDesc}</span>
            </div>
          </div>
          <div className='estimate-top-container-left'>
            <span style={{ "fontWeight": "bold" }}>For {estimateContent.petName}</span>
            <span>Sex: {estimateContent.petSex}</span>
            <span>Breed: {estimateContent.petBreed}</span>
            <span>Birthdate: {estimateContent.petDOB}</span>
          </div>
          <div className='estimate-table-container'>
            <table className='estimate-table'>
              <tr>
                <th className="estimate-table-cell">Description</th>
                <th className="estimate-table-cell">Low quantity</th>
                <th className="estimate-table-cell">High quantity</th>
                <th className="estimate-table-cell">Low price</th>
                <th className="estimate-table-cell">High price</th>
              </tr>
              {estimateContent.estimateItems.map(ei => {
                return (
                  <tr>
                    <td className="estimate-table-cell">{ei.itemDescription}</td>
                    <td className="estimate-table-cell">{Number(ei.lowQuantity.toFixed(2))}</td>
                    <td className="estimate-table-cell">{Number(ei.highQuantity.toFixed(2))}</td>
                    <td className="estimate-table-cell">${ei.lowPrice.toFixed(2)}</td>
                    <td className="estimate-table-cell">${ei.highPrice.toFixed(2)}</td>
                  </tr>
                )
              })}
              {isDisplayTax && <tr>
                <td className="estimate-table-cell">Total Tax</td>
                <td className="estimate-table-cell"></td>
                <td className="estimate-table-cell"></td>
                <td className="estimate-table-cell">${totalLowTax ? totalLowTax.toFixed(2) : 0.00}</td>
                <td className="estimate-table-cell">${totalHighTax ? totalHighTax.toFixed(2) : 0.00}</td>
              </tr>}
              <tr>
                <td className="estimate-table-cell">Total</td>
                <td className="estimate-table-cell"></td>
                <td className="estimate-table-cell"></td>
                <td className="estimate-table-cell">${totalLowPrice.toFixed(2)}</td>
                <td className="estimate-table-cell">${totalHighPrice.toFixed(2)}</td>
              </tr>
            </table>
          </div>
        </div>
        : <span>Treatment estimate field. Will show nothing unless used in tandem with the Estimates feature.</span>}
    </div>
  );
};

const controls = {
  edit: true,
  delete: true,
  more: true,
  // more: __DEV__,
};
export default FormFieldBase(FormFieldEstimate, controls);
