import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

const SizedDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 415px;
    border-radius: 15px;
    background-color: #ffffff;
    box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
  }
  .MuiDialogContent-root:first-child {
    padding-top: 2rem;
  }
  .MuiDialogContent-root:nth-child(2) {
    padding: 0;
  }
  .MuiDialogTitle-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const ContentContainer = styled(DialogContent)`
  padding-top: 2rem;
  margin: 0 auto;
  text-align: center;
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding-top: 40px !important;
  color: #12253f;
  font-size: 17px;
  line-height: 20px;
  .MuiTypography-h6 {
    font-weight: 600;
  }
`;

const StyledDialogContentText = styled(DialogContentText)`
  color: #12253f !important;
`;

const DrawerContainer = styled.div`
  min-width: ${(props: { minWidth?: number; maxWidth?: number }): string => {
    return props.minWidth ? `${props.minWidth}rem` : '20rem';
  }};
  max-width: ${(props: { minWidth?: number; maxWidth?: number }): string => {
    return props.maxWidth ? `${props.maxWidth}rem` : 'none';
  }};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20rem;
`;

const FooterContainer = styled.div`
  background-color: white;
  width: 100%;
  flex-grow: 0;
`;

const HeaderContainer = styled.div`
  background-color: white;
  width: 100%;
  flex-grow: 0;
`;

const ChildrenContainter = styled.div`
  overflow: auto;
  height: 100%;
  flex-grow: 1;
`;

export {
  SizedDialog,
  ContentContainer,
  StyledDialogTitle,
  StyledDialogContentText,
  DrawerContainer,
  FooterContainer,
  HeaderContainer,
  ChildrenContainter
};
