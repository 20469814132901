import React, { FC, useEffect, useState } from 'react';
import { ErrorMessage, FastField, FieldArray, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';

import ModalDialog from '.';
import Filter from '../Filter';

import { PhoneNumberResponseType } from '../../../services/ResponseTypes/PhoneNumber';
import { UserResponseType } from '../../../services/ResponseTypes/User';
import { phoneNumberPattern, phonePattern } from '../../../utils/regexs';
import ModalDiscard from './ModalDiscard';
import { Button } from '@material-ui/core';

interface IParameterUpdatePhone {
  phoneNumbers: PhoneNumberResponseType[];
}

interface IProps {
  isOpenModal: boolean;
  handleCloseModal: (arg: boolean) => void;
  userInfo?: UserResponseType;
  phoneNumbers: PhoneNumberResponseType[];
  handleUpdatePhone: (values: IParameterUpdatePhone) => void;
}

const ErrorMessageTypes = {
  incorrectFormat: 'Incorrect format.',
  required: 'This field is required.',
};

const validateSchema = Yup.object().shape({
  phoneNumbers: Yup.array().of(
    Yup.object().shape({
      number: Yup.string()
        .required(ErrorMessageTypes.required)
        .matches(phonePattern, ErrorMessageTypes.incorrectFormat),
      extension: Yup.number().typeError(ErrorMessageTypes.incorrectFormat),
    })
  ),
});

const typesPhone = [
  { label: 'Mobile Phone', value: 'cell' },
  { label: 'Home Phone', value: 'landline' },
  { label: 'Office Phone', value: 'business' },
  { label: 'Other Phone', value: 'other' },
];

const ModalEditPhone: FC<IProps> = ({
  isOpenModal,
  phoneNumbers,
  handleCloseModal,
  handleUpdatePhone,
}) => {
  const [openWarningPopup, setOpenWarningPopup] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [initialData, setInitialData] = useState({
    phoneNumbers: [
      {
        number: '',
        kind: 'cell',
        isValidated: false,
        isText: false,
        isCall: false,
        isPrimary: false,
        subscribe: true,
      },
    ],
  });

  useEffect(() => {
    setInitialData({
      // @ts-ignore
      phoneNumbers: phoneNumbers.length
        ? phoneNumbers.map((item)=>{
          return {...item, number:formatPhoneInput(item.number)}})
        : [
            {
              number: '',
              kind: 'cell',
              isValidated: false,
              isText: false,
              isCall: false,
              isPrimary: false,
              subscribe: true,
            },
          ],
    });
  }, [isOpenModal]);

  const handleConfirmPopupWarning = () => {
    setOpenWarningPopup(false);
    handleCloseModal(false);
    setIsDirty(false);
  };

  //@ts-ignore
  const formatPhoneInput = (phoneNumber) => {
    if(!phoneNumber) return;
    const curValue = phoneNumber.replace(/[^0-9]/g, '');
    if(curValue.length < 4) return curValue
    if(curValue.length < 7) return `(${curValue.slice(0, 3)})-${curValue.slice(3)}`
    return `(${curValue.slice(0, 3)})-${curValue.slice(3, 6)}-${curValue.slice(6, 10)}`
  }

  const modalBody = () => {
    return (
      <Formik
        initialValues={initialData}
        validationSchema={validateSchema}
        validateOnMount={true}
        validateOnChange={true}
        onSubmit={(values, actions) => {
          let newVals = {phoneNumbers: values.phoneNumbers.map(item=>{
            let newObj = {...item, number:item.number.replace(/[^0-9]/g,'')}
            return newObj
          })}
          handleUpdatePhone(newVals);
        }}
      >
        {(formikProps) => {
          const {
            handleSubmit,
            handleBlur,
            setFieldValue,
            values,
            isValid,
            dirty,
          } = formikProps;
          if (dirty && isOpenModal) {
            setIsDirty(true);
          }

          return (
            <form onSubmit={handleSubmit} className="edit-phone__form">
              <div
                className={`edit-phone__list ${
                  values.phoneNumbers.length > 8 ? 'add-scroll' : ''
                }`}
              >
                <FieldArray
                  name="phoneNumbers"
                  render={({ remove, push }) => (
                    <>
                      {values.phoneNumbers.length > 0 &&
                        (values.phoneNumbers as PhoneNumberResponseType[]).map(
                          (item, index) => {
                            return (
                              <div key={index + 1} className="edit-phone__item">
                                <div className="mr-5">
                                  <FastField
                                    name={`phoneNumbers.${index}.number`}
                                    max={10}
                                    className={`w-100 ${
                                      values.phoneNumbers[index]?.subscribe ===
                                        false && 'unsubscribe'
                                    }`}
                                    disabled={
                                      values.phoneNumbers[index]?.subscribe ===
                                      false
                                    }
                                    onBlur={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      handleBlur(e);
                                      setFieldValue(
                                        `phoneNumbers.${index}.number`,
                                        e.currentTarget.value.trim()
                                      );
                                    }}
                                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue(
                                        `phoneNumbers.${index}.number`,
                                        formatPhoneInput(e.currentTarget.value)
                                      )
                                    }}
                                  />
                                  <p className="error-msg">
                                    <ErrorMessage
                                      className="error-msg"
                                      name={`phoneNumbers.${index}.number`}
                                    />
                                  </p>
                                </div>
                                {/* <div className="w-25 mr-5 phone-ext">
                                  <FastField
                                    maxLength={50}
                                    name={`phoneNumbers.${index}.extension`}
                                    placeholder="Ext."
                                    className={`setting__search-form width-50 margin-r-0 ${
                                      values.phoneNumbers[index]?.subscribe ===
                                        false && "unsubscribe"
                                    }
                                    `}
                                    disabled={
                                      values.phoneNumbers[index]?.subscribe ===
                                      false
                                    }
                                    onBlur={(
                                      e: React.FocusEvent<HTMLInputElement>
                                    ) => {
                                      handleBlur(e);
                                      setFieldValue(
                                        `phoneNumbers.${index}.extension`,
                                        e.currentTarget.value.trim()
                                      );
                                    }}
                                  />
                                  <p className="msg-error">
                                    <ErrorMessage
                                      className="msg-error"
                                      name={`phoneNumbers.${index}.extension`}
                                    />
                                  </p>
                                </div> */}
                                <div className={`search-filter__global`}>
                                  {!values.phoneNumbers[index].subscribe ? (
                                    <FastField
                                      maxLength={50}
                                      name={`phoneNumbers.${index}.kind`}
                                      className={`setting__search-form width-50 margin-r-0 ${
                                        values.phoneNumbers[index]
                                          ?.subscribe === false && 'unsubscribe'
                                      }
                                    `}
                                      value={
                                        typesPhone.find(
                                          (type: any) =>
                                            type.value ===
                                            values.phoneNumbers[index].kind
                                        )?.label
                                      }
                                      disabled={
                                        values.phoneNumbers[index]
                                          ?.subscribe === false
                                      }
                                    />
                                  ) : (
                                    <Filter
                                      optionsForFilter={typesPhone}
                                      handleSetNewFilterValue={(newTypePhone) =>
                                        setFieldValue(
                                          `phoneNumbers.${index}.kind`,
                                          newTypePhone.value
                                        )
                                      }
                                      valueSelected={typesPhone.find(
                                        (type: any) =>
                                          type.value ===
                                          values.phoneNumbers[index].kind
                                      )}
                                    />
                                  )}
                                </div>
                                {/* <div className="btn-group">
                                  <button
                                    type="button"
                                    className={`${
                                      values.phoneNumbers[index].isText
                                        ? "btn-selected"
                                        : ""
                                    }`}
                                    disabled={
                                      !values.phoneNumbers[index].subscribe
                                    }
                                    onClick={() => {
                                      if (!values.phoneNumbers[index].isText) {
                                        const idxOldText =
                                          values.phoneNumbers.findIndex(
                                            (item: any) => item.isText
                                          );
                                        idxOldText > -1 &&
                                          setFieldValue(
                                            `phoneNumbers.${idxOldText}.isText`,
                                            false
                                          );
                                      }
                                      setFieldValue(
                                        `phoneNumbers.${index}.isText`,
                                        !values.phoneNumbers[index].isText
                                      );
                                    }}
                                  >
                                    Text
                                  </button>
                                  <button
                                    type="button"
                                    className={`${
                                      values.phoneNumbers[index].isCall
                                        ? "btn-selected"
                                        : ""
                                    }`}
                                    disabled={
                                      !values.phoneNumbers[index].subscribe
                                    }
                                    onClick={() => {
                                      if (!values.phoneNumbers[index].isCall) {
                                        const idxOldCall =
                                          values.phoneNumbers.findIndex(
                                            (item: PhoneNumberResponseType) =>
                                              item.isCall
                                          );
                                        idxOldCall > -1 &&
                                          setFieldValue(
                                            `phoneNumbers.${idxOldCall}.isCall`,
                                            false
                                          );
                                      }
                                      setFieldValue(
                                        `phoneNumbers.${index}.isCall`,
                                        !values.phoneNumbers[index].isCall
                                      );
                                    }}
                                  >
                                    Call
                                  </button>
                                </div> */}
                                <span
                                  className="btn-remove"
                                  onClick={() => remove(index)}
                                >
                                  <FontAwesomeIcon
                                    icon={['fal', 'times']}
                                    className="icon-remove-fallback"
                                  />
                                </span>
                              </div>
                            );
                          }
                        )}
                        {/* <span
                        className="add-fallback"
                        onClick={() => {
                          push({
                            number: '',
                            extension: '',
                            kind: 'cell',
                            isValidated: false,
                            isText: false,
                            isCall: false,
                            isPrimary: false,
                            subscribe: true,
                          });
                        }}
                      >
                        +Add Phone Number
                      </span> */}
                    </>
                  )}
                />
              </div>
              <div className="modal-footer">
                <Button
                  variant="contained"
                  type="button"
                  className="btn btn-left"
                  onClick={() =>
                    dirty ? setOpenWarningPopup(true) : handleCloseModal(false)
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="btn btn-right"
                  disabled={!dirty || !isValid}
                >
                  Save
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <ModalDialog
        isOpenModal={isOpenModal}
        title="Edit Phone Number"
        isShowButtonModal={false}
        handleCloseModal={() => {
          if (isDirty) {
            setOpenWarningPopup(true);
          } else {
            handleCloseModal(false);
          }
        }}
        handleConfirmModal={() => {}}
        classMore="modal__edit-phone"
      >
        {modalBody()}
      </ModalDialog>

      {openWarningPopup && (
        <ModalDiscard
          isOpenModal={openWarningPopup}
          handleCloseModal={setOpenWarningPopup}
          handleConfirmModal={handleConfirmPopupWarning}
        />
      )}
    </>
  );
};

export default ModalEditPhone;
