import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import LinkButton from 'components/Global/Button/LinkButton';
import Card from '../Global/Card/Card';
import ItemUI from '../Global/Card/Item';

import { AppointmentResponseType } from 'services/ResponseTypes/Appointment';
import { ReminderResponseType } from 'services/ResponseTypes/Reminder';

import {
  LoadingMoreType,
  LoadingType,
  LoadMoreType,
} from 'scenes/DashBoard/DashboardContainer';
import JoinAppointmentIcon from 'components/Global/TeleMed/JoinAppointmentIcon';
import StatusSelect from 'components/Global/Status';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';

interface Props {
  practiceName: string;
  upcomingVisits: AppointmentResponseType[];
  appointmentsDue: ReminderResponseType[];
  loading: Partial<LoadingType>;
  loadingMore: Partial<LoadingMoreType>;
  handleLoadMore: (arg: LoadMoreType) => void;
}

const LeftDashboard = ({
  practiceName,
  upcomingVisits,
  appointmentsDue,
  loading,
  loadingMore,
  handleLoadMore,
}: Props) => {
  const history = useHistory();
  const routeFlag = useAppSelector((state) => state.features.routeFlag);

  const renderDetailReminder = useCallback((reminder: ReminderResponseType) => {
    const timeShow = moment(
      reminder.injectedDate || reminder.scheduleDate
    ).format('MM/DD/YY');
    if (reminder.state === 'PastDue') {
      return `Not Received ${moment(reminder.scheduleDate).format('MM/DD/YY')}`;
    }
    if (reminder.isCompleted) {
      return `Last received on ${timeShow}`;
    }
    if (reminder.state === 'ActionNeeded') {
      return `Next Due Date ${timeShow}`;
    }
    return `Next Due Date Scheduled ${moment(reminder.scheduleDate).format(
      'MM/DD/YY'
    )}`;
  }, []);

  const renderUpcomingVisits = useMemo(() => {
    return (
      <>
        {upcomingVisits.length > 0 ? (
          <ul className="list-upcoming-visit">
            {upcomingVisits.map((apt) =>
              apt.patients.pets.map((pet) => (
                <ItemUI
                  key={apt.appointmentId}
                  handleClickSection={() =>
                    history.push(`/${practiceName}/pets/${pet.petId}`)
                  }
                  item={{
                    leftIcon: (
                      <span className="avatar d-flex--center">
                        {pet.imageUrl && (
                          <img src={pet.imageUrl} alt="Pet Avatar" />
                        )}
                      </span>
                    ),
                    name: pet.name,
                    detail: (
                      <>
                        <span className="telemed-icon">
                          {apt.kind === 'telemed' && apt.teleMedKey && (
                            <JoinAppointmentIcon
                              key={'join-appointment-icon-' + apt.appointmentId}
                              customClasses={{
                                button: 'btn-telemed__join-appointment',
                              }}
                            />
                          )}
                        </span>
                        <ul className="detail__info-list">
                          <li>
                            {`${moment(apt.dateTime, 'YYYY-MM-DD hh:mm').format(
                              'dddd MMM Do, YYYY'
                            )} at ${moment(apt.dateTime).format('hh:mm A')}`}
                          </li>
                          <li>{apt.appointmentTypeName ?? 'N/A'}</li>
                          <li>{apt.providerName ?? 'N/A'}</li>
                        </ul>
                      </>
                    ),
                    rightAction: (
                      <StatusSelect
                        id={apt.appointmentId}
                        currentStatus={apt.status}
                      />
                    ),
                  }}
                />
              ))
            )}
          </ul>
        ) : (
          <span className="no-result">No results found.</span>
        )}
      </>
    );
  }, [upcomingVisits]);

  const renderAppoinmentsDue = useMemo(() => {
    return (
      <>
        {appointmentsDue.length > 0 ? (
          <ul className="list-reminder">
            {appointmentsDue.map((reminder) => (
              <ItemUI
                classMore={
                  reminder.state === 'PastDue' ? 'reminder--past-due' : ''
                }
                key={reminder.reminderId}
                handleClickSection={() =>
                  history.push(
                    `/${practiceName}/pets/${reminder.pet?.petId ?? ''}`
                  )
                }
                item={{
                  leftIcon: (
                    <span className="avatar d-flex--center">
                      {reminder.pet?.imageUrl && (
                        <img src={reminder.pet.imageUrl} alt="Pet Avatar" />
                      )}
                    </span>
                  ),
                  name: reminder.pet?.name,
                  detail: (
                    <>
                      <p className="text--bolder">{reminder.title}</p>{' '}
                      {renderDetailReminder(reminder)}
                    </>
                  ),
                  elementMore:
                    reminder.state === 'UpToDate' ? (
                      <span className="status--complete vaccination-status">
                        <FontAwesomeIcon icon={['fas', 'check-circle']} />
                        <span>Up-to-date</span>
                      </span>
                    ) : reminder.state === 'PastDue' ? (
                      <span className="status status--danger">Past Due</span>
                    ) : (
                      <span className="status status--warning">
                        {moment().add(1, "year") < moment(reminder.scheduleDate) ? 'Pending' : 'Action Needed'}
                      </span>
                    ),
                  rightAction: routeFlag['online-booking'] &&
                    reminder.state === 'PastDue' && (
                      <LinkButton
                        redirectTo={{
                          pathname: `/${practiceName}/online-booking`,
                          state: reminder.pet?.petId || '',
                        }}
                        txtButton="Schedule now"
                      />
                    ),
                }}
              />
            ))}
          </ul>
        ) : (
          <span className="no-result">No results found.</span>
        )}
      </>
    );
  }, [appointmentsDue, practiceName]);

  return (
    <div className="dashboard-container__left">
      <Card
        title="Upcoming Visits"
        children={renderUpcomingVisits}
        loading={loading.upcomingVisits}
        loadingMore={loadingMore.upcomingVisits}
        handleLoadMore={() => handleLoadMore('upcomingVisits')}
        classMore="small-scroll"
      />
      <Card
        title="Services Due"
        children={renderAppoinmentsDue}
        loading={loading.appointmentsDue}
        loadingMore={loadingMore.appointmentsDue}
        handleLoadMore={() => handleLoadMore('appointmentsDue')}
        classMore="small-scroll"
      />
    </div>
  );
};

export default LeftDashboard;