import validator from "validator";
import {
  getFieldValidators,
  getFieldValue,
  getFieldProperties,
  getMapFromArray,
  Messages,
} from "./helpers";

const getFieldPropertyValues = (
  propertyMap,
  propertyNames,
  defaultValues = {}
) => {
  let property;
  const values = {};
  if (typeof propertyNames === "string") propertyNames = [propertyNames];
  propertyNames.forEach((propertyName) => {
    try {
      property = propertyMap.get(propertyName);
      values[propertyName] = property.value;
    } catch (ex) {
      if (!property || !values[propertyName])
        values[propertyName] = defaultValues[propertyName];
    }
  });
  return values;
};

const validateSubField = (field) => {
  const visibleSubfields = field.fields.map(
    (sub) => sub.properties[0][1].value
  );
  const values = field.value;
  return (
    visibleSubfields.findIndex((item, idx) => {
      if (
        ["Co-Owner Address", "Contact Address"].includes(field.display_name) &&
        idx === 1
      ) {
        return false;
      }
      return item && !values[idx];
    }) === -1
  );
};

export const validateField = (field, requiredOverride = false) => {
  const makeRequired = requiredOverride || isRequired(field);
  const makeHidden = isHidden(field);
  const value = getFieldValue(field);
  const validators = getFieldValidators(field);
  const properties = getFieldProperties(field, "specific");
  const phonePattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  let errors = [];
  if (makeRequired && !makeHidden) {
    const validationError = validateIsBlank(field, value);
    if (
      [
        "Contact Name",
        "Co-Owner Name",
        "Co-Owner Address",
        "Contact Address",
      ].includes(field.display_name) &&
      !validateSubField(field)
    ) {
      errors.push(Messages.CANT_BE_BLANK);
    } else if (
      field.display_name === "Pet Species & Breed" &&
      !validateSubField(field)
    ) {
      errors.push(Messages.SELECT_DROPDOWN);
    } else if (validationError) {
      errors.push(validationError);
    }
  }
  // if (["CoPhoneNumber", "PhoneNumber"].includes(field.column_name) && !value) {
  //   errors.push(Messages.INVALID_PHONE_NUMBER);
  // }
  // only run validations if we either have a value or it is a required field
  // e.g., a blank value shouldn't fail validation unless the field is required
  if (value && errors.length === 0) {
    validators.forEach((validation) => {
      const result = validation.fn(value, { properties });
      if (result && !result.valid) errors.push(result.message);
    });
  }

  if (errors.length > 0) {
    return [`${field.display_name || field.type_name} ${errors[0]}`];
  }
};

const isHidden = (field) => {
  return getFieldPropertyValues(
    getFieldProperties(field, "common").common,
    "makeHidden"
  ).makeHidden;
};

const isRequired = (field) => {
  return getFieldPropertyValues(
    getFieldProperties(field, "common").common,
    "makeRequired"
  ).makeRequired;
};

const validateIsBlank = (field, value) => {
  if (field.type_name === "Multiple Choice" && noneTrue(value))
    return Messages.ONE_CHECKED;

  const uuidNameArray = [
    "0bfa90c5-caaa-4553-b2b6-b115b37cf259", // communication release
    "201491cd-8d5f-4448-a211-6e5bae0cbfbc", // authorization and acknowledgements
    "342ca295-40e9-4c5a-8cc7-898ca0cbbb04", // insurance_info
    "7083bb57-28aa-4ee0-b81a-bdb700f539e7", // dental_history
    "8271260b-ddf1-4d3a-9f55-e3fe71a352fb", // patient_history
    "8d226da1-2162-4aad-aa74-07e38d016cd0", // medical history
  ];

  if (uuidNameArray.includes(field.uuid)) {
    if (
      validator.isEmpty(field.value[0], { ignore_whitespace: true }) &&
      validator.isEmpty(field.value[2], { ignore_whitespace: true })
    ) {
      return Messages.CANT_BE_BLANK;
    }
  }

  if (
    !value ||
    validator.isEmpty(value.toString(), { ignore_whitespace: true })
  )
    return Messages.CANT_BE_BLANK;
};

// custom validator for checkbox groups (multiple choice)
// that enforces at least one option should be checked (true), if required.
const noneTrue = (values) =>
  values.filter((value) => Object.values(value)[0]).length === 0;
