import React, { useEffect, useState } from 'react';

const FormFieldInputText = ({
  type,
  className,
  isRenderedField,
  onChange,
  displayName,
  value,
  ...rest
}) => {
  const dynamicProps = {};

  if (isRenderedField) {
    dynamicProps.onChange = onChange;
  } else {
    dynamicProps.readOnly = true;
    dynamicProps.disabled = true;
  }

  const getAutoComplete = () => {
    switch (displayName) {
      case 'First Name':
        return 'given-name';
      case 'Middle Name':
        return 'additional-name';
      case 'Last Name':
        return 'family-name';
      case 'Contact Phone Number':
      case 'Co-Owner Phone Number':
        return 'tel';
      case 'Contact Email Address':
      case 'Co-Owner Email Address':
        return 'email';
      case 'Address 1':
      case 'Address 2':
        return 'street-address';
      // case 'City':
      //   return 'city';
      case 'Zip / Postal Code':
        return 'postal-code';
      default:
        return 'off';
    }
  };

  return (
    <input
      autoComplete={getAutoComplete()}
      type={type}
      className={className}
      {...dynamicProps}
      {...rest}
      value={
        ['Contact Phone Number', 'Co-Owner Phone Number'].includes(displayName)
          ? value?.length > 0
            ? value.map((phone) => phone.number).join(', ')
            : ''
          : value
      }
    />
  );
};

FormFieldInputText.defaultProps = {
  className: 'span30',
  isRenderedField: false,
  onChange: () => {},
};

export default FormFieldInputText;
