import React from "react";
import { store } from "redux/configureStore";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import AppRouter from "routers";

function App() {
  return (
    <Provider store={store}>
      <AppRouter />
      <ToastContainer />
    </Provider>
  );
}

export default App;