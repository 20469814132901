import * as t from "io-ts";

const AddressLocation = t.partial({
  addressLine1: t.string,
  addressLine2: t.string,
  city: t.string,
  country: t.string,
  postalCode: t.string,
  state: t.string,
});

export const AddressEntryData = t.type({
  userAddressId: t.string,
  address: AddressLocation,
  isPrimary: t.boolean,
  kind: t.string,
});

export type AddressResponseType = t.TypeOf<typeof AddressEntryData>;
