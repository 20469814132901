import React from 'react';
import { FormFieldInput } from './form-field-input';
import { FormFieldBase } from './form-field-base';
import { getFieldProperties, getFieldPropertyValues } from '../helpers';

export const FormFieldSpinner = ({
  field,
  isRenderedField,
  onChange,
  value,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  const properties = getFieldProperties(field, ['common', 'specific']);
  const { step, negativeValues } = getFieldPropertyValues(properties.specific, [
    'step',
    'negativeValues',
  ]);
  const options = {
    properties,
    isRenderedField,
    onChange,
    value,
    onBlur,
    validationMessage,
    readOnly,
  };

  if (step && step !== '1') options.step = step;
  if (negativeValues) options.min = 0;

  return <FormFieldInput field={field} options={options} />;
};

FormFieldSpinner.defaultProps = {
  isRenderedField: false,
  onChange: () => { },
  onBlur: () => { },
  readOnly: false,
};

export default FormFieldBase(FormFieldSpinner);
