/* eslint-disable no-underscore-dangle */
// As this file grows it could be split into other files and then those
// can be imported into this one.
const SESSION_TOKEN = 'lwsid';
const UI_ROOT = `/practice`;
const LARGE_SCREEN = 1100; // Greater thant this is consider large
const API_DOMAIN = 'http://localhost:3001';
const SUPPORT_EMAIL = 'support@legwork.com';
const getRootDomain = (includeApi = true): string => {
  // for local development outside of the docker container can change to http://localhost:3001
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let baseUrl =
    (window as any)._env_?.LEGWORK_BASE_URL || window.location.origin;

  if (!includeApi) {
    baseUrl = baseUrl.replace(/(api\.)/gi, '');
  }

  return baseUrl;
};
const LOGIN_URL = getRootDomain(false);

const ACCOUNT_SERVICE_ROOT = `${getRootDomain()}/account/v1/`;

const STORAGE_SERVICE_ROOT = `${getRootDomain()}/storage/v1/`;
const LOGIN_DOMAIN = LOGIN_URL;

const RPC_PATH = `${getRootDomain()}/rpc`;
const API_PATH = `${getRootDomain()}/api/`;
const DATA_SERIES_LIMITS = {
  default: 5,
  topPages: 8
};
const SLUG_NAME = 'demo-company-0';
const ROLE_GUEST = 'GUEST';
const ROLE_SINGLE_USER = 'SINGLE_USER';
const ROLE_LEGAL_GUARDIAN = 'LEGAL_GUARDIAN';
const SMS = 'sms';
const EMAIL = 'email';
const APPOINTMENT_BOOKING = 'appointment-booking';
const DIRTY = 'DIRTY';
const PRISTINE = 'PRISTINE';
const UPCOMING_APPOINTMENTS_LIMIT = 5; // maximum of upcoming appoinment will be shown in the dashboard

export const PatientField = {
  id: 6,
  uuid: '26669538-9a1f-444f-881b-4847e8ebba3b',
  type_name: 'Dropdown',
  is_deleted: false,
  properties: {
    common: [
      [
        'label',
        {
          type: 'input',
          label: 'Label',
          value: 'Patient',
          isDynamic: false
        }
      ],
      [
        'isVisible',
        {
          type: 'checkbox',
          label: 'Visible Option',
          value: false,
          isDynamic: true
        }
      ],
      [
        'sublabel',
        {
          type: 'input',
          label: 'Sublabel',
          value: '',
          isDynamic: true
        }
      ],
      [
        'placeholder',
        {
          type: 'input',
          label: 'Placeholder',
          value: '',
          isDynamic: true
        }
      ],
      [
        'makeRequired',
        {
          type: 'checkbox',
          label: 'Make Required',
          value: false,
          isDynamic: false
        }
      ],
      [
        'makeHidden',
        {
          type: 'checkbox',
          label: 'Make Hidden',
          value: false,
          isDynamic: false
        }
      ],
      [
        'hideLabel',
        {
          type: 'checkbox',
          label: 'Hide Label',
          value: false,
          isDynamic: false
        }
      ],
      [
        'readOnly',
        {
          type: 'checkbox',
          label: 'Read-Only',
          value: false,
          isDynamic: false
        }
      ]
    ],
    specific: [
      ['placeholder', {}],
      ['options', { type: 'option-group', label: 'Options', value: [] }]
    ]
  },
  sort_order: 6,
  updated_at: '2020-08-11T21:17:29.674Z',
  updated_by: '5ca516994291bf5601000078',
  display_name: 'Patient',
  validationMessage: null,
  value: ''
};
// TODO: mock field patient in appointment booking form

const COOKIE_TIME_INTERVAL = 300000;

export default {
  SESSION_TOKEN,
  UI_ROOT,
  LARGE_SCREEN,
  API_DOMAIN,
  SUPPORT_EMAIL,
  getRootDomain,
  LOGIN_URL,
  ACCOUNT_SERVICE_ROOT,
  STORAGE_SERVICE_ROOT,
  LOGIN_DOMAIN,
  RPC_PATH,
  API_PATH,
  DATA_SERIES_LIMITS,
  SLUG_NAME,
  ROLE_GUEST,
  ROLE_SINGLE_USER,
  ROLE_LEGAL_GUARDIAN,
  SMS,
  EMAIL,
  APPOINTMENT_BOOKING,
  DIRTY,
  PRISTINE,
  COOKIE_TIME_INTERVAL,
  UPCOMING_APPOINTMENTS_LIMIT
};

export const populateDisplayFieldName = {
  singleField: [
    "Contact Email Address",
    "Contact Phone Number",
    "Contact Gender",
    "Co-Owner Email Address",
    "Co-Owner Phone Number",
    "Co-Owner Gender",
    "Pet Name",
    "Pet Sex",
    "Pet Birthdate",
  ],
  multiField: [
    "Contact Name",
    "Contact Address",
    "Co-Owner Name",
    "Co-Owner Address",
    "Pet Species & Breed",
  ],
  fields: ["Section Break", "Optional"],
};
