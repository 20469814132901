import React from 'react';

const hours = [null, '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

const minutes = [null, '00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

const FormFieldInputTime = ({
  className,
  prefix,
  sublabels,
  isRenderedField,
  onChange,
  id,
  hour,
  minute,
  ampm,
  timeRange,
  ...rest
}) => {
  const elementId = type => `${id}-${timeRange ? '1-' : ''}${type}`;

  const handleChange = () => {
    const timeSettings = {
      hour: document.getElementById(elementId('hour')).value,
      minute: document.getElementById(elementId('minute')).value,
      ampm: document.getElementById(elementId('ampm')).value,
    };
    const { hour, minute, ampm } = timeSettings;

    // create custom event object to pass up
    const event = {
      target: {
        id,
        value: `${hour || '12'}:${minute || '00'} ${ampm || 'AM'}`,
      },
    };

    onChange(event);
  };
  const dynamicProps = {};
  const ampmProps = {};

  if (isRenderedField) {
    dynamicProps.onChange = handleChange;
    if (!ampm) ampmProps.defaultValue = null;
    else ampmProps.value = ampm;
  } else {
    dynamicProps.readOnly = true;
    dynamicProps.disabled = true;
    ampmProps.defaultValue = 'AM';
  }

  return (
    <div className={`${className} time-picker`} style={{ display: 'flex' }}>
      {prefix && <span style={{ paddingTop: '7px' }}>{prefix}</span>}
      <div>
        <select id={elementId('hour')} type='number' value={hour} {...dynamicProps} {...rest}>
          {hours.map((hour, index) => (
            <option key={index} value={hour}>
              {hour}
            </option>
          ))}
        </select>
        <div className='form-field-label secondary'>{sublabels.Hour}</div>
      </div>
      <span style={{ paddingTop: '7px' }}>:</span>
      <div>
        <select id={elementId('minute')} type='number' value={minute} {...dynamicProps} {...rest}>
          {minutes.map((minute, index) => (
            <option key={index} value={minute}>
              {minute}
            </option>
          ))}
        </select>
        <div className='form-field-label secondary'>{sublabels.Minutes}</div>
      </div>
      <span style={{ paddingTop: '7px' }}>:</span>
      <div>
        <select id={elementId('ampm')} type='number' {...ampmProps} {...dynamicProps} {...rest}>
          <option value={null}>{null}</option>
          <option value='AM'>AM</option>
          <option value='PM'>PM</option>
        </select>
      </div>
    </div>
  );
};

FormFieldInputTime.defaultProps = {
  className: '',
  sublabels: { Hour: '', Minutes: '' },
  isRenderedField: false,
  onChange: () => { },
  id: '',
};

export default FormFieldInputTime;
