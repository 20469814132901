import { getRootDomain } from "../../../../utils/url";
import moment from "moment";

const validateArgs = (args) => {
  let isValid = true;
  const [urlApi, ...argsValidate] = args;
  Array.from(argsValidate).some((arg) => {
    if (!arg) {
      isValid = false;
      return !isValid;
    }
  });
  return isValid;
};

const Api = {
  async getAppointmentTypes(urlApiForEmbed, micrositeName) {
    if (!validateArgs(arguments)) return [];
    const response = await fetch(
      `${
        urlApiForEmbed || getRootDomain()
      }/tenants/v1/appointmentSettingType/micrositeName/${micrositeName}`
    );
    if (response.ok) {
      const results = await response.json();
      return results;
    }
    if (response.code >= 400) {
      const error = await response.json();

      if (error.message) {
        throw new Error(error.message);
      }

      throw new Error("Error in calling API.");
    }

    return [];
  },

  async getProvidersByAppointmentType(urlApiForEmbed, appointmentTypeId) {
    if (!validateArgs(arguments)) return [];
    const response = await fetch(
      `${
        urlApiForEmbed || getRootDomain()
      }/apointmentSettingProvider/v1/getAppointmentSettingProviderByAppointmentType/${appointmentTypeId}`
    );

    if (response.ok) {
      const results = await response.json();
      return results;
    }
    if (response.code >= 400) {
      const error = await response.json();

      if (error.message) {
        throw new Error(error.message);
      }

      throw new Error("Error in calling API.");
    }

    return [];
  },

  async getOpenDays(urlApiForEmbed, appointmentTypeId, providerId, date, duration) {
    if (!validateArgs(arguments)) return [];
    const month = moment(date).month() + 1;
    const year = moment(date).year();
    const response = await fetch(
      `${
        urlApiForEmbed || getRootDomain()
      }/tenants/v1/patientPortal/appointmentDate?appointmentTypeId=${appointmentTypeId}&providerId=${providerId}&month=${month}&year=${year}&duration=${duration}`
    );

    if (response.ok) {
      const results = await response.json();
      return results;
    }
    if (response.code >= 400) {
      const error = await response.json();

      if (error.message) {
        throw new Error(error.message);
      }

      throw new Error("Error in calling API.");
    }

    return [];
  },

  async getOpenTimeSlots(
    urlApiForEmbed,
    appointmentTypeId,
    providerId,
    day,
    duration
  ) {
    if (!validateArgs(arguments)) return [];
    const date = moment(day).format("YYYY-MM-DD");
    const response = await fetch(
      `${
        urlApiForEmbed || getRootDomain()
      }/tenants/v1/patientPortal/appointmentTime?appointmentTypeId=${appointmentTypeId}&providerId=${providerId}&date=${date}&duration=${duration}`
    );
    if (response.ok) {
      const results = await response.json();
      return results.appointmentTimeInfos;
    }
    if (response.code >= 400) {
      const error = await response.json();

      if (error.message) {
        throw new Error(error.message);
      }

      throw new Error("Error in calling API.");
    }

    return [];
  },
};

export default Api;
