import React from 'react';
import classNames from 'classnames';
import { FormFieldBase } from './form-field-base';
import { getFieldProperties, getFieldPropertyValues, convertStringTo, replaceAt } from '../helpers';
import ValidationMessage from './validation-message';

let _value; // should this be refactored so it isn't module-scoped?

export const FormFieldSSN = ({
  field,
  isRenderedField,
  onChange,
  value,
  onBlur,
  validationMessage,
  readOnly,
  isReview,
}) => {
  const handleBlur = e => {
    onBlur({ target: e.target.nextSibling });
  };

  const cleanValue = val => val.replace(/[^0-9|\\•]/g, '').substr(0, 9);

  const transformDisplay = val => {
    // strip all non-numbers
    let displayVal = cleanValue(val);

    if (displayVal) displayVal = maskValue(displayVal, true);

    return displayVal;
  };

  const transformValue = val => {
    if (typeof _value !== 'string') _value = '';

    if (!val) {
      _value = null;
      return;
    }

    const cleanVal = cleanValue(val);

    for (let i = 0; i < cleanVal.length; i++) {
      if (/[0-9]/g.exec(cleanVal[i])) {
        _value = replaceAt(field.value, i, cleanVal[i]); // Note Magic in here old code not work:  _value = replaceAt(_value, i, cleanVal[i]);
      }
    }

    _value = _value.substr(0, cleanVal.length);
  };

  const maskValue = (str, showLast) => {
    const last = str[str.length - 1];
    const parts = [str.slice(0, 3), str.slice(3, 5), str.slice(5, 9)];

    str = `${convertStringTo(parts[0], '•')}${
      parts[1] ? `-${convertStringTo(parts[1], '•')}` : ''
      }${parts[2] ? `-${parts[2]}` : ''}`;
    if (showLast) str = replaceAt(str, str.length - 1, last);

    return str;
  };

  const syncInput = e => {
    // inspired by codepen: https://codepen.io/ashblue/pen/LGeqxx
    const val = e.target.value;
    const displayVal = transformDisplay(val);

    transformValue(val);

    e.target.value = displayVal;
    e.target.nextSibling.value = _value;

    onChange({ target: e.target.nextSibling });
  };

  const properties = getFieldProperties(field, 'common');
  const propertyNames = [
    'label',
    'sublabel',
    'makeRequired',
    'makeHidden',
    'hideLabel',
    'readOnly',
    'sublabel',
    'placeholder',
  ];
  const defaultValues = {
    label: field.display_name,
    sublabel: '',
    makeRequired: false,
    makeHidden: false,
    hideLabel: false,
    readOnly: false,
    placeholder: '',
  };
  const propertyValues = getFieldPropertyValues(properties.common, propertyNames, defaultValues);
  const containerClasses = classNames({
    'form-field-input-container': true,
    'hide-field': propertyValues.makeHidden,
    'hide-label': propertyValues.hideLabel,
    'read-only': propertyValues.readOnly || readOnly,
  });
  const validationClasses = classNames({
    'control-group error': validationMessage,
  });
  const id = field.uuid;
  const dynamicProps = {};

  if (isRenderedField) {
    dynamicProps.onChange = e => syncInput(e);
    dynamicProps.onBlur = handleBlur;
    dynamicProps.onKeyUp = e => syncInput(e);
    dynamicProps.readOnly = readOnly;
    dynamicProps.disabled = readOnly;
  } else {
    dynamicProps.readOnly = true;
    dynamicProps.disabled = true;
  }

  let maskedValue = value;
  if (value) maskedValue = isReview ? value : transformDisplay(value);

  return (
    <div key={id} className={containerClasses}>
      <div className='form-field-label primary'> {`
            ${propertyValues.label}
          `}
          {propertyValues.makeRequired ?  <b style={{color: "#102341"}}>*</b> : ''}</div>
      <div className='form-field-input'>
        <div className={validationClasses}>
          <input
            id={`${id}-masked`}
            type='text'
            className='span30'
            placeholder='xxx-xx-xxxx'
            maxLength={11}
            value={maskedValue}
            {...dynamicProps}
          />
          {isRenderedField && <input id={id} type='hidden' value={value} {...dynamicProps} />}
          <div className='form-field-label secondary'>{propertyValues.sublabel}</div>
          <ValidationMessage message={validationMessage} />
        </div>
      </div>
    </div>
  );
};

FormFieldSSN.defaultProps = {
  isRenderedField: false,
  onChange: () => { },
  value: '',
  onBlur: () => { },
  readOnly: false,
  isReview: false,
};

export default FormFieldBase(FormFieldSSN);
