// tslint:disable: max-file-line-count
import React, { useState, useCallback, useEffect } from 'react';
import { AccordionSummary, Card, Container, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { parseJSON } from '../../components/PatientPortal/common.util';
import {
  AccordionDetailsWrapper,
  AccordionWrapper,
  CardContentWrapper,
  EmailAndPhoneWrapper,
  FormItem,
  PhoneLink,
  Title,
  TypographyWrapper,
  useStyles,
} from './PatientDocument.style';
import { pathOr } from 'ramda';
import PrimaryButton from '../../components/PatientPortal/PrimaryButton/PrimaryButton.component';
import { listDocumentsMock } from '../../dataMock';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getFormsForPet,
  getListFormPractice,
  getPetsForContact,
} from 'services/pratices';
import {
  convertPhoneNumber,
  convertStringToPascalCase,
} from '../../utils/convertData';
import loading_spinner from 'assets/images/loading.svg';
import { useAppSelector } from 'hooks/redux';
import { PetResponseType } from 'services/ResponseTypes/Pet';
import { DocumentType } from 'services/ResponseTypes/Document';
import { getSessionJWT } from 'services/SessionJWT';

const PatientDocument = () => {
  const history = useHistory();
  const practice = useAppSelector((state) => state.formsPatientPortal);
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const isAuth = getSessionJWT();

  const [rawDocuments, setRawDocuments] = useState<any>([]);
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [pets, setPets] = useState<PetResponseType[]>([
    { petId: '', name: '', guardians: [] },
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<number>(0);
  const [loadingSection, setLoadingSection] = useState<boolean>(false);

  const classes = useStyles();
  const tenantInfo = parseJSON('tenantInfo');

  useEffect(() => {
    if (!practice.practiceName) {
      return;
    }
    if (!userInfo.userId && !isAuth) {
      getForms('', false, true);
    } else if (userInfo.userId && isAuth) {
      getListPet();
    }
  }, [userInfo, practice.practiceName]);

  const getListPet = async () => {
    try {
      const res = await getPetsForContact(userInfo.userId);

      if (res) {
        const activePets = res.pets.filter((pet) => pet.status === 'active');
        setPets([...activePets, { petId: '', name: '', guardians: [] }]);
        await getForms(
          activePets.length > 0 ? activePets[0].petId : '',
          false,
          true
        );
      }
    } catch (err) {
      setIsLoading(false);
      console.log('err', err);
    }
  };

  const formatPhoneNumber = (str: any) => {
    const cleaned = str.toString().replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return null;
  };

  const goToIndividualForm = (pet: PetResponseType, form: DocumentType) => {
    window.sessionStorage.setItem('pet', JSON.stringify(pet));
    form.documentStatus === 'submitted' &&
      window.sessionStorage.setItem(
        'latestSubmissionId',
        form.latestSubmissionId ?? ''
      );
    history.push(`/${practice.practiceName}/forms/${form.id}`);
  };

  const getTenantInfoByKey = (key: any) => {
    if (Array.isArray(key)) {
      return pathOr('', [...key])(tenantInfo);
    }

    return pathOr('', [key])(tenantInfo);
  };

  const getForms = useCallback(
    async (
      petId?: string,
      disabledLoading?: boolean,
      disabledLoadingSection?: boolean
    ) => {
      !disabledLoading && setIsLoading(true);
      !disabledLoadingSection && setLoadingSection(true);
      try {
        const response = petId
          ? await getFormsForPet(practice.practiceName, petId)
          : await getListFormPractice(practice.practiceName);

        if (response) {
          setDocuments(response.result);
          !disabledLoading && setIsLoading(false);
          !disabledLoadingSection && setLoadingSection(false);
        }
      } catch (err) {
        !disabledLoading && setIsLoading(false);
        !disabledLoadingSection && setLoadingSection(false);
        console.log('err', err);
      }
    },
    [practice.practiceName]
  );

  const handleExpanded = async (petId: string, index: number) => {
    setExpanded(expanded === index ? -1 : index);
    if (!userInfo.userId) {
      return;
    }
    if (expanded !== index) {
      await getForms(index === pets.length - 1 ? '' : petId, true);
    }
  };

  return (
    <>
      {isLoading ? (
        <img
          className="loading-spinner"
          src={loading_spinner}
          alt={'Loading'}
        />
      ) : (
        <Container>
          <Title className={classes.mb15}>Client Forms</Title>

          <p className={`${classes.textDescription} ${classes.mb10}`}>
            To complete a document, simply enter and submit the requested
            information. While most fields are optional, some may be required
            and are marked with an asterisk (*). Please do not use your
            browser's back or forward buttons, as they may undo or redo your
            recent actions and result in errors
          </p>

          <p className={`${classes.textDescription} ${classes.mb15}`}>
            The information you submit is encrypted for your protection and will
            be transmitted securely to our office.
          </p>

          <p className={`${classes.textDescription} ${classes.mb30}`}>
            If you have questions at any time, please call our office at{' '}
            <EmailAndPhoneWrapper>
              {convertPhoneNumber(practice?.practicePhoneNumber)}
            </EmailAndPhoneWrapper>
            <>
              <PhoneLink href={`tel:${getTenantInfoByKey('officeNumber')}`}>
                {formatPhoneNumber(getTenantInfoByKey('officeNumber'))}
              </PhoneLink>{' '}
              or send us an email at{' '}
              {practice?.practiceEmailAddress && (
                <EmailAndPhoneWrapper>
                  {practice?.practiceEmailAddress}
                </EmailAndPhoneWrapper>
              )}
              <span>{getTenantInfoByKey('emailAddress')}</span>
            </>
          </p>
          <React.Fragment>
            {pets.length > 0 &&
              pets.map((pet, idx) => (
                <AccordionWrapper
                  className={`${classes.accordionRoot} client-forms`}
                  square={true}
                  expanded={expanded === idx}
                  onChange={() => handleExpanded(pet.petId, idx)}
                  key={pet.petId}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <TypographyWrapper>
                      {`${
                        userInfo.userId
                          ? `${idx === pets.length - 1 ? 'New Pet' : pet.name}`
                          : 'Guest'
                      }'s Forms`}
                    </TypographyWrapper>
                  </AccordionSummary>
                  <AccordionDetailsWrapper>
                    <Grid
                      item={true}
                      sm={12}
                      className="patient-document__list-doc"
                    >
                      <Card
                        className={`${classes.pt0} ${classes.pb0} ${classes.borderRadius10}`}
                      >
                        <Grid
                          item={true}
                          sm={12}
                          className="patient-document__list-doc"
                        >
                          <Card
                            className={`${classes.pt0} ${classes.pb0} ${classes.borderRadius10}`}
                          >
                            <CardContentWrapper>
                              {loadingSection ? (
                                <img
                                  className="loading-spinner"
                                  src={loading_spinner}
                                  alt={'Loading'}
                                />
                              ) : (
                                <>
                                  {documents.sort((form1: DocumentType, form2: DocumentType) => { if (form1.form_name < form2.form_name) return -1; if (form1.form_name > form2.form_name) return 1; return 0; }).map((form: DocumentType) => {
                                    return (
                                      <FormItem key={form.id}>
                                        <p>
                                          <span className="form-name">
                                            {form.form_name}
                                          </span>
                                          {userInfo.userId && (
                                            <span
                                              className={`form-status ${form.documentStatus}`}
                                            >
                                              {convertStringToPascalCase(
                                                form.documentStatus ?? 'new'
                                              )}
                                            </span>
                                          )}
                                        </p>
                                        <div className={classes.buttonWrapper}>
                                          <PrimaryButton
                                            title={
                                              form.documentStatus ===
                                              'submitted'
                                                ? 'Edit'
                                                : 'Start'
                                            }
                                            variant="contained"
                                            className="btn-primary"
                                            titleClassName="patient-document"
                                            isShowIcon={true}
                                            onClick={() =>
                                              goToIndividualForm(pet, form)
                                            }
                                            style={{ borderRadius: '10px' }}
                                          />
                                        </div>
                                      </FormItem>
                                    );
                                  })}
                                </>
                              )}
                            </CardContentWrapper>
                          </Card>
                        </Grid>
                      </Card>
                    </Grid>
                  </AccordionDetailsWrapper>
                </AccordionWrapper>
              ))}
          </React.Fragment>
        </Container>
      )}
    </>
  );
};

export default PatientDocument;
