import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";

import { faCreditCard, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faCopy,
  faTimes,
  faChevronRight,
  faSignOutAlt,
  faBars,
  faPen,
  faCheckCircle,
  faClipboardCheck,
  faExclamationCircle,
  faLock,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { faTimes as faTimesLight } from "@fortawesome/pro-light-svg-icons";
import { faUpload } from "@fortawesome/pro-solid-svg-icons";
import { faTimes as faTimesRegular, faImage, faFile, faFileWord, faFilePdf } from "@fortawesome/pro-regular-svg-icons";

export const RegisterIcons = async (): Promise<Array<IconDefinition>> => {
  const icons: Array<IconDefinition> = [
    faCreditCard,
    faCopy,
    faExclamationCircle,
    faSignOutAlt,
    faTimes,
    faBars,
    faEnvelope,
    faLock,
    faCheckCircle,
    faChevronRight,
    faTimesLight,
    faPen,
    faClipboardCheck,
    faTimesRegular,
    faUpload,
    faImage,
    faX,
    faFile,
    faFileWord,
    faFilePdf,
  ];

  await library.add(...icons);

  return icons;
};
