import React from "react";
import styled from "styled-components";

const LoadingWrap = styled.div`
  .dot-flashing,
  .dot-flashing::before,
  .dot-flashing::after {
    width: 7px;
    height: 7px;
    border-radius: 50%;

    background-color: #b8b6c5;
    animation: dotFlashing 1s infinite alternate;
  }

  .dot-flashing {
    position: relative;
    margin: 15px auto 0;
    animation-delay: 0.5s;
    animation-timing-function: linear;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -13px;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 13px;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #b8b6c5;
    }
    50%,
    100% {
      background-color: #ebe6ff;
    }
  }
`;

const LoadingMore = () => {
  return (
    <LoadingWrap>
      <div className="dot-flashing"></div>
    </LoadingWrap>
  );
};

export default LoadingMore;
