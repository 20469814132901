const styles = {
  timeSlot: {
    minWidth: '51px',
    height: '18px',
    border: '1px solid #ccc',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '10px'
  },
  timeSlotHover: {
    backgroundColor: 'rgba(47, 104, 253, .5)',
  },
  timeSlotText: {
    margin: '0',
    paddingTop: '2px',
  },
  timeSlotSelected: {
    backgroundColor: '#2F68FD',
    color: 'white',
  },
  disabledSlot: {
    pointerEvents: 'none',
    opacity: 0.4
  }
};

export default styles;
