export const Labels = {
  AppointmentType: 'Appointment Type',
  Provider: 'Provider',
  AppointmentDate: 'Appointment Date',
  AppointmentTime: 'Appointment Time',
  Morning: 'Morning',
  Afternoon: 'Afternoon',
  Patient: 'Patient'
};

export const Defaults = {
  AppointmentType: { id: '', appointmentType: 'Select a reason for your visit', hidden: true, disabled: true  },
  Provider: { id: '', name: 'Select a provider', hidden: true, disabled: true },
  ProviderMergeAll: { id: "no-preference", name: "No Preference" },
};

const mockAccountId = '5ea06a0484f89f5e01000079';

// TODO: fetch these from Account Service API
export const Mocks = {
  AppointmentTypes: [
    { name: 'Exam and Cleaning', duration: 60, id: 1, accountId: mockAccountId },
    { name: 'Dental Emergency', duration: 60, id: 2, accountId: mockAccountId },
    { name: 'Braces Consultation', duration: 30, id: 3, accountId: mockAccountId },
    { name: 'Implant Consultation', duration: 30, id: 4, accountId: mockAccountId },
    { name: 'Veneers Consultation', duration: 30, id: 5, accountId: mockAccountId },
  ],

  Providers: [
    { contactId: '123', externalId: '123', status: 'active', isOnlineBookingEnabled: true, displayName: 'Alan Johnson', jobTitle: 'King', email: 'alan@summitdental.com', id: 1, accountId: mockAccountId },
    { contactId: '456', externalId: '456', status: 'active', isOnlineBookingEnabled: true, displayName: 'Anne Barton', jobTitle: 'Queen', email: 'anne@summitdental.com', id: 2, accountId: mockAccountId },
    { contactId: '789', externalId: '789', status: 'active', isOnlineBookingEnabled: true, displayName: 'Patrick Garza', jobTitle: 'Jester', email: 'patrick@summitdental.com', id: 3, accountId: mockAccountId },
  ],

  AppointmentTypeProviders: [
    { appointmentTypeId: 1, providerId: 1 },
    { appointmentTypeId: 1, providerId: 3 },
    { appointmentTypeId: 2, providerId: 1 },
    { appointmentTypeId: 2, providerId: 2 },
    { appointmentTypeId: 3, providerId: 2 },
    { appointmentTypeId: 3, providerId: 3 },
    { appointmentTypeId: 4, providerId: 1 },
    { appointmentTypeId: 4, providerId: 2 },
    { appointmentTypeId: 4, providerId: 3 },
  ],

  TimeSlots: [
    [
      { hour: 9, minute: 45 },
      { hour: 11, minute: 30 },
      { hour: 13, minute: 15 },
      { hour: 14, minute: 0 },
      { hour: 14, minute: 30 },
      { hour: 15, minute: 30 },
      { hour: 16, minute: 15 },
    ],
    [
      { hour: 8, minute: 30 },
      { hour: 9, minute: 30 },
      { hour: 10, minute: 30 },
      { hour: 13, minute: 0 },
      { hour: 13, minute: 30 },
      { hour: 14, minute: 30 },
      { hour: 15, minute: 30 },
      { hour: 16, minute: 30 },
    ],
    [
      { hour: 9, minute: 0 },
      { hour: 10, minute: 15 },
      { hour: 12, minute: 0 },
      { hour: 14, minute: 0 },
      { hour: 14, minute: 30 },
      { hour: 15, minute: 0 },
      { hour: 15, minute: 30 },
      { hour: 16, minute: 30 },
    ],
  ],
};

export const PatientField = {
  "id": 6,
  "uuid": "c1f7c68c-c060-4683-9aed-84f6004e5ef3",
  "type_name": "Dropdown",
  "is_deleted": false,
  "properties": {
    "common": [
      [
        "label",
        {
          "type": "input",
          "label": "Label",
          "value": "Patient",
          "isDynamic": false
        }
      ],
      [
        "isVisible",
        {
          "type": "checkbox",
          "label": "Visible Option",
          "value": false,
          "isDynamic": true
        }
      ],
      [
        "sublabel",
        {
          "type": "input",
          "label": "Sublabel",
          "value": "",
          "isDynamic": true
        }
      ],
      [
        "placeholder",
        {
          "type": "input",
          "label": "Placeholder",
          "value": "",
          "isDynamic": true
        }
      ],
      [
        "makeRequired",
        {
          "type": "checkbox",
          "label": "Make Required",
          "value": true,
          "isDynamic": false
        }
      ],
      [
        "makeHidden",
        {
          "type": "checkbox",
          "label": "Make Hidden",
          "value": false,
          "isDynamic": false
        }
      ],
      [
        "hideLabel",
        {
          "type": "checkbox",
          "label": "Hide Label",
          "value": false,
          "isDynamic": false
        }
      ],
      [
        "readOnly",
        {
          "type": "checkbox",
          "label": "Read-Only",
          "value": false,
          "isDynamic": false
        }
      ]
    ],
    "specific": [
      [
        "placeholder",
        {}
      ],
      [
        "options",
        {
          "type": "option-group",
          "label": "Options",
          "value": []
        }
      ]
    ]
  },
  "sort_order": 6,
  "updated_at": "2020-07-29T07:31:50.810Z",
  "updated_by": "5ca516994291bf5601000078",
  "display_name": "Patient",
  "validationMessage": null
}
