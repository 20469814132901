import * as t from "io-ts";

export const TaskEntryData = t.type({
  name: t.string,
  details: t.string,
  status: t.string,
  taskId: t.string,
});

export type TaskResponseType = t.TypeOf<typeof TaskEntryData>;
