import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

interface ParamRedirect {
  pathname: string;
  state: string;
}

interface Props {
  redirectTo: string | ParamRedirect;
  txtButton?: string;
  className?: string;
  withShadow?: boolean;
}

const LinkButton = (props: Props) => {
  const {
    redirectTo,
    txtButton,
    className = 'btn btn--light',
    withShadow = false,
  } = props;

  return (
    <Link to={redirectTo}>
      {withShadow ? (
        <Button variant="contained" color="primary" className={className}>
          {txtButton}
        </Button>
      ) : (
        <button className={className}>{txtButton}</button>
      )}
    </Link>
  );
};

export default LinkButton;
