import React from 'react';
import { FormFieldBase } from './form-field-base';
import { FormFieldInput } from './form-field-input';
import { getFieldProperties } from '../helpers';

export const FormFieldDefault = ({ field, isRenderedField, onChange }) => {
  const properties = getFieldProperties(field, ['common', 'specific']);
  return <FormFieldInput field={field} options={{ properties, isRenderedField, onChange }} />;
};

export default FormFieldBase(FormFieldDefault);

FormFieldDefault.defaultProps = {
  isRenderedField: false,
  onChange: () => { },
};
