import React from 'react';
import styles from './styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const DefaultThankYouMessage = `Your submission was sent securely to our office. We will get back to you shortly.`;
const RefererMessage = `Please review and complete any additional documents that may require your attention.`;
const ReviewButtonMessage = `Review My Documents`;

const Referer = ({ referer }) => {
  return (
    <div style={styles.refererBlock}>
      <p style={{ ...styles.message, ...styles.messageSmall }}>{RefererMessage}</p>
      <a href={decodeURIComponent(referer)} className='btn btn-primary' style={styles.refererBtn}>
        {ReviewButtonMessage}
      </a>
    </div>
  );
};

const ThankYou = ({ message, referer, pdfUrl, showDownloadLink }) => {
  return (
    <div style={styles.container}>
      <CheckCircleOutlineIcon
        style={{
          color: '#86C36F',
          fontSize: '90px',
          width: '100%',
          marginBottom: '15px',
        }}
      />
      <h1 style={styles.textThankYou}>Thank you!</h1>
      <p style={styles.message}>{message || DefaultThankYouMessage}</p>
      {showDownloadLink ? <p style={styles.message}>If you would like to download a copy of your form, you can find a link to it here: <a href={pdfUrl} style={{ "color": "#2f68fd" }}>Download Form</a></p>
      : ""}
      {referer && <Referer referer={referer} />}
    </div>
  );
};

ThankYou.defaultProps = {
  message: DefaultThankYouMessage,
};

export default ThankYou;
