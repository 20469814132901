import React, { useCallback } from "react";
import classNames from "classnames";
import DayPicker from "react-day-picker";
import { useMediaQuery } from "@material-ui/core";
import styles from "./styles";
import { Labels } from "../../constants";
import "react-day-picker/lib/style.css";
import "./appointment-selector.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { convertDayOfYearLocalToTimeZone } from "../../../../../../utils/convertData";

import ValidationMessage from "../../../validation-message";
import TimeSelector from "./components/time-selector";

import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  dayPickerContainer: {
    "& .DayPicker-Caption": {
      fontSize: "13px",
      color: "#12253F",
      paddingLeft: "17px",
    },
  },
});

const AppointmentSelector = ({
  openDays,
  timeSlots,
  selectedDay,
  selectedTime,
  onMonthChange,
  onDayClick,
  onTimeClick,
  dayPickerField,
  timeSelectorField,
  readOnly,
  forceRenderDatePicker,
  duration,
  timezoneOffSet,
  timeSlotsLoading,
  daysLoading,
}) => {
  const modifiers = {
    highlighted: selectedDay,
    allDay: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
  };

  const disableDaysOfMonth = (day) => {
    return (
      openDays.indexOf(
        convertDayOfYearLocalToTimeZone(timezoneOffSet, day) + 1
      ) === -1
    );
  };

  const validationClass = classNames({
    "control-group error": !!dayPickerField.validationMessage,
  });

  const readOnlyStyle = readOnly && styles.readOnly;
  const classes = useStyle();

  const matches = useMediaQuery("(max-width:768px)");
  const isIpadLg = useMediaQuery("(max-width:1024px)");

  const isTablet = matches || isIpadLg;
  return (
    <div
      className="appointment-booking__date-time"
      style={isTablet ? styles.displayColumn : styles.dateTimeContainer}
    >
      <div className="form-field-input-container" style={styles.inputContainer}>
        <div className="form-field-label primary">
          {`${Labels.AppointmentDate}`} <b style={{ color: "#102341" }}>*</b>
        </div>
        {forceRenderDatePicker &&(
          <>
          <div
            style={{ ...styles.calendarContainer, ...readOnlyStyle, "display": daysLoading ? "none" : ""}}
            className={`${validationClass} ${classes.dayPickerContainer}`}
          >
            <DayPicker
              selectedDays={selectedDay}
              initialMonth={selectedDay || new Date()}
              disabledDays={[disableDaysOfMonth]}
              weekdayElement={<Weekday />}
              navbarElement={<Navbar />}
              onMonthChange={onMonthChange}
              onDayClick={onDayClick}
              modifiers={modifiers}
              modifiersStyles={styles.calendarStyles}
              containerProps={{ readOnly, id: dayPickerField.uuid }}
            />
            <ValidationMessage message={dayPickerField.validationMessage} />
          </div>
          <div className="date-picker-loading" style={{ "display": daysLoading ? "" : "none" }}>
            <img src="https://portal.thevethero.com/static/media/loading-spinner.14e717da.svg" alt="Loading" className="loading-spinner" />
          </div>
          </>
        )}
      </div>

      {selectedDay && (
        <TimeSelector
          id={timeSelectorField.uuid}
          timeSlots={timeSlots}
          selectedTime={selectedTime}
          onTimeClick={onTimeClick}
          validationMessage={timeSelectorField.validationMessage}
          readOnly={readOnly}
          duration={duration}
          loading={timeSlotsLoading}
        />
      )}
    </div>
  );
};

AppointmentSelector.defaultProps = {
  openDays: [],
  timeSlots: [[], []],
  selectedDay: null,
  selectedTime: null,
  readOnly: false,
};

const Weekday = ({ weekday, className, localeUtils, locale }) => {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  return (
    <div className={className} title={weekdayName} style={styles.weekDay}>
      {weekdayName.slice(0, 3)}
    </div>
  );
};

const Navbar = ({ onPreviousClick, onNextClick, className }) => {
  const previousClick = useCallback(() => {
    onPreviousClick();
  }, []);

  const nextClick = useCallback(() => {
    onNextClick();
  }, []);

  return (
    <div className={className} style={styles.arrowButton}>
      <FontAwesomeIcon
        icon={faChevronLeft}
        onClick={previousClick}
        style={{ fontSize: "13px", color: "#12253F", marginRight: "10px" }}
      />
      <FontAwesomeIcon
        icon={faChevronRight}
        onClick={nextClick}
        style={{ fontSize: "13px", color: "#12253F" }}
      />
    </div>
  );
};

export default AppointmentSelector;
