import React from "react";
import FAIconButton from "../Button/FAIconButton";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

interface JoinAppointmentIconProps {
  // setModalIsOpen: (arg: boolean) => void;
  customClasses?: {
    button?: string;
    faIcon?: string;
  };
  checkIncall?: () => void;
}

const JoinAppointmentIcon = (props: JoinAppointmentIconProps) => {
  const handleClick = () => {
    // props.setModalIsOpen(true);
    // props.checkIncall();
  };

  return (
    <FAIconButton
      icon={faVideo}
      handleClick={handleClick}
      customClasses={props.customClasses ? props.customClasses : undefined}
      // title="Show appointment"
    />
  );
};

export default JoinAppointmentIcon;
