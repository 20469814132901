//@ts-nocheck
import { call, put, takeEvery, fork, all } from 'redux-saga/effects';
import { getLogoPratice } from 'services/pratices';
import { GET_LOGO_PRACTICE } from 'constants/actions';
import { getLogoPracticeSuccess, getLogoPracticeFailed } from './actions';
import { get } from 'lodash';

// @ts-ignore
function* getLogoPracticeHandle({ practiceName }) {
  try {
    const response = yield call(getLogoPratice, practiceName);
    const practicePhoneNumber =
      response?.data?.phoneNumbers?.find((phone: any) => phone.isPracticePhone)
        ?.number ?? '';
    // localStorage.setItem('logoPractice', response.data.logoUrl ?? "");
    // localStorage.setItem('practiceName', practiceName);
    // localStorage.setItem('practiceEmailAddress', response.data.practiceEmailAddress);
    // localStorage.setItem('practicePhoneNumber', practicePhoneNumber);
    if (get(response, 'status', 0) === 200) {
      yield put(
        getLogoPracticeSuccess({
          logoPractice: response.data.logoUrl,
          practiceName,
          practiceEmailAddress: response.data.practiceEmailAddress,
          practicePhoneNumber,
        })
      );
    } else {
      yield put(getLogoPracticeFailed());
    }
  } catch (error) {
    yield put(getLogoPracticeFailed());
  }
}

//watcher
function* getLogoPraticeAction() {
  // @ts-ignore
  yield takeEvery(GET_LOGO_PRACTICE, getLogoPracticeHandle);
}

export default function* watchAll() {
  // yield getLogoPracticeHandle()
  yield all([fork(getLogoPraticeAction)]);
}
