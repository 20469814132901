import * as t from "io-ts";

const DocumentRequired = t.type({
  form_name: t.string,
  id: t.string,
});

const DocumentNullable = t.partial({
  form_folder_id: t.string,
  isAppointmentBooking: t.boolean,
  isPatientForm: t.boolean,
  formType: t.number,
  is_deleted: t.boolean,
  is_restricted: t.boolean,
  is_redirect: t.boolean,
  is_published: t.boolean,
  is_publishing: t.boolean,
  isSyncPatientDocument: t.boolean,
  IsSendSubmissionEmail: t.boolean,
  has_group: t.boolean,
  has_contact: t.boolean,
  embed_code: t.string,
  form_version_id: t.string,
  folder_name: t.string,
  tenantId: t.string,
  number: t.string,
  object: t.string,
  never_published: t.boolean,
  actions: t.string,
  notificationIds: t.array(t.string),
  notifications: t.array(t.string),
  created_at: t.string,
  created_by: t.string,
  updated_at: t.string,
  updated_by: t.string,
  allowDisplayInMicrosites: t.boolean,
  documentStatus: t.string,
  latestSubmissionId: t.string,
});

const DocumentEntry = t.intersection([DocumentRequired, DocumentNullable]);

export type DocumentType = t.TypeOf<typeof DocumentEntry>;

export const DocumentCollectionEntry = t.type({
  totalAvailable: t.number,
  count: t.number,
  offsetValue: t.number,
  result: t.array(DocumentEntry),
});

export type DocumentCollectionResponseType = t.TypeOf<
  typeof DocumentCollectionEntry
>;
