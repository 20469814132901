import moment from "moment";
import { AuthJWTResponseType } from "./ResponseTypes/AuthJWT";

const keyAuthToken = "AuthToken";
const keyAuthTokenType = "AuthTokenType";
const keyAuthTokenExpiry = "AuthTokenExpiresAt";

export const getSessionJWT = (): string | undefined => {
  const authToken = sessionStorage.getItem(keyAuthToken);
  if (!authToken) {
    return undefined;
  }
  return authToken;
};

export const getSessionAuthorizationHeaderValue = (): string | undefined => {
  const authToken = sessionStorage.getItem(keyAuthToken);
  const tokenType = sessionStorage.getItem(keyAuthTokenType);

  if (!authToken || !tokenType) {
    return undefined;
  }

  return tokenType + " " + authToken;
};

export const setSessionJWT = (authDetails: AuthJWTResponseType): void => {
  window.sessionStorage.setItem(keyAuthToken, authDetails.access_token);
  window.sessionStorage.setItem(keyAuthTokenType, authDetails.token_type);
  window.sessionStorage.setItem(
    keyAuthTokenExpiry,
    moment().add(authDetails.expires_in, "seconds").unix().toString()
  );
};
