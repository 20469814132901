import React, { useEffect, useMemo } from 'react';
import { FormFieldInput } from './form-field-input';
import { FormFieldBase } from './form-field-base';
import { getFieldProperties } from '../helpers';

export const FormFieldGender = ({
  field,
  isRenderedField,
  onChange,
  value,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  const properties = getFieldProperties(field, ['common', 'specific']);

  const options = useMemo(() => {
    return (field.options ?? []).map((option) => {
      const arr = option.split(':');
      return {
        label: arr[0].trim(),
        id: arr[1].trim(),
      };
    });
  }, [field]);

  const handleChange = (e) => {
    onChange(e);
    if (e.target.value) {
      field.label =
        options.find((option) => option.id === e.target.value)?.label ?? '';
    }
  };

  useEffect(() => {
    if (value && !field.label) {
      field.label = options.find((o) => o.id === value)?.label ?? '';
    }
  }, []);

  return (
    <FormFieldInput
      field={field}
      options={{
        properties,
        isRenderedField,
        onChange: handleChange,
        value,
        onBlur,
        validationMessage,
        readOnly,
      }}
    >
      {isRenderedField && (
        <>
          <option hidden={true} value="">
            Select Pet Sex
          </option>
          {options.map((option, index) => {
            return (
              <option key={index} value={option.id}>
                {option.label}
              </option>
            );
          })}
        </>
      )}
    </FormFieldInput>
  );
};

FormFieldGender.defaultProps = {
  isRenderedField: false,
  onChange: () => {},
  value: '',
  onBlur: () => {},
  readOnly: false,
};

export default FormFieldBase(FormFieldGender);
