import { LocalConvenienceStoreOutlined } from '@material-ui/icons';
import { string } from 'io-ts';
import { AnyAction } from 'redux';

interface IState {
  routeFlag: {
    [key: string]: boolean;
  };
  loading: boolean;
}

const initialState: IState = {
  loading: false,
  routeFlag: {
    dashboard: true,
    'online-booking': true,
    forms: true,
    subscription: true,
    pets: true

  },
};

const roleByFeatures = {
  'Dashboard': 'dashboard',
  'Online Appointment Booking': 'online-booking',
  'Form Builder': 'forms',
  'Subscription': 'subscription',
  'Pets': 'pets'
};

const getPathByFeatures = (arrayOfObjectScreens: {screenName: ''}[]) => {
  //const features = screens.map((screen)=>screen.toLowerCase())
  const features : string[]= []
  arrayOfObjectScreens.forEach((item : {screenName: ''})=>{
    features.push(item.screenName)
  })
  //console.log('Step 5: features excluded: ', features)
  const keys = Object.keys(roleByFeatures);
  //console.log(keys)

  const result = {};

  keys.forEach((k) => {
    // @ts-ignore
    result[roleByFeatures[k]] = features.includes(k) ? false : true;
  });

  //console.log('result: ', result)

  return result;
  // result would look like:
  // {forms: false, 'online-booking': false}
};






// Our reducer

export const featuresReducer = (
  state = initialState,
  action: AnyAction
): IState => {
  switch (action.type) {
    case 'FETCH_FEATURES':
      //console.log('reducer fetch feature')
      return {
        ...state,
        // loading: true,
        loading: false,
      };
    case 'FETCH_FEATURES_SUCCESS':
      //console.log('fetch feature success payload: ', action.payload.screens)
      return {
        loading: false,
        routeFlag: { ...getPathByFeatures(action.payload.screens) },
      };
    default:
      return state;
  }
};
