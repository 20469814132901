// an object of branch and matching value
import { isObject } from 'lodash';
import { compose, when, always, reduce } from 'ramda';
interface PatternO {
  [branch: string]: any;
}

const matchValue = (value: string | number, pattern: PatternO) => {
  const cases = Object.keys(pattern);
  const lastIndex = cases.length - 1;
  const hasCorrectCase = (key: string, index: number) => {
    if (String(value) === key) return true;
    if (key === '_') {
      if (index !== lastIndex) {
        throw new Error(`'_' must be the last branch.`);
      }

      return true;
    }

    return false;
  };
  const matchingCase = cases.find(hasCorrectCase);

  if (!matchingCase) {
    throw new ReferenceError(`Match error for value: ${value}`);
  }

  return pattern[matchingCase];
};

const match = (pattern: any) => (value: any) => matchValue(value, pattern);

const setCookie = (
  name: string,
  value: string | number | object,
  options: { duration: number }
) => {
  const { duration } = options;

  const expireDate = new Date();
  expireDate.setTime(expireDate.getTime() + duration * 3600 * 1000);

  let cookieString = isObject(value)
    ? `${name}=${JSON.stringify(value)}`
    : `${name}=${value}`;

  cookieString = cookieString.concat(
    '; ',
    `expires=${expireDate.toUTCString()};path=/`
  );

  document.cookie = cookieString;
};

const deleteCookie = (cookieName: any) => {
  const cookieDate = new Date(); // current date & time
  // set expired time to the past so that the cookie is invalid in the present
  cookieDate.setTime(cookieDate.getTime() - 10000);
  document.cookie = `${cookieName}=; expires=${cookieDate.toUTCString()};path=/`;
};

const getCookie = (cookieName: any, valueIsObject = false) => {
  const results = document.cookie.match(`(^|;) ?${cookieName}=([^;]*)(;|$)`);

  if (results) {
    if (valueIsObject) {
      try {
        return JSON.parse(results[2]);
      } catch (error) {
        // handle error
        // tslint:disable-next-line: no-console
        console.log('should be parse error', error);
      }
    }

    return results[2];
  }

  return null;
};

const deleteAllCookie = () => {
  document.cookie.split(';').forEach((cookie) => {
    document.cookie = cookie
      .replace(/^ +/, '')
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
  });
};

const calculateTotalWidget = (dataArray: any, key: any, isToFixed = false) =>
  compose(
    when(always(isToFixed), (res: number) => res.toFixed(2)),
    reduce((acc, cur: any) => acc + cur[key], 0)
  )(dataArray);

const isKeyPressEnterEvent = (event: any) => {
  const charCode = event.which ? event.which : event.keyCode;

  if (charCode === 13) return true;

  return false;
};

const parseJSON = (objectKey: any, fallbackValue = {}) => {
  try {
    return JSON.parse(
      localStorage.getItem(objectKey) || JSON.stringify(fallbackValue)
    );
  } catch (err) {
    return fallbackValue;
  }
};

const capitalizeFirstLetter = (str: any) => {
  if (!str) return str;

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const EMAIL_REGEX = /^(([*!#$%&'\/\=\*\^\~\?\+-|`{}\w*]){1,64}@\w+([-.]\w+)*\.\w+([-.]\w+)*\s*[,]?\b)*$/;

// @ts-ignore
const matchFieldByLabel = (fields = [], label: string) => fields.find(f => f.display_name === label);

export {
  match,
  setCookie,
  deleteCookie,
  getCookie,
  deleteAllCookie,
  calculateTotalWidget,
  isKeyPressEnterEvent,
  parseJSON,
  EMAIL_REGEX,
  capitalizeFirstLetter,
  matchFieldByLabel
};
