import React from 'react';
import moment from 'moment';
import styles from './styles';

class TimeSlot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
    };
  }

  toggleHover = () => this.setState({ isHovered: !this.state.isHovered });

  handleClick = () => this.props.onClick(this.props.time);

  render() {
    const { time, selected, disabled } = this.props;
    const { isHovered } = this.state;

    let style = styles.timeSlot;
    if (isHovered) style = { ...style, ...styles.timeSlotHover };
    if (selected) style = { ...style, ...styles.timeSlotSelected };
    if (disabled) style = { ...style, ...styles.disabledSlot };

    return (
      <div
        style={style}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        onClick={this.handleClick}
      >
        <span style={styles.timeSlotText}>{moment(time).format('h:mm A')}</span>
      </div>
    );
  }
}

TimeSlot.defaultProps = {
  selected: false,
};

export default TimeSlot;
