import * as t from "io-ts";

const PhoneNumberResponseRequired = t.type({
  number: t.string,
  isPrimary: t.boolean,
  kind: t.string,
});

const PhoneNumberResponseNullable = t.partial({
  phoneNumberId: t.string,
  extension: t.string,
  isValidated: t.boolean,
  validatedOutput: t.string,
  isText: t.boolean,
  isCall: t.boolean,
  subscribe: t.boolean,
});

export const PhoneNumberEntryData = t.intersection([
  PhoneNumberResponseRequired,
  PhoneNumberResponseNullable,
]);

export type PhoneNumberResponseType = t.TypeOf<typeof PhoneNumberEntryData>;
