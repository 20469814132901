// @ts-nocheck
import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { PaginationsView } from "./PaginationsView";
import { TableSortLabel } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.scss";
interface IColumns {
  title: string;
  key: string;
}
interface ISortField {
  field: string;
  isAsc: boolean;
}
interface ITable {
  columns: Array<IColumns>;
  rows?: Array<Record<string, string | React.ReactElement | number | null>>;
  tableBody?: React.ReactElement;
  isHasPagination?: boolean;
  totalAvailable?: number;
  onChangePage?: (event: unknown, newPage: number) => Promise<void>;
  onChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowsPerPage?: number;
  page?: number;
  isSort?: boolean;
  sortField?: ISortField;
  handleSort?: (field: string, isAsc: boolean) => void;
}

const styleCellHead = {
  color: "#6e84a3",
  fontFamily: "Nunito Sans, Calibri, Arial",
  fontSize: ".8125rem",
  fontWeight: "600",
  letterSpacing: "0",
  lineHeight: "18px",
};

const styleCellBody = {
  fontFamily: "Nunito Sans, Calibri, Arial",
  fontSize: ".8125rem",
  fontWeight: "600",
  letterSpacing: "0",
  lineHeight: "18px",
};

export default function TableComponent(props: ITable) {
  const {
    columns,
    rows,
    tableBody,
    isHasPagination,
    totalAvailable,
    onChangePage,
    onChangeRowsPerPage,
    rowsPerPage,
    page,
    isSort = false,
    sortField,
    handleSort,
  } = props;
  const [orderTerm, setOrderTerm] = useState<boolean>(
    sortField?.isAsc ?? false
  );
  // orderTerm : true -> Asc , false -> Desc

  const [sortTerm, setSortTerm] = useState<string>(sortField?.field ?? "");

  const onHandleSort = (field: string) => {
    handleSort(
      field === sortTerm && !orderTerm ? "" : field,
      field === sortTerm ? !orderTerm : true
    );
    const oldOrderTerm = orderTerm;
    setOrderTerm(field === sortTerm ? !orderTerm : true);
    setSortTerm(field === sortTerm && !oldOrderTerm ? "" : field);
  };

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.key} style={styleCellHead}>
                {isSort ? (
                  <TableSortLabel
                    hideSortIcon={true}
                    onClick={() => onHandleSort(column.key)}
                  >
                    {column.title}
                    <span className="group-icon--sort">
                      <FontAwesomeIcon
                        icon={["fas", "sort-up"]}
                        className={`${
                          column.key === sortTerm && orderTerm ? "sorted" : ""
                        }`}
                      />
                      <FontAwesomeIcon
                        icon={["fas", "sort-down"]}
                        className={`${
                          (column.key === sortTerm) & !orderTerm ? "sorted" : ""
                        }`}
                      />
                    </span>
                  </TableSortLabel>
                ) : (
                  column.title
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBody
            ? tableBody
            : rows.map((row) => (
                <TableRow key={row.id}>
                  {columns.map((column) => (
                    <TableCell key={column.key} style={styleCellBody}>
                      {row[column.key] ?? ""}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
        </TableBody>
      </Table>
      {isHasPagination && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]}
          count={totalAvailable || 10}
          rowsPerPage={rowsPerPage || 10}
          page={page || 0}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          ActionsComponent={PaginationsView}
        />
      )}
    </TableContainer>
  );
}
