import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import useOnClickOutside from 'hooks/useOnClickOutSide';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  practiceName: string;
  isAuth: boolean;
  fullName: string;
  iconName: string;
  handleLogout: () => void;
  handleChangePassword: () => void;
}

const Account = ({
  practiceName,
  isAuth,
  fullName,
  iconName,
  handleLogout,
  handleChangePassword,
}: IProps) => {
  const refOutsideAccount = useRef(null);

  const [openOptionsAccount, setOpenOptionsAccount] = useState<boolean>(false);
  const [isLoggin, setIsLoggin] = useState<boolean>(false);

  const handleCloseOptionsAccount = () => {
    setOpenOptionsAccount(false);
  };

  const handleToggleOptionsAccount = () => {
    setOpenOptionsAccount(!openOptionsAccount);
  };

  useOnClickOutside(refOutsideAccount, handleCloseOptionsAccount);

  return (
    <div
      tabIndex={0}
      className="account--desktop"
      ref={refOutsideAccount}
      onClick={handleToggleOptionsAccount}
    >
      {isAuth ? (
        <div className="account show-dropdown">
          <span className="account-info">
            <span className="account-icon">{iconName}</span>
            <span className="account-name">{fullName}</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
          {openOptionsAccount && (
            <div>
              <span className="dropdown-rectangle"></span>
              <ul className="account-options">
                <li>
                  <span onClick={handleChangePassword}>
                    <FontAwesomeIcon icon={['fas', 'lock']} /> Change password
                  </span>
                </li>
                <li>
                  <span onClick={handleLogout}>
                    <FontAwesomeIcon icon={['fas', 'sign-out-alt']} /> Logout
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
      ) : (
        <>
          <span>
            <Link to={`/${practiceName}/login`}>Login</Link>
          </span>
          <Link to={`/${practiceName}/register`}>
            <Button variant="contained">Register</Button>
          </Link>
        </>
      )}
    </div>
  );
};

export default Account;
