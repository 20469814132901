import { AxiosResponse } from 'axios';
import { Decoder } from 'io-ts';
import * as t from "io-ts";
import { isLeft } from 'fp-ts/lib/Either';

import { ValidAxiosResponses } from './ResponseTypes/ResponseTypes';
import { UserEntryData, UserResponseType } from './ResponseTypes/User';
import { convertDataToMultipleArray } from './../utils/convertData';
import {
  AppointmentCollectionCustom,
  AppointmentCollectionCustomType,
  AppointmentCollectionResponse,
  AppointmentCollectionResponseType,
} from './ResponseTypes/Appointment';
import {
  PetCollection,
  PetCollectionResponseType,
  PetEntryData,
  PetResponseType,
} from './ResponseTypes/Pet';
import {
  MethodLoginEntry,
  MethodLoginResponseType,
} from './ResponseTypes/MethodLogin';
import {
  DocumentCollectionEntry,
  DocumentCollectionResponseType,
} from './ResponseTypes/Document';
import {
  SubmissionEntry,
  SubmissionResponseType,
} from './ResponseTypes/Submission';
import { AuthJWTResponse, AuthJWTResponseType } from './ResponseTypes/AuthJWT';
import {
  VaccinationCollectionResponse,
  VaccinationCollectionResponseType,
} from './ResponseTypes/Vaccination';
import {
  ReminderCollectionResponse,
  ReminderCollectionResponseType,
} from './ResponseTypes/Reminder';
import {
  ProviderCollectionEntry,
  ProviderCollectionType,
} from './ResponseTypes/Provider';
import {
  MedicalHistoryCollection,
  MedicalHistoryCollectionEntry,
} from './ResponseTypes/Medical';
import { any } from 'ramda';

const consoleLogFailedRes = (
  failedOnGeneric: boolean,
  data: ValidAxiosResponses | unknown
) => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }
  console.log(
    failedOnGeneric
      ? 'invalid response in generic typeguard.  This means that the format of the response data did not match any known valid response format. More output immediately below'
      : 'invalid response in specific typeguard. This means that the format of the response data matched a known type, but did not match the type that we were expecting. More output immediately below'
  );
  // don't disable this if the console is full of log statements, the log is informing you
  // that the response doesn't match and you probably need to check your type declaration
  // console.log('This is the full response:', JSON.stringify(data, null, 2));
};

const validateGenericApiResponseData = function (
  responseData: unknown
): responseData is ValidAxiosResponses {
  const isFormatValid = responseData as ValidAxiosResponses;
  // console.log('generic response is valid: ', !!isFormatValid);
  if (isFormatValid) {
    return true;
  }
  consoleLogFailedRes(true, responseData);
  return false;
};

const validateSpecificApiResponseType = function <
  T extends ValidAxiosResponses
>(
  responseData: ValidAxiosResponses,
  decoder: Decoder<ValidAxiosResponses, T>
): responseData is T {
  const decodedOutput = decoder.decode(responseData);

  const decodeFailed = isLeft(decodedOutput); // true means failure
  // console.log('response matches expected format', !decodeFailed);
  if (decodeFailed) {
    if (process.env.NODE_ENV !== 'development') {
      return false;
    }
    // This should be logging in production TODO
    // console.log(JSON.stringify(PathReporter.report(decodedOutput), null, 2));
    consoleLogFailedRes(false, responseData);
    return false;
  } else {
    return true;
  }
};

export const validateContact = (
  res: AxiosResponse
): UserResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);

  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;

  if (
    validateSpecificApiResponseType<UserResponseType>(
      validResponse,
      UserEntryData
    )
  ) {
    return validResponse as UserResponseType;
  }
};

export const validateSubmission = (
  res: AxiosResponse
): SubmissionResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);

  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;

  if (
    validateSpecificApiResponseType<SubmissionResponseType>(
      validResponse,
      SubmissionEntry
    )
  ) {
    return validResponse as SubmissionResponseType;
  }
};

// Type for validate external link
export const ExternalLinkSetting = t.type({
  buttonText: t.string,
  externalUrl: t.string,
  isEnable: t.boolean,
}); 

export const ExternalLink = t.type({
  namespace: t.string,
  settings: ExternalLinkSetting,
});
export type ExternalLinkResponse = t.TypeOf<typeof ExternalLink>;


export const validateExternalLink = function validateExternalLink(
  res: AxiosResponse
): ExternalLinkResponse | undefined {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    //TODO handle bad response, query again, log malformed data, etc.
    return;
  }
  const validGenericResponse = res.data as ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ExternalLinkResponse>(
      validGenericResponse,
      ExternalLink
    )
  ) {
    return validGenericResponse as ExternalLinkResponse;
  }
};



// Validate excluded routes
// export const ExcludedScreens = t.type(
//   {
//     screenName: t.string
//   }
// ); 

// export const ExcludedRoutesSetting = t.type({
//   count: t.number,
//   screens: [ExcludedScreens],
//   totalAvailable: t.number
// }); 

// export const ExcludedRoutes = t.type({
//   config: t.any,
//   data: ExcludedRoutesSetting,
//   headers: t.any,
//   request: t.any,
//   status: t.number,
//   statusText: t.string
// });
// export type ExcludedRoutesResponse = t.TypeOf<typeof ExcludedRoutes>;

// export const validateExcludedRoutes = function validateExternalLink(
//   res: AxiosResponse
// ): ExcludedRoutesResponse | undefined {
//   const isResValid = validateGenericApiResponseData(res.data);
//   if (!isResValid) {
//     //TODO handle bad response, query again, log malformed data, etc.
//     return;
//   }
//   const validGenericResponse = res.data as ValidAxiosResponses;
//   if (
//     validateSpecificApiResponseType<ExcludedRoutesResponse>(
//       validGenericResponse,
//       ExcludedRoutes
//     )
//   ) {
//     return validGenericResponse as ExcludedRoutesResponse;
//   }
// };

export const validatePet = (
  res: AxiosResponse
): PetResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);

  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;

  if (
    validateSpecificApiResponseType<PetResponseType>(
      validResponse,
      PetEntryData
    )
  ) {
    return validResponse as PetResponseType;
  }
};

export const validatePets = (
  res: AxiosResponse
): PetCollectionResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);

  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;

  if (
    validateSpecificApiResponseType<PetCollectionResponseType>(
      validResponse,
      PetCollection
    )
  ) {
    return validResponse as PetCollectionResponseType;
  }
};

export const validateMethodLogin = (
  res: AxiosResponse
): MethodLoginResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);

  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;

  if (
    validateSpecificApiResponseType<MethodLoginResponseType>(
      validResponse,
      MethodLoginEntry
    )
  ) {
    return validResponse as MethodLoginResponseType;
  }
};

export const validateLoginWithCode = (
  res: AxiosResponse
): AuthJWTResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);

  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;

  if (
    validateSpecificApiResponseType<AuthJWTResponseType>(
      validResponse,
      AuthJWTResponse
    )
  ) {
    return validResponse as AuthJWTResponseType;
  }
};

export const validateDocuments = (
  res: AxiosResponse
): DocumentCollectionResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);

  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;

  if (
    validateSpecificApiResponseType<DocumentCollectionResponseType>(
      validResponse,
      DocumentCollectionEntry
    )
  ) {
    return validResponse as DocumentCollectionResponseType;
  }
};

export const validateRecentProviders = (
  res: AxiosResponse
): ProviderCollectionType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);

  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;

  if (
    validateSpecificApiResponseType<ProviderCollectionType>(
      validResponse,
      ProviderCollectionEntry
    )
  ) {
    return validResponse as ProviderCollectionType;
  }
};

export const validateDailyAppointments = (
  res: AxiosResponse
): AppointmentCollectionCustomType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);

  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;

  res.data.appointments = convertDataToMultipleArray(res.data.appointments);

  if (
    validateSpecificApiResponseType<AppointmentCollectionCustomType>(
      validResponse,
      AppointmentCollectionCustom
    )
  ) {
    return validResponse as AppointmentCollectionCustomType;
  }
};

export const validateAppointments = (
  res: AxiosResponse
): AppointmentCollectionResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<AppointmentCollectionResponseType>(
      validResponse,
      AppointmentCollectionResponse
    )
  ) {
    return validResponse as AppointmentCollectionResponseType;
  }
};

export const validateVaccinations = (
  res: AxiosResponse
): VaccinationCollectionResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<VaccinationCollectionResponseType>(
      validResponse,
      VaccinationCollectionResponse
    )
  ) {
    return validResponse as VaccinationCollectionResponseType;
  }
};

export const validateMedicalHistories = (
  res: AxiosResponse
): MedicalHistoryCollection | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<MedicalHistoryCollection>(
      validResponse,
      MedicalHistoryCollectionEntry
    )
  ) {
    return validResponse as MedicalHistoryCollection;
  }
};

export const validateReminders = (
  res: AxiosResponse
): ReminderCollectionResponseType | undefined => {
  const isResValid = validateGenericApiResponseData(res.data);
  if (!isResValid) {
    return;
  }

  const validResponse = res.data as ValidAxiosResponses;
  if (
    validateSpecificApiResponseType<ReminderCollectionResponseType>(
      validResponse,
      ReminderCollectionResponse
    )
  ) {
    return validResponse as ReminderCollectionResponseType;
  }
};
