import { GET_USER_INFO } from "./../../../constants/actions";
import { call, put, takeEvery, fork, all } from "redux-saga/effects";
import { getUserInfo } from "services/pratices";
import { getUserSuccess } from "./actions";

function* auth() {
  try {
    // @ts-ignore
    const response = yield call(getUserInfo);

    if (response) {
      yield put(getUserSuccess(response));
    }
  } catch (error) {
    console.log("err", error);
  }
}

//watcher
function* getUserWithSaga() {
  // @ts-ignore
  yield takeEvery(GET_USER_INFO, auth);
}

export default function* watchAll() {
  yield all([fork(getUserWithSaga)]);
}
