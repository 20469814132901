import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  fieldIsType,
  getMapFromArray,
  getFieldPropertyValues,
} from '../helpers';
import { FormFieldBase } from './form-field-base';
import { FormFieldInput } from './form-field-input';
import ValidationMessage from './validation-message';

export const FormFieldSpecieAndBreed = ({
  field,
  isRenderedField,
  commonProps,
  onChange,
  value,
  className,
  onBlur,
  validationMessage,
  readOnly,
}) => {
  const [selectedSpecie, setSelectedSpecie] = useState('');

  const optionSpecies = useMemo(() => {
    return (field.fields[0].options?.sort() ?? []).map((option) => {
      const arr = option.split(':');
      return {
        label: arr[0].trim(),
        id: arr[1].trim(),
      };
    });
  }, [field]);

  const optionBreeds = useMemo(() => {
    return (field.fields[1].options?.sort() ?? []).map((option) => {
      const arr = option.split(':');
      return {
        label: arr[0].trim(),
        id: arr[1].trim(),
        idSpecie: arr[2].trim(),
      };
    });
  }, [field]);

  const getFieldSelectOptions = (field) => {
    if (!isRenderedField) return;

    if (fieldIsType(field, 'Pet Species', 'display_name')) {
      const listSpecies = [
        { label: 'Select Pet Species', id: '' },
        ...optionSpecies,
      ];
      return listSpecies.map((specie, idx) => (
        <option hidden={specie.id === ''} key={idx} value={specie.id}>
          {readOnly && specie.label === 'Select Pet Species'
            ? ''
            : specie.label}
        </option>
      ));
    }
    if (fieldIsType(field, 'Pet Breed', 'display_name')) {
      return optionBreeds
        .filter(
          (bread) =>
            !!selectedSpecie &&
            (bread.idSpecie === selectedSpecie || bread.idSpecie == 'null')
        )
        .map((item, idx) => (
          <option key={idx} value={item.id}>
            {item.label}
          </option>
        ));
    }
  };

  useEffect(() => {
    if (value) {
      setSelectedSpecie(value[0]);
      const specie = optionSpecies.find((item) => item.id === value[0]);
      if (specie) {
        field.fields[0].label = specie?.label ?? '';
        field.fields[0].value = specie?.id ?? '';

        const breeds = optionBreeds.filter(
          (item) => item.idSpecie === value[0] || item.idSpecie === 'null'
        );

        const breed = breeds.find((item) => item.id === value[1]) ?? breeds[0];
        if (breed) {
          field.fields[1].label = breed?.label ?? '';
          field.fields[1].value = breed?.id ?? '';
          onChange({
            target: { id: field.fields[1].uuid, value: breed?.id || '' },
          });
        }
      }
    }
  }, [value[0], value[1], optionBreeds, optionSpecies]);

  const handleChangeSpecies = (e) => {
    onChange(e);
    // if (e.target.value) {
    //   const newSpecies = e.target.value;
    //   breed = optionBreeds.find(
    //     (item) => item.idSpecie === newSpecies || item.idSpecie === "null"
    //     );
    //     // field.fields[1].value = breed?.id ?? "";
    //   }
    //   onChange(e, undefined, false, {
    //     column: 'Breed',
    //     id: field.fields[1].uuid,
    //     value: breed?.id || ''
    //   });
  };

  const handleChangeBreed = (e) => {
    onChange(e);
  };

  const { fields } = field;
  const fieldMap = new Map();

  fields
    .sort((a, b) => a.sort_order - b.sort_order) // First: make sure sub-fields are properly sorted
    .map((sf, index) => {
      const subfield = { ...sf };
      if (isRenderedField && value[index] !== undefined)
        subfield.value = value[index];
      fieldMap.set(index, [subfield]);
    });

  const classes = classNames({
    [className]: true,
    subfields: true,
    'specie-breed': true,
    'control-group error': validationMessage,
  });

  return (
    <div key={field.uuid || commonProps.label} className={classes}>
      <div className="form-field-label primary">{`${commonProps.label} ${
        commonProps.makeRequired ? '*' : ''
      }`}</div>
      <div className="flex">
        {Array.from(fieldMap).map((fields, key) => {
          return (
            <React.Fragment key={key}>
              {fields[1].map((el) => {
                const properties = getMapFromArray(el.properties);
                const propertyNames = ['sublabel'];
                const defaultValues = { sublabel: '' };
                const propertyValues = getFieldPropertyValues(
                  properties,
                  propertyNames,
                  defaultValues
                );

                return (
                  <FormFieldInput
                    key={el.id}
                    field={el}
                    children={getFieldSelectOptions(el)}
                    options={{
                      properties,
                      sublabel: propertyValues.sublabel,
                      subfield: true,
                      isRenderedField,
                      onChange:
                        el.display_name === 'Pet Breed'
                          ? handleChangeBreed
                          : handleChangeSpecies,
                      value: el.value || '',
                      onBlur,
                      readOnly,
                    }}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
      <ValidationMessage message={validationMessage} />
    </div>
  );
};

FormFieldSpecieAndBreed.defaultProps = {
  isRenderedField: false,
  onChange: () => {},
  value: '',
  onBlur: () => {},
  readOnly: false,
};

export default FormFieldBase(FormFieldSpecieAndBreed);
