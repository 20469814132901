import { getFeaturesOnPractice } from './../../services/pratices';
import { call, put, takeEvery, fork, all } from 'redux-saga/effects';

// @ts-ignore
function* getFeatures({ micrositeName }) {
  try {
   //console.log('Step 3: Saga get features: ')
    // @ts-ignore
    const response = yield call(getFeaturesOnPractice, micrositeName);

    if (response) {
      //console.log('Step 4 saga feature sucess: ', response)
      yield put({
        type: 'FETCH_FEATURES_SUCCESS',
        payload: response,
      });
    }
  } catch (error) {
    console.log('err', error);
  }
}

//watcher
function* getFeaturesWithSaga() {
  // @ts-ignore
  yield takeEvery('FETCH_FEATURES', getFeatures);
}

export default function* watchAll() {
  yield all([fork(getFeaturesWithSaga)]);
}
