import React, { useEffect } from 'react';
import { ReactComponent as LoadingSpinner } from 'assets/images/loading.svg';

import { getLogoPractice } from './FormsPatientPortal/redux/actions';
import { useAppDispatch } from 'hooks/redux';
import { useRouter } from 'hooks/useRouter';
import { setSessionJWT } from 'services/SessionJWT';

const Loading = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  // @ts-ignore
  const to = router.query['0'];

  // @ts-ignore
  const { practiceMicrositeName } = router.match.params;

  const searchParam = router.location.search;
  const query = new URLSearchParams(searchParam.split('+').join('%2B'));
  const access_token = query.get('token');
  const token_type = query.get('type');

  useEffect(() => {
    if (practiceMicrositeName) {
      dispatch(getLogoPractice(practiceMicrositeName));
    }
  }, [practiceMicrositeName]);

  useEffect(() => {
    if (access_token && token_type) {
      setSessionJWT({
        access_token,
        expires_in: 0,
        refresh_token: '',
        token_type,
      });
      router.history.push(`/${practiceMicrositeName}/${to}`);
    } else {
      router.history.push(`/${practiceMicrositeName}/login`);
    }
  }, [access_token, token_type]);

  return <LoadingSpinner className="loading-spinner loading-center" />;
};

export default Loading;
