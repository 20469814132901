import React from 'react';
import { getFieldProperties, getFieldPropertyValues } from '../helpers';

const buttonStyle = {
  backgroundColor: '#112440',
  borderRadius: '6px',
  color: 'white',
  margin: '0px auto',
  fontSize: '13px',
  fontFamily: 'Nunito Sans',
  cursor: 'pointer',
  padding: '4px 12px',
  outline: 'none',
  display: 'block',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  height: '36px',
  border: 'none',
  minWidth: '100px',
};

export const FormFieldSubmit = ({
  field,
  onFieldClick,
  pageNum,
  displayPrevious,
  onPageChange,
  disabled
  }) => {
  const handleFieldClick = e => {
    e.stopPropagation();
    onFieldClick(field);
  };

  const properties = getFieldProperties(field, 'common');
  const propertyValues = getFieldPropertyValues(properties.common, 'label');

  return (
    <div key={field.uuid} className='form-field-submit'>
      {displayPrevious && (
        <input
          type='button'
          className='btn btn-secondary pull-left'
          value='Previous'
          onClick={() => onPageChange(pageNum - 1)}
        />
      )}
      {/*<input*/}
      {/*  type='button'*/}
      {/*  className='btn btn-primary'*/}
      {/*  value={propertyValues.label}*/}
      {/*  onClick={handleFieldClick}*/}
      {/*  disabled={disabled}*/}
      {/*/>*/}
      <button
        style={buttonStyle}
        disabled={disabled}
        type='button'
        onClick={handleFieldClick}
      >
        <span>{propertyValues.label}</span>
      </button>
    </div>
  );
};

export default FormFieldSubmit;

FormFieldSubmit.defaultProps = {
  pageNum: 1,
  displayPrevious: false,
  onPageChange: () => {
  },
  disabled: false
};
