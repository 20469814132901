import React from 'react';
import ValidationMessageItems from './validation-message-items';

const ValidationMessage = ({ message }) => {
  if (!message) return null;
  return (
    <div style={{ width: '100%', float: 'left' }}>
      {Array.isArray(message) ? (
        <ValidationMessageItems messages={message} />
      ) : (
        <p style={{
          margin: 0
        }}>
          <span className='text-error text-danger'>{message}</span>
        </p>
      )}
    </div>
  );
};

ValidationMessage.defaultProps = {
  message: ''
};

export default ValidationMessage;
