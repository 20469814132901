import React, { SetStateAction, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import { ErrorMessage, FastField, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import ModalDialog from 'components/Global/Modal';
import { ReactComponent as Loading } from 'assets/images/loading.svg';

import { ErrorMessages } from 'constants/validateErrors';
import { passwordPattern } from 'utils/regexs';
import { fetchLogin, loginWithCode } from 'services/pratices';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { login, getUserInfo } from 'scenes/Auth/redux/actions';
import { setSessionJWT } from 'services/SessionJWT';

interface LoginType {
  emailAddress: string;
  password: string;
}

interface ProviderType {
  providerType: string;
  providerDescription: string;
}

interface IProps {
  methodLogin: string;
  setMethodLogin: React.Dispatch<SetStateAction<string>>;
}

const validateSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .required(`Email Address ${ErrorMessages.required}`)
    .email('Please enter a valid Email Address.')
    .max(50, ErrorMessages.incorrectFormat),
  password: Yup.string()
    .required(`Password ${ErrorMessages.required}`)
    .matches(passwordPattern, ErrorMessages.passwordFormat),
});

const initialValue = {
  emailAddress: '',
  password: '',
};

const Login = ({ methodLogin, setMethodLogin }: IProps) => {
  window.document.title = 'Patient Portal | Login';

  const history = useHistory();
  const location = useLocation();
  const micrositeName = useAppSelector(
    (state) => state.formsPatientPortal.practiceName
  );
  const dispatch = useAppDispatch();
  const [errorResponse, setErrorResponse] = useState<string>('');
  const [providersLogin, setProvidersLogin] = useState<ProviderType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  location.state = '';

  useEffect(() => {
    setMethodLogin(providersLogin[0]?.providerType ?? '');
  }, [providersLogin]);

  const handleLogin = async (values: LoginType) => {
    setLoading(true);
    try {
      const res = await fetchLogin(
        values.emailAddress,
        values.password,
        micrositeName
      );

      if (res) {
        dispatch(
          login({ username: values.emailAddress, password: values.password })
        );
        setLoading(false);

        // with API change to not check token we can skip 2FA
        try {
          const res = await loginWithCode({
            provider: methodLogin,
            token: "",
            username: values.emailAddress,
            password: values.password,
            micrositeName,
          });

          if (true) {
            setSessionJWT(res);
            dispatch(getUserInfo());
            history.push(`/${micrositeName}/dashboard`);
          }
        } catch (err) {
          // @ts-ignore
          setErrorResponse(err?.response?.data?.message);
        }
        // @ts-ignore
        //setProvidersLogin(res?.providers);
      }
    } catch (err) {
      // @ts-ignore
      setErrorResponse(err?.response?.data?.message ?? '');
      setLoading(false);
    }
  };

  const handleChangeMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMethodLogin(e.target.value);
  };

  const handleConfirmMethodLogin = () => {
    history.push(`/${micrositeName}/auth-code`);
  };

  return (
    <>
      <Formik
        initialValues={initialValue}
        validationSchema={validateSchema}
        validateOnMount={true}
        validateOnChange={true}
        onSubmit={(values) => handleLogin(values)}
      >
        {({
          isValid,
          setFieldValue,
          handleSubmit,
          handleBlur,
          handleChange,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <span className="register-link">
              Are you a customer and don't have an account?&nbsp;
              <br />
              <Link to={`/${micrositeName}/register`}>Register here</Link>
            </span>
            <div className="auth__input-container">
              <label htmlFor={'email'}>
                Email <b className="require">*</b>
              </label>
              <FastField
                required
                autoComplete="off"
                placeholder="Email"
                maxLength={50}
                name="emailAddress"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  setErrorResponse('');
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleBlur(e);
                  setFieldValue('emailAddress', e.target.value.trim());
                }}
              />
              <p className="error-msg">
                <ErrorMessage
                  name="emailAddress"
                  render={(msg) => (
                    <>
                      <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                      <span>{msg}</span>
                    </>
                  )}
                />
              </p>
            </div>

            <div className="auth__input-container">
              <label htmlFor="password">
                Password <b className="require">*</b>
              </label>
              <FastField
                type="password"
                required
                maxLength={50}
                autoComplete="off"
                placeholder="Password"
                name="password"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  setErrorResponse('');
                }}
              />
              <p className="error-msg">
                <ErrorMessage
                  name="password"
                  render={(msg) => (
                    <>
                      <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                      <span>{msg}</span>
                    </>
                  )}
                />
                {!!errorResponse && (
                  <>
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                    <span>{errorResponse}</span>
                  </>
                )}
              </p>
            </div>

            <Button
              variant="contained"
              color="primary"
              className="btn btn-login"
              disabled={!isValid || !!errorResponse}
              type="submit"
            >
              Login
            </Button>

            <span className="forgot-password">
              <Link to={`/${micrositeName}/forgot-password`}>
                Forgot password
              </Link>
            </span>

            <span className="access--without-account">
              <Link to={`/${micrositeName}/forms`}>Access without account</Link>
            </span>
          </form>
        )}
      </Formik>

      <ModalDialog
        isOpenModal={!!providersLogin.length}
        isHiddenHeader={true}
        handleCloseModal={() => setProvidersLogin([])}
        handleConfirmModal={handleConfirmMethodLogin}
        textLeftButton="Cancel"
        textRightButton="Confirm"
        classMore="modal--border-large modal__select--method-login"
      >
        <>
          <p className="modal-title">
            <b>Select Contact Verification Method</b>
          </p>

          <RadioGroup
            aria-label="method"
            value={methodLogin}
            onChange={handleChangeMethod}
          >
            {providersLogin.map((provider) => (
              <FormControlLabel
                value={provider.providerType}
                control={<Radio />}
                label={`${
                  provider.providerType === 'Email'
                    ? 'Email '
                    : 'SMS ending in '
                }${provider.providerDescription}`}
              />
            ))}
          </RadioGroup>
        </>
      </ModalDialog>

      <Modal
        open={loading}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Loading className="loading-spinner loading-center" />
      </Modal>
    </>
  );
};

export default Login;
