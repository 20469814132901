import React from 'react';
import { TabType } from 'scenes/Pets/PetProfileContainer';
import { getRandomNumber } from 'utils/convertData';

import './index.scss';

interface Props {
  readonly tabs: TabType[];
  activeTab: TabType;
  onChange: (arg: TabType) => void;
}

function TabBar({ tabs, activeTab, onChange }: Props) {
  const checkActive = (tab: TabType) => {
    if (activeTab === tab) {
      return 'active';
    }
    if (tab === 'Medical' && ['History', 'Compliance'].includes(activeTab)) {
      return 'active';
    }

    return '';
  };

  return (
    <>
      <ul className="nav-tab">
        {tabs.map(
          (tab) =>
            tab && (
              <li
                key={getRandomNumber().toString()}
                className={`tab-item ${checkActive(tab)}`}
                onClick={() =>
                  tab !== activeTab &&
                  onChange(tab === 'Medical' ? 'History' : tab)
                }
              >
                {tab}
              </li>
            )
        )}
      </ul>
    </>
  );
}

TabBar.displayName = 'TabBar';

export default TabBar;
