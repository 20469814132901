import styled from "styled-components";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { CardContent, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  mb15: {
    marginBottom: 15,
  },
  mb10: {
    marginBottom: 10,
  },
  mb30: {
    marginBottom: 30,
  },
  ml15: {
    marginLeft: 15,
  },
  ml30: {
    marginLeft: 30,
  },
  pt50: {
    paddingTop: 50,
  },
  pt0: {
    paddingTop: 0,
  },
  pb0: {
    paddingBottom: 0,
  },
  borderRadius10: {
    borderRadius: 10,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    "&:first-child": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
  textLink: {
    cursor: "pointer",
    color: "#2f68fd",
  },
  textDescription: {
    color: "#6e84a3",
    fontSize: 13,
    letterSpacing: 0,
    lineHeight: "18px",
    maxWidth: 858,
  },
  buttonWrapper: {
    width: "113px",
  },
  completedIcon: {
    pointerEvents: "none",
  },
  accordionRoot: {
    marginBottom: 20,
    "&:before": {
      height: "0px",
    },
    "&:last-child": {
      borderRadius: 15,
    },
  },
});

export const EmailAndPhoneWrapper = styled.span`
  color: #2f68fd;
  font-family: "Nunito Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 17px;
`;

export const CardContentWrapper = styled(CardContent)`
  max-height: 330px;
  overflow: auto;
  padding: 0px !important;
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #b3bfd0;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #fff;
  }

  & > div {
    & > div {
      width: 100px;
      flex-shrink: 0;

      @media screen and (min-width: 768px) {
        width: 113px;
      }
    }
  }
`;

export const PhoneLink = styled.a`
  text-decoration: none;
  color: #2f68fd;
`;

export const ButtonGroupWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CenteredButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonText = styled.p`
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 7px;
  padding-top: 5px;
`;

export const Title = styled.p`
  color: #12253f;
  font-size: 17px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 20px;
`;

export const FormItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 16px;
  border-bottom: 1px solid #e3ebf6;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }

  & > p {
    color: #12253f;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
  }

  .form-name {
    margin-right: auto;
  }

  .form-status {
    margin-top: 5px;

    font-size: 13px;
    color: #fff;
  }

  & > div {
    width: 90px !important;
  }

  .MuiButtonBase-root {
    margin-left: 10px;
  }

  @media screen and (min-width: 37.5em) {
    & > p {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .form-status {
        margin-top: 0;
        margin-left: 20px;
      }
    }

    & > div {
      width: 100px !important;
    }
  }
`;

export const AccordionWrapper = styled(Accordion)`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
  box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09) !important;
  border-radius: 15px;

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordionSummary-root {
    height: 53px;

    &.Mui-expanded {
      min-height: 53px;
      height: 53px;
    }
  }

  .MuiCollapse-entered {
    height: auto;
    // overflow-y: auto;
    max-height: 350px;
  }
`;

export const AccordionDetailsWrapper = styled(AccordionDetails)`
  display: block !important;
  padding: 0px !important;
`;

export const TypographyWrapper = styled(Typography)`
  color: #12253f;
  font-size: 17px;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 20px;
`;
