import React, { useEffect, useCallback, useState } from 'react';
import { useRouter } from 'hooks/useRouter';
import { Breadcrumbs, CardContent, Container, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { get } from 'lodash';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Link } from 'react-router-dom';

import BackToLayOutComponent from 'components/BackToLayOutComponent';
import { ReactComponent as Loading } from 'assets/images/loading.svg';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import {
  getCoOwnerForPet,
  getDetailPatientPortal,
  getDetailPatientPortalWithSubmission,
  getLatestSubmission,
} from 'services/pratices';
import { getLogoPractice } from './redux/actions';
import FormRenderer from 'components/PatientPortal/form-renderer';
import { useStyles } from './Dashboard.style';
import { useAppSelector } from 'hooks/redux';
import { convertDataPopulate } from 'utils/convertData';
import { PetResponseType } from 'services/ResponseTypes/Pet';
import { UserResponseType } from 'services/ResponseTypes/User';
import { SubmissionResponseType } from 'services/ResponseTypes/Submission';
import { getSessionJWT } from 'services/SessionJWT';
import { getUserInfo } from 'scenes/Auth/redux/actions';

export const petInitial: PetResponseType = {
  petId: '',
  name: '',
  guardians: [],
};

export const coOnwerInital: UserResponseType = {
  userId: '',
  enabled: true,
  tenant: '',
  userKind: 'Undefined',
};

const FormsPatientPortalVM = () => {
  const router = useRouter();
  const dispatchAction = useDispatch();
  // @ts-ignore
  const { practiceMicrositeName, formId, petId, autoFillId, submissionId } = router.match.params;
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const pet = JSON.parse(
    window.sessionStorage.getItem('pet') ?? JSON.stringify(petInitial)
  );
  const latestSubmissionId =
    window.sessionStorage.getItem('latestSubmissionId');

  // if getting a submission's information the microsite name won't be in the URL. we dont need it for just printing a submission page because it only breaks appointment booking and OAB doesnt do pdfs anyways
  const [micrositeName, setMicrositeName] = useState<any>(practiceMicrositeName);
  const [formFields, setFormFields] = useState<any>();
  const [fullData, setFullData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [coOwner, setCoOwner] = useState<UserResponseType>(coOnwerInital);
  const [submissionData, setSubmissionData] =
    useState<SubmissionResponseType>();

  const classes = useStyles();

  const handleWhenToPrompt = () => {
    return localStorage.getItem('isDirty') === 'yes';
  };

  const handleAfterCancel = () => {
    return localStorage.getItem('isDirty') === 'yes';
  };

  useEffect(() => {
    localStorage.setItem('isDirty', 'no');
    getDetailForm();

    if (!getSessionJWT()) {
      let header = document.getElementsByClassName('nav-link__header')[0];
      if (header != undefined) {
        // @ts-ignore
        document.getElementsByClassName('nav-link__header')[0].style.display = "none";
      }
    }
    
    if (latestSubmissionId) {
      getSubmissionDetail(latestSubmissionId);
    }
    return () => {
      window.sessionStorage.removeItem('latestSubmissionId');
    };
  }, []);

  useEffect(() => {
    const isAuth = getSessionJWT();
    if (isAuth) {
      dispatchAction(getUserInfo());
    }
  }, []);

  // useEffect(() => {
  //   if (micrositeName) {
  //     dispatchAction(getLogoPractice(micrositeName));
  //   }
  // }, [micrositeName]);

  useEffect(() => {
    if (pet.petId && userInfo.userId) {
      getCoOwner();
    }
  }, [pet.petId, userInfo.userId]);

  const getSubmissionDetail = useCallback(async (submissionId: string) => {
    try {
      const res = await getLatestSubmission(submissionId);

      if (res) {
        setSubmissionData(JSON.parse(res.submissionsDetail));
      }
    } catch (err) {
      console.log('err', err);
    }
  }, []);

  const getCoOwner = useCallback(async () => {
    try {
      const res = await getCoOwnerForPet(userInfo.userId, pet.petId);

      if (res) {
        setCoOwner(res);
      }
    } catch (err) {
      console.log('err', err);
    }
  }, [userInfo.userId, pet.petId]);

  const getDetailForm = useCallback(async () => {
    try {
      setIsLoading(true);
      if(submissionId) {
        const response = await getDetailPatientPortalWithSubmission(formId, submissionId);
        if (response) {
          setFormFields(get(response, 'data.versionObject.fields', []));
          setFullData(get(response, 'data', []));
          // setMicrositeName();
          setIsLoading(false);
        }
      } else {
        const response = await getDetailPatientPortal(formId, autoFillId == undefined ? "" : autoFillId);
        if (response) {
          setFormFields(get(response, 'data.versionObject.fields', []));
          setFullData(get(response, 'data', []));
          setIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log('err', err);
    }
  }, [formId]);

  const handleBackAllForms = () => {
    router.push(`/${micrositeName}/forms`);
  };

  const breadcrumbs = [
    <Link to={`/${micrositeName}/pets`} key={'my pets'}>
      My Pets
    </Link>,
    <Link
      key={'petId'}
      to={{
        pathname: `/${micrositeName}/pets/${pet?.petId}`,
        state: true,
      }}
    >
      {pet?.name}
    </Link>,
    <span key="formId" className="text--bolder">
      {fullData?.formName}
    </span>,
  ];

  const renderFormDocument = () => (
    <Grid item={true} xs={12} sm={12}>
      <CardContent className={classes.cardContent}>
        {fullData &&
        fullData.allowDisplayInMicrosites &&
        fullData.isPublished &&
        !fullData.isDeleted ? (
          <>
            <div
              className="class-name-form-unique formSubmission"
              id="formPrintPDF"
            >
              <FormRenderer
                submission={submissionData}
                formVersion={{
                  object: { fields: formFields },
                }}
                fullData={fullData}
                practiceMicrositeName={
                  micrositeName
                  // window.localStorage.getItem('practiceName')
                }
                populateData={convertDataPopulate(userInfo, pet, coOwner)}
                formId={formId}
                autoFillId={autoFillId}
                submissionId={submissionId}
              />
            </div>
          </>
        ) : (
          <span className="form--not-publish">
            {' '}
            {fullData?.isDeleted
              ? 'Sorry, the form has no longer existed.'
              : 'This form has not been published yet.'}
          </span>
        )}
      </CardContent>
    </Grid>
  );

  return (
    <>
      {isLoading ? (
        <Loading className="loading-spinner" />
      ) : (
        <Container>
          {petId ? (
            <>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
          {renderFormDocument()}
            </>
          ) :             
              renderFormDocument()
          }
          <NavigationPrompt
            when={handleWhenToPrompt}
            afterCancel={handleAfterCancel}
          >
            {({ onConfirm, onCancel }) => (
              <ConfirmationDialog
                open={true}
                confirmHandler={onConfirm}
                cancelHandler={onCancel}
                textTitle={'Are you sure'}
                textDescription={'Do you want to go back without saving ?'}
              />
            )}
          </NavigationPrompt>
        </Container>
      )}
    </>
  );
};

export default FormsPatientPortalVM;
