import { ButtonProps, createStyles, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import { ReactComponent as ChevronRight } from 'assets/icons/right_chervon.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      backgroundColor: '#2F68FD',
      display: 'flex',
      borderRadius: '15px',
      minWidth: '90px',
      width: '100%',
      padding: '6px 16px !important',
    },
    buttonCustom: {
      backgroundColor: '#2F68FD',
      display: 'flex',
      borderRadius: '15px',
      justifyContent: 'space-around',
      width: 103,
      height: 36,
      margin: 'auto',
    },
    iconWrapper: {
      width: '25px',
      height: '25px',
      borderRadius: '50%',
      backgroundColor: '#093EE3',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
    },
    inconWrapperDisable: {
      width: '25px',
      height: '25px',
      borderRadius: '50%',
      backgroundColor: '#617799',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
    },
    buttonTitle: {
      color: '#fff',
      'font-size': (props: any) => {
        if (
          ['login', 'forgot-password', 'set-password'].includes(
            props.titleClassName
          )
        ) {
          return '18px';
        }

        return '15px';
      },
      'font-family': 'Nunito Sans',
      'letter-spacing': '0',
      'line-height': '24px',
      'margin-bottom': '0',
      'margin-top': '0px',
      'text-transform': 'none',
    },
  })
);

interface IProps extends ButtonProps {
  title: string;
  titleClassName?: string;
  isShowIcon?: boolean;
  disableIcon?: boolean;
  customClass?: boolean;
}

const PrimaryButton: React.FC<IProps> = (props) => {
  const classes = useStyles(props);
  const {
    isShowIcon,
    disableIcon,
    titleClassName,
    title,
    className = '',
    ...rest
  } = props;

  return (
    <Button
      {...rest}
      className={`${
        props.customClass ? classes.buttonCustom : classes.button
      } ${className}`}
      color={'primary'}
      endIcon={
        isShowIcon ? (
          <div
            className={
              !disableIcon ? classes.iconWrapper : classes.inconWrapperDisable
            }
          >
            <ChevronRight />
          </div>
        ) : null
      }
    >
      <p className={classes.buttonTitle}>{title}</p>
    </Button>
  );
};

export default PrimaryButton;
