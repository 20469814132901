import * as t from "io-ts";

const ProviderRequired = t.type({
  id: t.string,
  name: t.string,
});

const ProviderNullable = t.partial({
  jobTitle: t.string,
  externalId: t.string,
  emailAddress: t.string,
  isBookingActive: t.boolean,
});

export const ProviderEntryData = t.intersection([
  ProviderRequired,
  ProviderNullable,
]);

export type ProviderResponseType = t.TypeOf<typeof ProviderEntryData>;

export const ProviderCollectionEntry = t.array(ProviderEntryData);
export type ProviderCollectionType = ProviderResponseType[];
