import React from 'react';

const FormFieldInputSelect = ({ className, children, isRenderedField, onChange, ...rest }) => {
  const dynamicProps = {};

  if (isRenderedField) {
    dynamicProps.onChange = onChange;
  } else {
    dynamicProps.readOnly = true;
    dynamicProps.disabled = true;
  }

  return (
    <select className={className} {...dynamicProps} {...rest}>
      {children}
    </select>
  );
};

FormFieldInputSelect.defaultProps = {
  className: 'span30',
  isRenderedField: false,
  onChange: () => { },
};

export default FormFieldInputSelect;
