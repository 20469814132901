import React from "react";

type IProps = {
  logo: string;
};

const index = ({ logo }: IProps) => {
  return (
    <div className="auth__logo-container">
      <div className="logo-practice">
        {!!logo && <img src={logo} alt={"Pet Portal logo"} />}
      </div>
    </div>
  );
};

export default index;
