import * as t from "io-ts";

const PetResponseRequired = t.type({
  petId: t.string,
  name: t.string,
  guardians: t.array(t.string),
});

const PetResponseNullable = t.partial({
  externalId: t.string,
  species: t.string,
  breed: t.string,
  birthDate: t.string,
  neuteredSpayed: t.boolean,
  sex: t.string,
  sexId: t.string,
  documentRequiredAttention: t.number,
  status: t.string,
  speciesExternalId: t.string,
  breedExternalId: t.string,
  isPastDue: t.boolean,
  imageUrl: t.string,
});

export const PetEntryData = t.intersection([
  PetResponseRequired,
  PetResponseNullable,
]);

export type PetResponseType = t.TypeOf<typeof PetEntryData>;

export const PetCollection = t.type({
  count: t.number,
  pets: t.array(PetEntryData),
});

export type PetCollectionResponseType = t.TypeOf<typeof PetCollection>;
