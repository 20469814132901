import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useRouter } from 'hooks/useRouter';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps, useParams } from 'react-router-dom';
import { getLogoPractice } from 'scenes/FormsPatientPortal/redux/actions';
import { getSessionJWT } from 'services/SessionJWT';
import { getPracticeNameFromURL } from 'utils/convertData';

interface PrivateRouteProps extends RouteProps {
  component: React.FunctionComponent<Record<string, unknown>>;
  key: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isAuthenticated = getSessionJWT();

  const practiceMicrositeName = getPracticeNameFromURL(router.pathname);
  const practiceName = useAppSelector(
    (state) => state.formsPatientPortal.practiceName
  );

  useEffect(() => {
    if (!practiceName) {
      dispatch(getLogoPractice(practiceMicrositeName));
    }
  }, []);

  return (
    <Route
      render={(props) =>
        !isAuthenticated ? (
          // @ts-ignore
          <Redirect to={`/${practiceMicrositeName}/login`} />
        ) : (
          <Component {...props} />
        )
      }
      {...rest}
    />
  );
};
