import React, { useRef } from 'react';
import Item from './Item';
import styled from 'styled-components';
import { ReactComponent as Loading } from 'assets/images/loading.svg';
import LoadingMore from '../Loading/LoadingMore';

const CardWrap = styled.div`
  margin-bottom: 20px;
  border: 1px solid #dee0ec;

  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;

  &:last-child {
    margin-bottom: 0;
  }

  & > div {
    padding: 1.2rem;
    padding-right: 0;

    &.small-scroll {
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &.wrap-scroll {
      padding-right: 1.2rem;
    }

    .wrap-scroll {
      padding-right: 1.2rem;
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
    }
  }

  .card-title {
    padding-bottom: 1.2rem;
    font-size: 16px;
    font-weight: 800;
    border-bottom: 1px solid #dee0ec;
  }

  .loading-spinner {
    margin-bottom: -1rem !important;
  }
`;

export interface Item {
  name?: string;
  urlImage?: string;
  detail: string | React.ReactNode;
  rightAction: string | React.ReactNode;
}

interface Props {
  title?: string | React.ReactNode;
  loading?: boolean;
  children: React.ReactNode;
  classMore?: string;
  loadingMore?: boolean;
  isScrollContent?: boolean;
  handleLoadMore?: () => void;
}

const Card = ({
  title,
  loading = false,
  children,
  classMore = '',
  loadingMore = false,
  isScrollContent = false,
  handleLoadMore,
}: Props) => {
  const refContainer = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (refContainer) {
      const height = refContainer.current?.offsetHeight ?? 0;
      const totalHeight = refContainer.current?.scrollHeight ?? 0;
      const heightScroll = refContainer.current?.scrollTop ?? 0;

      if (totalHeight - height - heightScroll < 700) {
        handleLoadMore && handleLoadMore();
      }
    }
  };

  return (
    <CardWrap>
      <div
        className={`${isScrollContent ? '' : 'wrap-scroll'} ${classMore}`}
        ref={isScrollContent ? null : refContainer}
        onScroll={() => !isScrollContent && handleLoadMore && handleScroll()}
      >
        {title && <h3 className="card-title">{title}</h3>}
        {loading ? (
          <Loading className="loading-spinner" />
        ) : (
          <div
            className={`${isScrollContent ? 'wrap-scroll' : ''}`}
            ref={isScrollContent ? refContainer : null}
            onScroll={() => {
              isScrollContent && handleLoadMore && handleScroll();
            }}
          >
            {children}
          </div>
        )}
        {!loading && loadingMore && <LoadingMore />}
      </div>
    </CardWrap>
  );
};

export default Card;
