import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, FastField, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Button, Modal, TableCell, TableRow } from '@material-ui/core';
import styled from 'styled-components';

import ModalDialog from 'components/Global/Modal';
import { ReactComponent as Loading } from 'assets/images/loading.svg';
import TableComponent from 'components/Global/Table';

import { ErrorMessages } from 'constants/validateErrors';
import { useAppSelector } from 'hooks/redux';
import { registerAccount, signUpUser } from 'services/pratices';
import { string } from 'fp-ts';

interface Props {
  practiceName: string;
}

interface RegisterType {
  lastName: string;
  emailAddress: string;
}

interface OptionUser {
  id: string;
  lastName: string;
  firstName: string;
  emailAddress: string;
  phoneNumber: string;
  address: string;
  pets: string;
}

const styleCellBody: React.CSSProperties = {
  fontFamily: 'Nunito Sans, Calibri, Arial',
  fontSize: '.8125rem',
  fontWeight: 'normal',
  letterSpacing: '0',
  lineHeight: '18px',
};

const Wrapper = styled.div`
  .MuiTableContainer-root {
    max-height: 225px;

    .MuiTableCell-root {
      padding: 12px 16px;
    }

    .MuiTableHead-root {
      .MuiTableCell-root {
        &:first-child,
        &:nth-child(2),
        &:last-child {
          min-width: 85px;
        }

        &:nth-child(3) {
          min-width: 110px;
        }

        &:nth-child(4) {
          min-width: 200px;
        }
      }
    }

    .MuiTableRow-root {
      .MuiTableCell-root {
        padding-right: 1px;
      }

      & > .MuiTableCell-root:first-child {
        padding-left: 10px;
      }

      & > .MuiTableCell-root:last-child {
        padding-right: 10px;
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        cursor: pointer;

        &:hover {
          background-color: #eceef5;
        }

        &.active {
          background-color: #3990fa;

          .MuiTableCell-root {
            color: #fff;
          }
        }
      }
    }
  }
`;

const columns = [
  { key: 'firstName', title: 'First Name' },
  { key: 'lastName', title: 'Last Name' },
  { key: 'phoneNumber', title: 'Phone Number' },
  { key: 'address', title: 'Address' },
  { key: 'pets', title: 'Pets' },
];

const ValidationSchema = Yup.object().shape({
  lastName: Yup.string()
    .required(`Last Name ${ErrorMessages.required}`)
    .max(50, ErrorMessages.incorrectFormat),
  emailAddress: Yup.string()
    .required(`Email Address ${ErrorMessages.required}`)
    .email('Please enter a valid Email Address.')
    .max(50, ErrorMessages.incorrectFormat),
});

const initialValue = {
  lastName: '',
  emailAddress: '',
};

const Register = () => {
  const history = useHistory();
  const { practiceName } = useAppSelector((state) => state.formsPatientPortal);

  const [optionUsers, setOptionUsers] = useState<OptionUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [errorResponse, setErrorResponse] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setSelectedUser(optionUsers[0]?.id ?? '');
  }, [optionUsers]);

  const handleRegister = async (values: RegisterType) => {
    try {
      setLoading(true);
      // @ts-ignore
      const res = await registerAccount({
        email: values.emailAddress,
        lastName: values.lastName,
        micrositeName: practiceName,
      });

      // @ts-ignore
      if (res.status) {
        // @ts-ignore
        if (res?.users?.length === 1) {
          history.push({
            pathname: `/${practiceName}/register-success`,
            state: true,
          });
          // @ts-ignore
        } else if (res?.users?.length > 1) {
          // @ts-ignore
          const options = res.users.map((user: any) => {
            const lastName = user.name?.lastName ?? '';
            const firstName = user.name?.givenName ?? '';
            const emailAddress = user.emailAddress ?? '';
            const phoneNumber =
              (
                user?.phoneNumbers?.find((phone: any) => phone.isPrimary) ??
                user?.phoneNumbers?.[0]
              )?.number ?? '';
            const addressPrimary =
              user?.addresses?.find((address: any) => address.isPrimary) ??
              user?.addresses?.[0];
            let address = '';
            if (addressPrimary) {
              const {
                addressLine1,
                city,
                country,
                postalCode,
                state,
                addressLine2,
              } = addressPrimary.address;
              const addressSegments = [
                addressLine1 ?? '',
                addressLine2 ?? '',
                city ?? '',
                state ?? '',
                postalCode ?? '',
                country ?? '',
              ];
              address = addressSegments.filter((e) => e.length)?.join(', ');
            }
            // @ts-ignore
            const pets = res?.pets
              ?.filter((item: any) => item.guardians.includes(user.userId))
              ?.map((pet: any) => pet?.name)
              .join(', ');

            return {
              id: user.userId,
              lastName,
              firstName,
              emailAddress,
              phoneNumber,
              address,
              pets,
            };
          });
          setLoading(false);
          setOptionUsers(options);
        }
      } else {
        // @ts-ignore
        setErrorResponse(res?.message ?? '');
        setLoading(false);
      }
    } catch (err) {
      console.log('err', err);
      history.push({
        pathname: `/${practiceName}/register-unsuccess`,
        state: false,
      });
    }
  };

  const handleSignUpUser = async () => {
    try {
      setOptionUsers([]);
      const res = await signUpUser({
        micrositeName: practiceName,
        userId: selectedUser,
      });

      if (res) {
        history.push({
          pathname: `/${practiceName}/register-success`,
          state: true,
        });
      }
    } catch (err) {
      console.log('err', err);
      history.push({
        pathname: `/${practiceName}/register-unsuccess`,
        state: false,
      });
    }
  };

  const renderDataTableUser = (user: OptionUser, field: string): string => {
    switch (field) {
      case 'firstName':
        return user.firstName;
      case 'lastName':
        return user.lastName;
      case 'phoneNumber':
        return user.phoneNumber;
      case 'address':
        return user.address;
      case 'pets':
        return user.pets;
      default:
        return '';
    }
  };

  const modalBodyMultiContact = (): React.ReactElement => {
    return (
      <Wrapper>
        <div className="model-body__content">
          <p className="modal-body__text">
            {`System found ${
              optionUsers.length
            } contact(s) matched with your information: ${
              optionUsers[0]?.lastName ?? ''
            } and ${
              optionUsers[0]?.emailAddress ?? ''
            }. Please select the correct contact and select "Continue" to register.`}
          </p>

          <TableComponent
            columns={columns}
            rows={optionUsers.map((user) => ({
              firstName: user.firstName,
              lastName: user.lastName,
              phoneNumber: user.phoneNumber,
              address: user.address,
              pets: user.pets,
            }))}
            tableBody={
              <>
                {optionUsers.map((user) => (
                  <TableRow
                    key={user.id}
                    className={`${user.id === selectedUser ? 'active' : ''}`}
                    onClick={() => setSelectedUser(user.id)}
                  >
                    {columns.map((column) => (
                      <TableCell key={column.key} style={styleCellBody}>
                        {renderDataTableUser(user, column.key)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            }
            isHasPagination={false}
          />
        </div>
      </Wrapper>
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValue}
        validationSchema={ValidationSchema}
        validateOnMount={true}
        validateOnChange={true}
        onSubmit={(values) => handleRegister(values)}
      >
        {({ handleSubmit, handleBlur, setFieldValue, errors, isValid }) => (
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className="auth__form-register"
          >
            <p className="title">Create your account</p>

            <div className="auth__input-container">
              <label>
                Last name <b className="require">*</b>
              </label>
              <FastField
                maxLength={50}
                placeholder="Last Name"
                required
                name="lastName"
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleBlur(e);
                  setFieldValue('lastName', e.target.value.trim());
                }}
              />
              <p className="error-msg">
                <ErrorMessage
                  name="lastName"
                  render={(msg) => (
                    <>
                      <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                      <span>{msg}</span>
                    </>
                  )}
                />
              </p>
            </div>

            <div className="auth__input-container">
              <label>
                Email <b className="require">*</b>
              </label>
              <FastField
                maxLength={50}
                required
                placeholder="Email"
                name="emailAddress"
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleBlur(e);
                  setFieldValue('emailAddress', e.target.value.trim());
                }}
              />
              <p className="error-msg">
                <ErrorMessage
                  name="emailAddress"
                  render={(msg) => (
                    <>
                      <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                      <span>{msg}</span>
                    </>
                  )}
                />
              </p>
            </div>

            <Button
              variant="contained"
              color="primary"
              className="btn btn-login"
              disabled={!isValid}
              type="submit"
            >
              Create account
            </Button>

            <span className="link-refer">
              By continuing, you're agreeing to the&nbsp;
              <a href="https://thevethero.zendesk.com/hc/en-us" target="_blank">
                VetHero Customer Terms of Service and Privacy Policy
              </a>
            </span>
          </form>
        )}
      </Formik>

      <ModalDialog
        isOpenModal={!!errorResponse}
        title="Error"
        handleCloseModal={() => setErrorResponse('')}
        handleConfirmModal={() => setErrorResponse('')}
        children={errorResponse}
        isFullAction={false}
        classMore="modal-error"
      />

      <ModalDialog
        isOpenModal={!!optionUsers.length}
        title="Select Contact"
        handleCloseModal={() => setOptionUsers([])}
        handleConfirmModal={() => handleSignUpUser()}
        textLeftButton="Cancel"
        textRightButton="Continue"
        classMore="modal-select-user"
      >
        {modalBodyMultiContact()}
      </ModalDialog>

      <Modal
        open={loading}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Loading className="loading-spinner loading-center" />
      </Modal>
    </>
  );
};

export default Register;
